.synchronize{
    display: flex;
    width: 98%;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-top: 10px;
    &__button-space{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 58px;
        background: $cards-color;
        border-radius: 8px;
        padding: 0px 18px;
    }

    &__button-label{
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $main-text-color;
        margin-right: 25px;
    }

    
    &__transparent-button{
        border-radius: 27px;
        border: 1px solid $contrast-color;
        background-color: transparent;
        color: $contrast-color;
        font-size: 13px;
        font-weight: 500;
        line-height: 19px;
        height: 40px;
        display: flex;
        align-items: center;
        padding:0px 43px;
        margin-left: 16px;
        cursor: pointer;
      }
  
      &__transparent-button:hover{
        border: 1px solid $click-color;
        color: $click-color;
      }
  
      &__transparent-button:active{
        border: 1px solid $click-color;
        color: $click-color;
        background-color: $gray-10;
      }

      &__transparent-button:disabled{
        background: $gray-10;
        color: $gray-90;
        border: 1px solid $gray-10
    }   

      &__cards-space{
        display: flex;
        justify-content:space-between;
        width: 100%;
        margin-top: 16px;
    }

    &__space{
        width: 32%;
    }

    &__card{
        background: $cards-color;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        width: 100%;
        padding: 18px 0px 0px 0px;
    }

    &__category-edit {
        width: 100%;
        display: flex;
        margin-bottom: 5px;
        transition: 0.5s;
      }

    &__category-edit-hover {
        width: 100%;
        display: flex;
        margin-bottom: 5px;
        background-color: $gray-10;
      }

    &__hover-blue-null{
        width: 2%;
        margin-right: 25px;
        border-radius: 0 200px 200px 0;
    }

    &__hover-blue {
        width: 2%;
        background-color: $contrast-color;
        margin-right: 25px;
        border-radius: 0 200px 200px 0;
        transition: 0.5s;
      }

    &__format-label {
        display: flex;
        align-items: center;
        margin: 0;
        font-size: 12px;
        margin-top: 16px;
    }

    &__edit-format-label{
        color: $contrast-color;
    }

    &__format{
        width: 100%;
        display: flex;
        margin-bottom: 5px;
    }

    &__cont {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: 55%;
      }
      
      &__cont input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      
      &__checkmark {
        position: absolute;
        top: 18px;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: transparent;
        border: 1.5px solid $gray-90;
        border-radius: 4px;
      }
     
      &__cont input:checked ~ &__checkmark {
          border: 1.5px solid $contrast-color;
          background-color: transparent;
          border-radius: 4px;
      }
      
      &__checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      &__checkmark2 {
        top: 0px !important;
        left: 15px !important;
      }
      
      &__cont input:checked ~ &__checkmark:after {
        display: block;
      }
      
      &__cont &__checkmark:after {
        left: 5px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid $contrast-color;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &__last-update{
        color: $gray-50;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.14px;
        float: right;
        margin-top: 16px;    
    }
}

.main-category{
    background: $cards-color;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;
    padding: 18px 0px 5px 0px;

    &__header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0px 41px 15px 41px;
    }
    &__labels{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0px 41px;
    }

    &__label{
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $main-text-color;
    }

    &__tooltip{
        display: flex;
        align-items: center;
        background: url('./../../images/tooltip/blue-help.svg') no-repeat 100% 50%;
        background-size: 30px 30px;
        padding: 15px 15px;
        cursor: pointer;
    }

    &__label-small{
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        color: $gray-90;
    }
}

.category{
    display: flex;
    justify-content: flex-end;
    margin-left: 5px;
    position: relative;

    &__triangle {
        width: 0;
        max-height: 30px;
        margin-top: 20px;
        border-top: 15px solid transparent;
        border-right: 13px solid $cards-color;
        border-bottom: 15px solid transparent;
        position: absolute;
        z-index: 9;
        left: -13px;
    }

      &__card{
        background: $cards-color;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        width: 100%;
        padding: 18px 0px 5px 0px;
      }

      &__header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0px 41px 7px 41px;
    }
    &__labels{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0px 41px;
    }

    &__label{
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $main-text-color;
        padding-bottom: 8px;
    }

      &__label-small{
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        color: $gray-90;
    }

    &__select-all-space{
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.08);
        border-radius: 5px;
        background-color: #f8f8f8;
        width: 45%;
        padding-top: 9px;
        color: #707070;
        font-family: Poppins;
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
    }

    &__select-all-label{
        min-width: 200px;
        padding-left: 5px;
        padding-top: 2px;
    }

    &__format{
        width: 100%;
        display: flex;
        margin-bottom: 5px;
    }
}

.sub-category{
    display: flex;
    justify-content: flex-end;
    margin-left: 5px;
    position: relative;

    &__triangle {
        width: 0;
        max-height: 30px;
        margin-top: 20px;
        border-top: 15px solid transparent;
        border-right: 13px solid $cards-color;
        border-bottom: 15px solid transparent;
        position: absolute;
        z-index: 9;
        left: -13px;
    }

      &__card{
        background: $cards-color;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        width: 100%;
        padding: 18px 0px 5px 0px;
      }

      &__header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0px 41px 7px 41px;
    }
    &__labels{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0px 41px;
    }

    &__label{
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $main-text-color;
        padding-bottom: 8px;
    }

      &__label-small{
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        color: $gray-90;
    }

    &__select-all-space{
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.08);
        border-radius: 5px;
        background-color: #f8f8f8;
        width: 45%;
        padding-top: 9px;
        color: #707070;
        font-family: Poppins;
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
    }

    &__select-all-label{
        min-width: 200px;
        padding-left: 5px;
        padding-top: 2px;
    }

    &__format{
        width: 100%;
        display: flex;
        margin-bottom: 5px;
    }
}