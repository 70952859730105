.orderbycategory {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 0.5rem;
  padding: 0.5rem 0 1rem;
}
.headerorderbycategory {
  width: 98%;
  height: 50px;
  background-color: $cards-color;
  border-radius: 8px;
  display: flex;
  align-items: center;
  &__sync.c-tooltip {
    width: 3.125rem;
    height: 1.4rem;
    &[data-sync="error"] {
      color: var(--fail-color) !important;
    }
    &[data-sync="succes"] {
      color: var(--success-color) !important;
    }
    .icon-Loading {
      transform-origin: 52% 60%;
      animation: loading 2s infinite linear;
    }
  }
  &__return {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    color: $contrast-color;
    margin-right: 20px;
    cursor: pointer;
  }
  &__arrow {
    margin-left: 20px;
    margin-right: 10px;
  }
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin-right: 10px;
  }
  &__department {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.12px;
    text-transform: capitalize;
    color: $gray-90;
  }

  .header {
    &__item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin: 0 0.5rem;
    }
    &__title {
      font-size: 18px;
      font-weight: 600;
      &:nth-child(odd) {
        color: var(--contrast-color);
      }
      &[type="text"] {
        max-width: 13rem;
      }
    }

    &__button {
      width: 1.375rem;
      height: 1.375rem;
      padding: 0;
      margin: 3px 4px 3px 3px;
      background: no-repeat center / 14px
        url("../../images/collections/icon_pen.svg");
      font-size: 0;
      color: transparent;
      cursor: pointer;
    }
  }
}

.contentTables {
  width: 98%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  &__filter {
    width: max-content;
    max-width: 98%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // gap: 2rem;
    margin-bottom: 20px;
  }
}
.topfive {
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: $cards-color;
  padding-top: 10px;
  &__label {
    width: 95%;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  &__products {
    width: 95%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-around;
    border-radius: 8px;
  }
  &__info {
    width: 95%;
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: $background-basic-color;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  &__info-title {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    width: 95%;
  }

  &__info-details {
    width: 95%;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
  }
}

.topfiveproduct {
  width: 17%;
  height: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  &__contentproduct {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    height: 70px;
    overflow: hidden;
  }
  &__img-product {
    width: 100%;
  }
  &__arrow {
    display: none;
  }
}

.topfiveproduct:hover .topfiveproduct__arrow {
  display: flex;
}
.topfiveproduct:hover .topfiveproduct__contentproduct {
  border: 1px solid $contrast-color;
}

.detailsproduct {
  width: 30%;
  height: 35px;
  display: flex;
  align-items: center;
  background-color: $cards-color;
  border-radius: 8px;
  &__detail {
    width: 70%;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    padding-left: 10px;
    color: $gray-90;
    border-right: 1px solid $gray-30;
  }
  &__value {
    display: flex;
    width: 30%;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: $contrast-color;
  }
}

.tableperformance {
  width: 63%;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 7px;
  background-color: $cards-color;
  &__titles {
    width: 95%;
    display: flex;
    margin-bottom: 10px;
  }
  &__title {
    width: 53%;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  &__subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $gray-90;
  }
}

.rowtableperformance {
  width: 95%;
  display: flex;
  height: 35px;
  background-color: $gray-10;
  border-radius: 5px;
  margin-bottom: 3px;
  &__space {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    border-right: 1px solid $gray-30;
  }
  &__space:last-child {
    border: none;
  }
}
.valtableperformance {
  width: 95%;
  display: flex;
  height: 35px;
  background-color: $gray-10;
  border-radius: 5px;
  margin-bottom: 3px;
  cursor: pointer;
  &__space {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    border-right: 1px solid $gray-30;
  }
  &__space:last-child {
    border: none;
  }
}
.valtableperformance:hover .valtableperformance__space {
  color: $contrast-color;
}
.tableordering {
  width: 98%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $cards-color;
  border-radius: 10px;
  padding-top: 20px;
  margin-bottom: 1rem;
}
.headertableordering {
  width: 96%;
  display: flex;
  justify-content: center;
  height: 80px;

  &__content {
    width: 50%;
    display: flex;
    align-items: center;
  }
  &__content:nth-child(2) {
    width: 50%;
    justify-content: flex-end;
  }
  &__lblproducts {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  &__button {
    background-color: $contrast-color;
    border-radius: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: $cards-color;
    margin-left: 20px;
    width: 22%;
    padding: 7px 0;
  }
  &__lastdays {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: $gray-90;
  }
}
.arrowsheaders {
  position: absolute;
  left: 3px;
  width: 10px;
  height: 17px;
  bottom: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__img {
    cursor: pointer;
  }
}
.headerstableordering {
  width: 96%;
  display: flex;
  flex-direction: column;
  border: 1px solid $gray-30;
  background-color: $gray-10;
  min-height: 50px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  &__addCollection {
    width: 11.9375rem;
    height: 2.5rem;
    cursor: pointer;
    background-color: $contrast-color;
    border-radius: 1.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5rem;
    color: $gray-10;
  }
  &__comming {
    opacity: 0.5;
    pointer-events: none;
  }
  &__header {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem;
  }
  &__header:nth-child(1) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &__header:nth-child(2) {
    height: 40px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
  }
  &__content-header {
    display: flex;
    align-items: center;
  }
  &__content-header:nth-child(2) {
    justify-content: flex-end;
  }

  &__content-functions {
    display: grid;
    gap: 0.5rem;
    width: 100%;
    grid-template-columns: 18rem 1fr max-content max-content;
    grid-template-areas: "search . criterias excel";
    .headerstableordering__content-search {
      grid-area: search;
    }
    .headerstableordering__area-criteria {
      grid-area: criterias;
    }
    .headerstableordering__icon-excel {
      grid-area: excel;
    }
  }
  &__content-buttons {
    display: flex;
    align-items: center;
  }
  &__content-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 33px;
    border: 1px solid $gray-90;
    background-color: #fff;
    border-radius: 25px;
    overflow: hidden;
  }
  &__content-search-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 33px;
    border: 1px solid $contrast-color;
    border-radius: 25px;
  }
  &__input {
    width: 80%;
    height: 30px;
    padding-left: 10px;
    font-family: Poppins;
    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      background-color: #fff !important;
    }
  }
  &__input::placeholder {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
  }
  &__search {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--contrast-color);
    border-radius: 40px;

    .k-icon {
      color: #fff;
    }
  }
  &__reset-table {
    color: $contrast-color;
    cursor: pointer;
  }
  &__close-action {
    background-color: #1475c5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 100%;
    width: 32px;
  }
  &__search-action {
    height: 100%;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $contrast-color;
    border-radius: 40px;
    color: $cards-color;
    cursor: pointer;
  }
  &__area-criteria {
    background-color: #fff;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding-left: 10px;
    border: 1px solid $contrast-color;
    border-radius: 20px;
    cursor: pointer;
  }
  &__criteria {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $contrast-color;
    border-radius: 40px;
  }
  &__head {
    font-size: 10px;
    color: $gray-90;
    border-right: 1px solid $gray-30;
    padding: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: $gray-10;
    min-width: 8.3%;
  }
  &__head:first-child {
    min-width: 4%;
  }
  &__head:nth-child(2) {
    min-width: 16.3%;
    position: sticky;
    left: 0;
    z-index: 999;
  }

  &__head:nth-child(9) {
    color: $cards-color;
    font-weight: 600;
    font-size: 12px;
    min-width: 11.3%;
    background-color: blue;
  }
  &__head:last-child {
    width: 28px;
    min-width: 28px;
    cursor: pointer;
    border: none;
  }
  &__checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 0;
    user-select: none;
  }
  &__inputcheck {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  &__spancheck {
    position: absolute;
    height: 15px;
    width: 15px;
    top: 10.5px;
    background-color: $gray-10;
    border: 1px solid $gray-90;
    border-bottom: 1px solid $gray-90;
    border-radius: 4px;
  }
  &__inputcheck:checked ~ &__spancheck {
    background-color: $background-basic-color;
    border: 1px solid $contrast-color;
    border-bottom: 1px solid $contrast-color;
  }
  &__spancheck:after {
    content: "";
    position: absolute;
    display: none;
  }
  &__inputcheck:checked ~ &__spancheck:after {
    display: block;
  }
  &__checkbox &__spancheck:after {
    left: 4px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid $contrast-color;
    border-width: 0px 1px 1px 0;
    transform: rotate(45deg);
  }
}
.rowbodyordering {
  width: 100%;
  border-radius: 3px;
  outline: none;
}
.tableheadandbody {
  width: 96%;
  min-height: 50vh;
  overflow-x: scroll;
  position: relative;
  &__header {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 14px;
    z-index: 930;
    .categoryName {
      width: 10%;
    }
  }

  &__input {
    width: 80%;
    height: 30px;
    padding-left: 10px;
    font-family: Poppins;
  }
  &__input::placeholder {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
  }
  &__search {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray-30;
    border-radius: 40px;
  }

  &__head {
    font-size: 11px;
    color: $gray-90;
    border-right: 1px solid $gray-30;
    border-bottom: 1px solid $gray-30;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: $gray-10;
    width: 6.5%;
    min-width: 81px;
    position: relative;
    &.sellerName {
      width: 10%;
    }
  }
  &__heads {
    font-size: 11px;
    color: $gray-90;
    border-right: 1px solid $gray-30;
    border-bottom: 1px solid $gray-30;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: $gray-10;
    width: 7%;
    position: relative;
    cursor: pointer;
  }
  &__head-coll {
    font-size: 11px;
    color: $gray-90;
    border-right: 1px solid $gray-30;
    border-bottom: 1px solid $gray-30;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: $gray-10;
    position: relative;
    width: 10%;
    cursor: pointer;
  }
  &__head-label {
    color: $contrast-color;
  }
  &__head-label-normal {
    font-size: 11.5px;
    padding: 0 5px;
    white-space: pre-wrap;
    cursor: pointer;
    font-weight: 450;
  }
  &__head:first-child {
    min-width: 51.39px;
    width: 4%;
    border-left: 1px solid $gray-30;
    border-bottom-left-radius: 8px;
  }
  &__head-coll:first-child {
    border-left: 1px solid $gray-30;
    border-bottom-left-radius: 8px;
  }
  &__head:nth-child(2) {
    width: 21.5%;
    min-width: 255px;
    position: sticky;
    left: 0;
    z-index: 9;
  }
  &__head-coll:nth-child(2) {
    width: 21%;
    position: sticky;
    left: 0;
    z-index: 9;
  }
  /* &__head-coll:nth-child(4) {
    width: 15%;
    position: sticky;
    left: 0;
    z-index: 9;
  } */
  // &__head-coll:nth-child(7) {
  //   width: 14%;
  //   position: sticky;
  //   left: 0;
  //   z-index: 9;
  // }
  &__head-coll:nth-child(3) {
    width: 8%;
    position: sticky;
    left: 0;
    z-index: 9;
  }
  &__head-coll:nth-child(5) {
    width: 8%;
    position: sticky;
    left: 0;
    z-index: 9;
  }
  &__head-coll:nth-child(6) {
    width: 8%;
    position: sticky;
    left: 0;
    z-index: 9;
  }
  &__head-coll:nth-child(7) {
    width: 8%;
    position: sticky;
    left: 0;
    z-index: 9;
  }
  &__head:nth-child(11) {
    color: $cards-color;
    font-weight: 600;
    font-size: 12px;
    min-width: 95px;
    width: 7.5%;
    background-color: $high-smartman-color;
    .tableheadandbody__filter {
      filter: brightness(7.5);
    }
    .tableheadandbody__head-label {
      color: var(--gray-30);
    }
  }
  &__head:last-child {
    width: 2%;
    min-width: 25px;
    cursor: pointer;
    border: none;
    border-right: 1px solid $gray-30;
    border-bottom: 1px solid $gray-30;
    border-bottom-right-radius: 8px;
  }
  &__head-coll:last-child {
    width: 2%;
    min-width: 25px;
    cursor: pointer;
    border: none;
    border-right: 1px solid $gray-30;
    border-bottom: 1px solid $gray-30;
    border-bottom-right-radius: 8px;
  }
  &__heads:first-child {
    width: 4%;
    border-left: 1px solid $gray-30;
    border-bottom-left-radius: 8px;
  }
  &__heads:nth-child(2) {
    width: 22%;
    position: sticky;
    left: 0;
    z-index: 9;
  }

  &__heads:nth-child(11) {
    color: $cards-color;
    font-weight: 600;
    font-size: 12px;
    width: 9%;
    background-color: $high-smartman-color;
  }
  &__heads:last-child {
    width: 2%;
    cursor: pointer;
    border: none;
    border-right: 1px solid $gray-30;
    border-bottom: 1px solid $gray-30;
    border-bottom-right-radius: 8px;
    min-width: 23.77px;
  }
  &__head-menu {
    background-image: url("../../images/orderbycategory/menu-header.png");
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  &__checkbox {
    position: relative;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 0;
    user-select: none;
  }
  &__inputcheck {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  &__spancheck {
    position: absolute;
    height: 15px;
    width: 15px;
    top: 15.5px;
    background-color: $gray-10;
    border: 1px solid $gray-90;
    border-bottom: 1px solid $gray-90;
    border-radius: 4px;
  }
  &__inputcheck:checked ~ &__spancheck {
    background-color: $background-basic-color;
    border: 1px solid $contrast-color;
    border-bottom: 1px solid $contrast-color;
  }
  &__spancheck:after {
    content: "";
    position: absolute;
    display: none;
  }
  &__inputcheck:checked ~ &__spancheck:after {
    display: block;
  }
  &__checkbox &__spancheck:after {
    left: 4px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid $contrast-color;
    border-width: 0px 1px 1px 0;
    transform: rotate(45deg);
  }

  &__content-arrows {
    position: absolute;
    right: 1px;
    width: 10px;
    height: 90%;
    display: flex;
    flex-direction: column;
    padding-top: 2px;
  }
  &__filter {
    cursor: pointer;
  }
  &__status {
    margin-left: 20px;
    &::after {
      content: "";
      display: block;
      opacity: 0;
      width: 0.9375rem;
      height: 0.9375rem;
      border-radius: 50%;
    }
    &[data-state="activo"]::after {
      opacity: 1;
      background-color: $success-color;
    }
    &[data-state="pendiente"]::after {
      opacity: 1;
      background-color: $alert-color;
    }
    &[data-state="finalizado"]::after {
      opacity: 1;
      background-color: $fail-color;
    }
    &-text {
      width: 3.9375rem;
      text-align-last: left;
    }
  }
}
.dragging {
  border-radius: 3px;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
}
.dragging :focus {
  box-shadow: 0 0px 5px 1px #4c9ffe;
}
.sorting {
  pointer-events: none;
}

.selected {
  background-color: $background-basic-color;
}
.itemrowbodycollection {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50px;
  color: #333;
  font-weight: 400;
  margin-bottom: 14px;
  position: relative;
  &:hover .itemrowbodycollection__body-coll {
    background-color: #e5e5e5;
  }
  &__comming {
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $gray-10;
    color: $contrast-color;
    border-radius: 4px;
  }
  &__tooltip {
    display: grid;
    gap: 0.5rem;
    &__item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 0.625rem;
      text-align: left;
      color: $gray-10;
      border-bottom: 1px solid #6cbdff;
      cursor: default;
      &:last-child {
        border-bottom: none;
      }
      &::before {
        content: "";
        display: block;
        width: 1.125rem;
        height: 1.125rem;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &[data-type="edit"] {
        &:before {
          background-image: url("../../images/collections/icon_edit.svg");
        }
      }
      &[data-type="delete"] {
        &:before {
          background-image: url("../../images/collections/icon_delete.svg");
        }
      }
      &[data-type="duplicate"] {
        &:before {
          background-image: url("../../images/collections/icon_duplicate.svg");
        }
      }
      &[data-type="change-state"] {
        &:before {
          background-image: url("../../images/collections/icon_change-state.svg");
        }
      }
    }
  }
  &__body-coll {
    font-size: 13px;
    border-right: 1px solid #e2e2e2;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #f8f8f8;
    width: 10%;
    cursor: pointer;
    transition: background 0.25s linear;
    &.tableheadandbody__head-menu {
      cursor: pointer;
    }
    &:first-child {
      width: 10%;
      position: sticky;
      left: 0;
      z-index: 9;
      justify-content: center;
      gap: 0.5rem;
    }
    &:nth-child(2) {
      width: 21%;
      position: sticky;
      cursor: pointer;
      left: 0;
      z-index: 9;
    }
    /* &:nth-child(4) {
      width: 15%;
      position: sticky;
      left: 0;
      z-index: 9;
    } */
    // &:nth-child(7) {
    //   width: 14%;
    //   position: sticky;
    //   left: 0;
    //   z-index: 9;
    // }
    &:nth-child(3) {
      width: 8%;
      position: sticky;
      left: 0;
      z-index: 9;
    }
    &:nth-child(5) {
      width: 8%;
      position: sticky;
      left: 0;
      z-index: 9;
    }
    &:nth-child(6) {
      width: 8%;
      position: sticky;
      left: 0;
      z-index: 9;
    }
    &:nth-child(7) {
      width: 8%;
      position: sticky;
      left: 0;
      z-index: 9;
    }
    &:nth-child(9) {
      width: 2%;
      min-width: 25px;
      border-right: none;
      border-top-right-radius: 8px;
      border-color: transparent !important;
      border-bottom-right-radius: 8px;
      margin: 0;
      height: 100%;
    }
  }
  &__body-icon {
    font-size: 2rem;
  }
  &__body-img {
    width: 1rem;
  }
}
.itemrowbodyordering {
  display: flex;
  width: 100%;
  height: 50px;
  color: #333;
  font-weight: 400;
  margin-bottom: 14px;
  position: relative;

  &__rowbody {
    font-size: 13px;
    border-right: 1px solid $gray-30;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: $gray-10;
    width: 6.5%;
    min-width: 81px;
    word-break: break-all;
    padding: 0 5px;
    &.sellerName,
    &.category {
      width: 10%;
      display: flex;
      align-items: center;
      span {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 13px;
        word-break: break-word;
      }
    }
  }
  &__rowbodys {
    font-size: 13px;
    border-right: 1px solid $gray-30;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: $gray-10;
    width: 7%;
  }
  &__rowbody1 {
    border-right: 1px solid $gray-30;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    min-width: 51.39px;
    width: 4%;
    position: relative;
    height: 100%;
    background-color: $gray-10;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__rowbody2 {
    border-right: 1px solid $gray-30;
    width: 21.5%;
    min-width: 255px;
    position: sticky;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $gray-10;
  }

  &__rowbody2s {
    border-right: 1px solid $gray-30;
    width: 22%;
    position: sticky;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $gray-10;
  }
  &__training_smartman {
    background-color: #f6bd411f;
    display: flex;
    min-width: 90px;
    width: 10%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  &__item-slow-smartmanscore {
    background-color: $low-smartman-color;
    color: $main-text-color;
    font-weight: 600;
    font-size: 12px;
    min-width: 95px;
    width: 7.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  &__middle-slow-smartmanscore {
    background-color: $middle-smartman-color;
    color: $main-text-color;
    font-weight: 600;
    font-size: 12px;
    min-width: 95px;
    width: 7.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  &__high-slow-smartmanscore {
    background-color: $high-smartman-color;
    color: $cards-color;
    font-weight: 600;
    font-size: 12px;
    min-width: 95px;
    width: 7.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  &__item-slow-smartmanscores {
    background-color: $low-smartman-color;
    color: $main-text-color;
    font-weight: 600;
    font-size: 12px;
    width: 9%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  &__middle-slow-smartmanscores {
    background-color: $middle-smartman-color;
    color: $main-text-color;
    font-weight: 600;
    font-size: 12px;
    width: 9%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  &__high-slow-smartmanscores {
    background-color: $high-smartman-color;
    color: $cards-color;
    font-weight: 600;
    font-size: 12px;
    width: 9%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  &__rowbody:last-child {
    width: 2%;
    min-width: 25px;
    border-right: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  &__checkbox {
    position: relative;
    width: 50%;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 0;
    user-select: none;
  }
  &__inputcheck {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  &__spancheck {
    position: absolute;
    height: 15px;
    width: 15px;
    top: 14.5px;
    background-color: $gray-10;
    border: 1px solid $gray-90;
    border-bottom: 1px solid $gray-90;
    border-radius: 4px;
  }
  &__inputcheck:checked ~ &__spancheck {
    background-color: $background-basic-color;
    border: 1px solid $contrast-color;
    border-bottom: 1px solid $contrast-color;
  }
  &__spancheck:after {
    content: "";
    position: absolute;
    display: none;
  }
  &__inputcheck:checked ~ &__spancheck:after {
    display: block;
  }
  &__checkbox &__spancheck:after {
    left: 4px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid $contrast-color;
    border-width: 0px 1px 1px 0;
    transform: rotate(45deg);
  }
  &__nameproduct {
    width: 78%;
    height: 99%;
    padding: 0;
    text-align: initial;
    text-overflow: ellipsis;
    margin: 0;
    display: flex;
    align-items: center;
  }
  &__nameproduct:hover {
    color: $contrast-color;
    cursor: pointer;
  }
  &__imgproduct {
    width: 25%;
    min-width: 2.5rem;
    height: 85%;
    display: flex;
    overflow: hidden;
    justify-content: center;
    box-shadow: 2px 3px 5px 0px rgba(214, 214, 214, 1);
    border-radius: 8px;
    background-color: $cards-color;
    max-width: 46.55px;
    margin-left: 5px;
  }

  &__img {
    width: 130%;
    height: 100%;
    cursor: pointer;
  }
  &__inputsmartman {
    width: 30%;

    min-width: 70px;
    height: 60%;
    outline: none;
    border: 1px solid $gray-30;
    border-radius: 8px;
    text-align: center;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
    &[type="number"] {
      appearance: textfield;
    }
  }
  &__inputsmartman:focus {
    border: 1px solid $contrast-color;
  }

  &__element {
    position: relative;
    word-break: break-word;
    text-transform: capitalize;
  }

  &__element a {
    text-decoration: underline;
    color: var(--contrast-color);
  }

  &__element section {
    display: none;
    position: relative;
  }
  &__element div {
    font-family: "Poppins";
    background-color: $main-text-color !important;
    border: 1px solid $gray-30;
    min-height: 31px;
    align-items: center;
    letter-spacing: 0.12px;
    color: white;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-size: 10px;
    font-weight: normal;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    max-width: 700px;
    line-height: 15px;
    top: -61px;
    position: absolute;
    z-index: 9999;
    padding: 10px 30px 10px 10px;
    width: max-content;
    background: url("../../images/orderbycategory/link-icon.svg") no-repeat 97%
      43%;
  }

  &__element div:hover {
    color: $gray-50;
    text-decoration: underline;
  }

  &__element:hover section {
    display: block;
  }
}

.zoom-item {
  position: absolute;
  width: 300px;
  z-index: 9999999999999;
  top: 5px;
  left: 111px;
  display: flex;

  &__content {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: $cards-color;
    border: 1px solid $gray-30;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 15px;
  }
  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__header-label {
    width: 70%;
    white-space: pre-wrap;
    color: $contrast-color;
  }
  &__icon {
    width: 35px;
    height: 35px;
    cursor: pointer;
  }
  &__image {
    margin-bottom: 10px;
    width: 230px;
  }
  &__label {
    width: 80%;
    font-size: 13px;
    font-weight: 500;
  }
  &__arrow-indicative {
    position: absolute;
    width: 15px;
    height: 15px;
    left: -8px;
    background-color: $cards-color;
    transform: rotate(45deg);
    border-bottom: 1px solid $gray-30;
    border-left: 1px solid $gray-30;
  }
}

.modalcriteria {
  position: absolute;
  top: 15%;
  left: 30%;
  width: 50%;
  min-height: 450px;
  background-color: $cards-color;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__header {
    background-color: $main-text-color;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    width: 100%;
  }
  &__content-header {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    width: 65%;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: $cards-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__close {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
}

.orderandcriteria {
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: $gray-10;
  border: 1px solid $gray-30;
  margin-bottom: 10px;
  &__div {
    width: 50%;
    display: flex;
    padding: 10px 0 10px 40px;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  &__div:first-child {
    border-right: 1px solid $gray-30;
  }
}

.rowscriteriasort {
  width: 97%;
  display: flex;
  border-radius: 8px;
  padding: 2px 0px;

  &__row {
    width: 50%;
    padding: 15px 15px 15px 50px;
    margin-bottom: 10px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__label {
    cursor: pointer;
    width: 90%;
    height: 100%;
  }
  &__img {
    width: 15px;
    height: 8px;
    cursor: pointer;
  }
  &__row:first-child {
    border-right: 1px solid rgb(238, 238, 238);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &__row:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.listcriteria {
  display: flex;
  width: 100%;
  padding-left: 40px;
  cursor: default;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  align-items: center;
  height: 35px;
  &__checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    left: -27px;
    top: -4px;
  }

  &__checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &__check {
    position: absolute;
    top: -4px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: $gray-10;
    border: 1px solid $gray-90;
    border-bottom: 1px solid $gray-90;
    border-radius: 18px;
  }

  &__checkbox input:checked ~ &__check {
    background-color: $background-basic-color;
    border: 1px solid $contrast-color;
    border-bottom: 1px solid $contrast-color;
  }

  &__check:after {
    content: "";
    position: absolute;
    display: none;
  }

  &__checkbox input:checked ~ &__check:after {
    display: block;
  }

  &__checkbox &__check:after {
    left: 4px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid $contrast-color;
    border-width: 0px 1px 1px 0;
    transform: rotate(45deg);
  }
  &__criteria {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.listcriteria:hover {
  background-color: $cards-color;
}

.list-options-modal {
  position: absolute;
  left: 20px;
  width: 46%;
  max-height: 175px;
  overflow-y: scroll;
  background-color: $background-basic-color;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
}
.list-options-modal-none {
  display: none;
}
.list-options-modal-0 {
  top: 165px;
}
.list-options-modal-1 {
  top: 220px;
}
.list-options-modal-2 {
  top: 285px;
}
.list-options-modal-3 {
  top: 345px;
}
.list-options-modal::-webkit-scrollbar {
  width: 5px;
  border-radius: 4px;
}
.list-options-modal::-webkit-scrollbar-thumb {
  background: $gray-50;
  border-radius: 4px;
}

.list-options-modal::-webkit-scrollbar-track {
  background: $cards-color;
  border-radius: 4px;
}
.buttonsmodalcriteria {
  width: 60%;
  margin: auto;
  padding: 5px 0px 20px 0px;
  text-align: center;
  display: flex;
  justify-content: space-around;
  &__button {
    width: 20%;
    border-radius: 20px;
    padding: 10px 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  &__button:first-child {
    background-color: $gray-10;
    color: $gray-90;
    cursor: pointer;
  }
  &__button:last-child {
    background-color: $contrast-color;
    color: $cards-color;
    cursor: pointer;
  }
}

.plus-or-less-0 {
  width: 46%;
  background-color: $background-basic-color;
  position: absolute;
  top: 105px;
  left: 52%;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 8px 8px;
}
.plus-or-less-1 {
  width: 46%;
  background-color: $background-basic-color;
  position: absolute;
  top: 160px;
  left: 52%;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 8px 8px;
}
.plus-or-less-2 {
  width: 46%;
  background-color: $background-basic-color;
  position: absolute;
  top: 225px;
  left: 52%;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 8px 8px;
}
.plus-or-less-3 {
  width: 46%;
  background-color: $background-basic-color;
  position: absolute;
  top: 287px;
  left: 52%;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 8px 8px;
}
.optionsplusorless {
  display: flex;
  width: 100%;
  height: 40px;
  cursor: default;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding-left: 40px;

  &__checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    left: -25px;
    top: -4px;
  }

  &__checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &__check {
    position: absolute;
    top: -4px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: $gray-10;
    border: 1px solid $gray-90;
    border-bottom: 1px solid $gray-90;
    border-radius: 18px;
  }

  &__checkbox input:checked ~ &__check {
    background-color: $background-basic-color;
    border: 1px solid $contrast-color;
    border-bottom: 1px solid $contrast-color;
  }

  &__check:after {
    content: "";
    position: absolute;
    display: none;
  }

  &__checkbox input:checked ~ &__check:after {
    display: block;
  }

  &__checkbox &__check:after {
    left: 4px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid $contrast-color;
    border-width: 0px 1px 1px 0;
    transform: rotate(45deg);
  }
  &__option-label {
    width: 90%;
    height: 95%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.optionsplusorless:hover {
  background-color: $cards-color;
}

.popUpOrder {
  position: fixed;
  top: 5.375rem;
  left: -135px;
  background-color: #001130e0;
  z-index: 9;
  border-radius: 6px;
  display: none;
  visibility: hidden;
  width: 112%;
  font-size: 12px;
  height: 50px;
  color: $cards-color;
  align-items: center;
  justify-content: center;
  &[data-visible="true"] {
    display: flex;
    visibility: visible;
  }

  &__content {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__gomass {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $main-text-color;
    padding: 5px 15px;
    border-radius: 20px;
    margin: 0 0 0 10px;
    cursor: pointer;
    background-color: $cards-color;
  }

  &__span {
    border: 1px solid $cards-color;
    margin: 0 5px;
    padding: 5px 10px;
    border-radius: 5px;
  }
}
.cell__nameproduct {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.modalvtexscore {
  position: absolute;
  top: 15%;
  left: 30%;
  width: 50%;
  max-width: 35.3125rem;
  height: 18.75rem;
  background-color: $cards-color;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__content-options {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__header {
    background-color: $main-text-color;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    width: 100%;
  }
  &__header-label {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: $cards-color;
  }
  &__img {
    cursor: pointer;
  }
  &__optionvtexscore {
    display: flex;
    width: 90%;
    cursor: default;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    border-radius: 5px;
    align-items: center;
    margin-bottom: 20px;
  }

  &__checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
  }
  &__checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &__check {
    position: absolute;
    top: -9px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: $gray-10;
    border: 1px solid $gray-90;
    border-bottom: 1px solid $gray-90;
    border-radius: 18px;
  }
  &__checkbox input:checked ~ &__check {
    background-color: $background-basic-color;
    border: 1px solid $contrast-color;
    border-bottom: 1px solid $contrast-color;
  }

  &__check:after {
    content: "";
    position: absolute;
    display: none;
  }

  &__checkbox input:checked ~ &__check:after {
    display: block;
  }
  &__checkbox &__check:after {
    left: 4px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: 1px solid $contrast-color;
    border-width: 0px 1px 1px 0;
    transform: rotate(45deg);
  }

  &__option {
    margin-left: 30px;
  }

  &__input {
    width: 10%;
    border: 1px solid $gray-30;
    text-align: center;
    margin: 0 8px;
    padding: 5px 0;
    border-radius: 8px;
    outline: none;

    &[type="number"] {
      appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
      }
    }
  }
  &__buttons {
    width: 60%;
    margin: auto;
    padding: 5px 0px 20px 0px;
    text-align: center;
    display: flex;
    justify-content: space-around;
  }
  &__button {
    background-color: $gray-10;
    color: $gray-90;
    width: 45%;
    border-radius: 20px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  &__button:first-child {
    color: $main-text-color;
    cursor: pointer;
  }
  &__button:last-child {
    background-color: $contrast-color;
    color: $cards-color;
    cursor: pointer;
  }
}

.optionstablehide {
  display: none;
}

.optionstable {
  position: absolute;
  z-index: 998;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: 3px;
  top: 0;
  width: 23%;
  background-color: $background-basic-color;
  padding: 40px 0 0 0;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 8px 8px;
  animation-duration: 0.2s;
  animation-name: slidein;
}

.addcolumn {
  &__close {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  &__close-img {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  &__label {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.12px;
    margin-bottom: 20px;
  }
  &__options {
    display: flex;
    align-items: center;
    width: 100%;
    height: 35px;
    padding-left: 30px;
    cursor: default;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    cursor: pointer;
  }
  &__options:hover {
    background-color: $cards-color;
  }
  &__option {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    margin-right: 25px;
  }
  &__checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  &__span {
    position: absolute;
    top: -8px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: $gray-10;
    border: 1px solid $gray-90;
    border-bottom: 1px solid $gray-90;
    border-radius: 18px;
  }
  &__checkbox:checked ~ &__span {
    background-color: $background-basic-color;
    border: 1px solid $contrast-color;
    border-bottom: 1px solid $contrast-color;
  }
  &__span:after {
    content: "";
    position: absolute;
    display: none;
  }
  &__checkbox:checked ~ &__span:after {
    display: block;
  }
  &__option &__span:after {
    left: 4px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: 1px solid $contrast-color;
    border-width: 0px 1px 1px 0;
    transform: rotate(45deg);
  }

  &__nameoption {
    width: 85%;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__button {
    border: 1px solid $contrast-color;
    cursor: pointer;
    padding: 10px 25px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: $contrast-color;
    margin-bottom: 30px;
  }
}
.paginador {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  &__content {
    display: flex;
    align-items: center;
    border-radius: 20px;
    border: 1px solid $gray-50;
    padding: 0 0 0 3px;
    width: 5%;
    margin: 0 5px 0 5px;
  }
  &__input {
    width: 80%;
    height: 14px;
    text-align: center;
  }

  &__button {
    background-color: $gray-30;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  &__next {
    display: flex;
    width: 5%;
    justify-content: space-between;
    margin-left: 5px;
  }
}

.savechanges {
  width: 96%;
  height: 40px;
  display: flex;
  &__content {
    width: 50%;
    display: flex;
    align-items: center;
  }
  &__content:nth-child(2) {
    justify-content: flex-end;
  }
  &__reset {
    background-color: $contrast-color;
    border-radius: 30px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
  }
  &__reset-text {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: $contrast-color;
    margin-left: 10px;
  }
  &__save {
    background-color: $contrast-color;
    border-radius: 30px;
    color: $cards-color;
    width: 20%;
    padding: 7px 0;
    cursor: pointer;
  }
  &__last90 {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    color: $gray-90;
  }
}

.modalscreenordering {
  width: 100%;
  height: 100%;
  position: relative;
  &__fondo {
    width: 100%;
    height: 100%;
  }
  &__area-msg {
    position: absolute;
    top: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.messageloading {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 20px;
  }
  &__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 40px;
  }
}
.messagewithoutproduts {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 20px;
  }
  &__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 40px;
  }
}
.filterheadertable {
  width: 300px;
  top: 51px;
  position: absolute;
  z-index: 9;
  background-color: $cards-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10pxs;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: $main-text-color;
  font-weight: initial;
  font-size: 12px;

  &__form {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 10px;
  }
  &__input {
    width: 60%;
    border: 1px solid $gray-30;
    border-radius: 4px;
    padding-left: 5px;
  }
  &__input::placeholder {
    color: $gray-50;
  }
  &__double-input {
    width: 65%;
    display: flex;
    justify-content: space-between;
  }
  &__d-input {
    width: 40%;
    border: 1px solid $gray-30;
    border-radius: 4px;
    padding-left: 5px;
  }
  &__d-input::placeholder {
    color: $gray-50;
  }

  &__filterby {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    width: 90%;
    text-align: start;
    margin: 10px;
  }
  &__line {
    width: 100%;
    border-top: 1px solid $gray-50;
    margin-bottom: 10px;
  }
  &__space-sort {
    width: 90%;
    display: flex;
    margin-bottom: 15px;
  }
  &__buttons {
    width: 100%;
    height: 50px;
    border-top: 1px solid $gray-50;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  &__button-1 {
    border: 1px solid $contrast-color;
    border-radius: 30px;
    color: $contrast-color;
    padding: 5px 15px;
  }
  &__button-2 {
    background-color: $contrast-color;
    border-radius: 30px;
    padding: 5px 15px;
    color: $cards-color;
  }

  &__option {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    margin-right: 25px;
  }
  &__checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  &__span {
    position: absolute;
    top: 0px;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: $gray-10;
    border: 1px solid $gray-90;
    border-bottom: 1px solid $gray-90;
    border-radius: 18px;
  }

  &__span:after {
    content: "";
    position: absolute;
    display: none;
  }
  &__checkbox:checked ~ &__span:after {
    display: block;
    background-color: $contrast-color;
  }
  &__option &__span:after {
    left: 2.5px;
    top: 3px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $cards-color;
  }
}

.custom-header {
  &-menu {
    position: fixed;
    background-color: #fff;
    width: max-content;
    min-height: 6.25rem;
    padding: 0.5rem;
    top: 39.6%;
    right: 0;
    z-index: 1;
    border: 2px solid #dee2e6;
    border-right: none;
    border-radius: 0 0 0 15px;
    animation: fadeInRight 0.5s ease-in-out;
    &__content {
      width: 13.5rem;
      display: grid;
      grid-template: 2rem / 1fr;
      grid-auto-rows: 2rem;
      align-items: center;
    }
    &__label {
      width: 100%;
      display: flex;
      gap: 0.25rem;
      cursor: pointer;
    }
  }
  &-cell {
    margin-left: -11px;
    overflow: visible !important;
    padding: 0 !important;
    display: flex;
    width: 1.875rem;
    height: 3.125rem;
    align-items: center;
    justify-content: center;
  }
  &-button {
    all: unset;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAASCAMAAABLoI/KAAAAAXNSR0IB2cksfwAAADZQTFRFAAAAcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwcHBwHGWKzAAAABJ0Uk5TAGDv3zDw/8DgsCAQcNCgkFCAOyzEMwAAAD1JREFUeJxNy0EWgCAMxNCpYioULd7/sspzQ9Y/sm0vOsB1QlXxWrTUQupgYpJrknZnrGR8Q8LzE4dUdNMLPdMBhmO3m0EAAAAASUVORK5CYII=);
    width: 1.875rem;
    height: 3.125rem;

    background-repeat: no-repeat;
    background-position: 50%;
    cursor: pointer;
  }
}

@keyframes slidein {
  from {
    height: 0;
  }

  to {
    height: 363px;
  }
}

@keyframes loading {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
