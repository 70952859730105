.configuration{
    display: block;
    width: 100%;
    padding: 13px;
    &__title{
        font-size: 25px;
        font-weight: 500;
        background: $cards-color;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.160784);
        border-radius: 10px;
        margin-bottom: 13px;
        padding: 8px 27px;
    }
    &__card {
        width: 100%;
        background: $cards-color;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.160784);
        border-radius: 10px;
        padding: 25px 27px;
        margin-bottom: 13px;
    }
}

.store{
    position: relative;

    input:disabled{
        opacity: 0.5;
    }

    button:disabled{
        opacity: 0.5;
    }

    textarea:disabled{
        opacity: 0.5;
    }

    button:disabled:hover{
        box-shadow: none;
    }

    &__body{
        display: flex;
        flex-wrap: wrap;
    }

    &__row{
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
    }

    &__row:first-child{
        justify-content: space-between; 
        align-items: center;
        padding-bottom: 30px;
    }

    &__label-row{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        padding: 0px 20px;
    }
    
    &__input{
        border: 1px solid $gray-90;
        opacity: 1;
        color: var(--main-text-color);
        border-radius: 8px;
        width: 100% ;
        min-height: 40px;
        margin-top: 0px;
        margin-bottom: 20px;
        padding-left: 18px ;
        background-color: transparent;
        font-size: 13px ;
    }

    &__input:focus{
        border: 1px solid $contrast-color;
        opacity: 1;
        color:$main-text-color  ;
        outline: none;
    }

    &__input:placeholder-shown{
        opacity: 0.4;
        color:$gray-90;
        margin-bottom:20px;
        background-color: transparent;
      }
  
    &__input-invalid{
        mix-blend-mode: normal;
        border: 1px solid $fail-color;
        opacity: 1;
        color:$main-text-color ;
        box-sizing: border-box;
        border-radius: 25px;
        width: 100%;
        min-height: 36px;
        margin-top: 0px;
        margin-bottom:0px;
        padding-left: 20px;
        font-size: 13px;
        background-color: transparent;
    } 

    &__text-area{
        resize:none;
        text-transform: uppercase;
        padding: 11px 20px;     
        margin-bottom: 33px;  
    }
    
    &__text-area-invalid{
        resize:none;
        text-transform: uppercase;
        padding-right: 20px;  
    }

    &__error{
        color: $fail-color;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 300;
        line-height: 20px;
        margin-left: 20px;
    }

    &__label{
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
    }

    
 &__element {
    position: relative;  
  }
  
  &__element a{
      text-decoration: underline;
      color:var(--contrast-color)
  }
  
  &__element section{
        display: none;
  }
  &__element div {
      font-family:'Poppins';
      background-color: $main-text-color;
      border: 1px solid $gray-30;
      min-height: 31px;
      align-items: center;
      text-align: center;
      letter-spacing: 0.12px;
      color: white;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      font-size: 10px;
      font-weight: normal;
      align-items: center;
      text-align: left;
      vertical-align: middle;
      margin-bottom: 20px;
      width: 275px;
      line-height: 15px;
      top: 55px;
      position: relative;
      z-index: 5;
      right: 250px;
      padding: 10px;
    }
  
    &__element:hover section {
      display: block;
  }

  &__button-tooltip-space{
    height: 31px;
      padding-top:0px;
      position: absolute;
      top:-30px;
      display: inline-block;
    }

    &__tooltip-icon{
        display: flex;
        align-items: center;
        background: url('./../../images/tooltip/blue-help.svg') no-repeat 100% 50%;
        background-size: 26px 26px;
        padding: 13px 25px;
        cursor: pointer;
    }

    &__tooltip-icon-invalid{
        display: flex;
        align-items: center;
        background: url('./../../images/tooltip/information.svg') no-repeat 100% 50%;
        background-size: 26px 26px;
        padding: 13px 25px;
        cursor: pointer;
    }

    &__contrast-button{
        border-radius: 20px;
        background-color: $contrast-color;
        color: $cards-color;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        cursor: pointer;
        margin: auto auto auto 40px;
      }
  
      &__contrast-button:hover{
        box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
      }
  
      &__contrast-button:active{
        background-color: $click-color;
      }

      &__analytics-button{
        border-radius: 20px;
        border: 1px solid $main-text-color;
        background: url('./../../images/newstore/ga.svg') no-repeat 50% 50%;
        height: 40px;
        width: 200px;
        cursor: pointer;
        margin: auto;
      }

      &__analytics-button:hover{
        box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
      }

      &__analytics-button-check{
        border-radius: 20px;
        border: 1px solid $contrast-color;
        height: 40px;
        width: 200px;
        cursor: pointer;
        margin: auto 0 auto auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__ga-check{
          width: auto;
          display: flex;
          justify-content: flex-start;
      }

      &__analytics-button-check:hover{
        box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
      }

      &__index-loader{
        position: absolute;
        height: 100%;
        width: 100%;
        background: $gray-10;
        opacity: 0.95;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        color: $contrast-color;
        justify-content: center;
        align-items: center;
        z-index: 2;
    }

    &__button-group{
        background: $cards-color;
        border: 1px solid $gray-30;
        box-sizing: border-box;
        border-radius: 5px;
        width: 112px;
        height: 30px;
        display: flex;
        align-items: center;
    }

    &__syn-button{
        background: url('./../../images/configuration/syn-gray.svg') no-repeat 50% 50%;
        background-size: 15.2px 17px;
        padding: 7.6px 13.5px;
        width: 37px;
        cursor: pointer;
    }

    &__syn-button:hover{
        background: url('./../../images/configuration/syn-blue.svg') no-repeat 50% 50%;
        background-size: 15.2px 17px;
        padding: 7.6px 13.5px;
        width: 37px;
        cursor: pointer;
    }

    &__syn-button:active{
        background: url('./../../images/configuration/syn-blue.svg') no-repeat 50% 50%;
        background-size: 15.2px 17px;
        padding: 7.6px 13.5px;
        width: 37px;
        cursor: pointer;
    }

    &__edit-button{
        background: url('./../../images/configuration/edit-gray.svg') no-repeat 50% 50%;
        background-size: 29px 29px;
        padding: 7.6px 13.5px;
        width: 39px;
        height: 24px;
        cursor: pointer;
        border-left: 1px solid $gray-30;
        border-right: 1px solid $gray-30;
    }

    &__edit-button:hover{
        background: url('./../../images/configuration/edit-blue.svg') no-repeat 50% 50%;
        background-size: 29px 29px;
        padding: 7.6px 13.5px;
        width: 39px;
    }

    &__edit-button:active{
        background: url('./../../images/configuration/edit-blue.svg') no-repeat 50% 50%;
        background-size: 29px 29px;
        padding: 7.6px 13.5px;
        width: 39px;
    }

    &__delete-button{
        background: url('./../../images/configuration/delete-gray.svg') no-repeat 50% 50%;
        background-size: 15.2px 15px;
        padding: 7.6px 8px;
        width: 37px;
        cursor: pointer;
    }

    &__delete-button:hover{
        background: url('./../../images/configuration/delete-blue.svg') no-repeat 50% 50%;
        background-size: 15.2px 15px;
        padding: 7.6px 8px;
        width: 37px;
        cursor: pointer;
    }

    &__delete-button:active{
        background: url('./../../images/configuration/delete-blue.svg') no-repeat 50% 50%;
        background-size: 15.2px 15px;
        padding: 7.6px 8px;
        width: 37px;
        cursor: pointer;
    }

    &__points{
        background: url('./../../images/configuration/blue-points.svg') no-repeat 50% 50%;
        padding: 10px 3px;
        margin-left: 7px;
    }

    &__buttons{
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

.delete-modal{
    display: flex;
    flex-wrap: wrap;
    width: 600px;

    &__warning{
        color: $cards-color;
        background-color: $alert-color;
        padding: 8px 75px 8px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        width: 100%;
    }

    &__row{
        padding: 6px 111px 18px 111px;
    }
    &__info{
        margin-right: 24px;
    }

    .modal-content{
        border: none;
        box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.211765);
        border-radius: 10px;
        background-color: transparent;
        width: 610px;
        margin: auto;
    }

    &__header{
        height: 60px;
        color: white;
        background-color: $main-text-color;
        border-radius: 10px 10px 0px 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 15px;
        width: 100%;
    }

    &__close-button {
        background: url("./../../images/modal/white-x.svg") no-repeat;
        background-size: 28px 28px;
        padding: 14px;
    }

    &__body{
        border-radius: 0px 0px 10px 10px;
        background: $cards-color;
        padding: 24px 34px 38px 34px;
        width: 100%;
    }

    &__label{
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 19px;
        width: 100%;
        text-align: center;
        margin-bottom: 8px;
    }

    &__input{
        border: 1px solid $gray-90;
        opacity: 1;
        color: var(--main-text-color);
        border-radius: 25px;
        width: 100% ;
        min-height: 36px;
        margin-top: 0px;
        margin-bottom: 20px;
        padding-left: 20px ;
        background-color: transparent;
        font-size: 13px ;
    }

    &__input:focus{
        border: 1px solid $contrast-color;
        opacity: 1;
        color:$main-text-color  ;
        outline: none;
    }

    &__input:placeholder-shown{
        opacity: 0.4;
        color:$gray-90;
        margin-bottom:20px;
        background-color: transparent;
      }
  
    &__input-invalid{
        mix-blend-mode: normal;
        border: 1px solid $fail-color;
        opacity: 1;
        color:$main-text-color ;
        box-sizing: border-box;
        border-radius: 25px;
        width: 100%;
        min-height: 36px;
        margin-top: 0px;
        margin-bottom: 20px;
        padding-left: 20px;
        font-size: 13px;
        background-color: transparent;
    } 

      &__contrast-button{
        border-radius: 20px;
        background-color: $contrast-color;
        color: $cards-color;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        cursor: pointer;
        margin-right: 32px;
      }
  
      &__contrast-button:hover{
        box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
      }
  
      &__contrast-button:active{
        background-color: $click-color;
      }

      &__gray-button{
        border-radius: 20px;
        background-color: $gray-10;
        color: $gray-90;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        cursor: pointer;
      }

      &__buttons{
          display: flex;
          justify-content: center;
          width: 100%;
      }
  
      &__gray-button:hover{
        box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
      }
  
      &__gray-button:active{
        background-color: $gray-30;
      }
}

.link-item{
    display: flex;
    padding-bottom: 36px;
    border-bottom: 1px solid $gray-30;
    padding-top: 20px;

    &__left{
        width: 59%;
        display: flex;
        flex-wrap: wrap;
    }

    &__right{
        width: 41%;
        display: flex;
        align-items: flex-end;
    }

    &__right-cs{
        width: 41%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
    }

    &__coming-soon{
        border: 1px solid $alert-color;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        color: $alert-color;
        border-radius: 5px;
        width: 100px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    &__title{
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        width: 100%;
        margin-bottom: 36px;
    }

    &__text{
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
    }

    &__link{
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 19px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: $contrast-color;
        width: 100%;
    }
}

.link-item:first-child{
    padding-top: 0px;
}

.link-item:last-child{
    border-bottom: none;
}

.links-section{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}