.reports {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;

  &__card {
    width: 100%;
    background: $cards-color;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.160784);
    border-radius: 10px;
    margin-bottom: 13px;

    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 4px;
      display: inline;
    }
    &::-webkit-scrollbar-thumb {
      background: $gray-50;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background: $cards-color;
      border-radius: 4px;
    }
  }

  &__container {
    padding: 0px 13px 13px;
    &.plan_free::after {
      position: absolute;
      top: 0;
      content: attr(data-text);
      width: 1010px;
      height: 500px;
      display: flex;
      left: 0;
      justify-content: center;
      align-items: center;
      font-size: 6rem;
      opacity: 0.2;
      text-transform: uppercase;
      transform: translate(33%, 81%) rotate(-50deg);
      transform-origin: center;
      pointer-events: none;
      user-select: none;
    }
  }
}

.category-report {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 20px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
    display: inline;
  }
  &::-webkit-scrollbar-thumb {
    background: $gray-50;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: $cards-color;
    border-radius: 4px;
  }

  &__loader {
    position: absolute;
    height: 100%;
    width: 100%;
    background: $gray-10;
    opacity: 0.95;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
  }

  &__items-list {
    width: 100%;
    height: 50vh;
  }
}

.category-report-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 28px 12px;

  &__left {
    width: 65%;
    display: flex;
    justify-content: flex-start;
  }

  &__right {
    width: 35%;
    display: flex;
    justify-content: flex-end;
  }

  &__title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: $main-text-color;
    margin-right: 24px;
    height: 40px;
    display: flex;
    align-items: center;
  }

  &__lite-button {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    padding-left: 15px;
    color: $contrast-color;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    padding-left: 20px;
    cursor: pointer;
    font-family: $fontfamily;
  }

  &__lite-button:hover {
    text-decoration-line: underline;
    color: $click-color;
  }

  &__lite-button:active {
    color: $click-color;
  }
  &__restButton {
    background: url("./../../images/reports/restButtonIcon.svg") no-repeat 100%
      50%;
    color: $contrast-color;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    border-radius: 30px;
    cursor: pointer;
    height: 40px;
    width: 45px;
    font-family: $fontfamily;
  }
}
.category-filter {
  display: flex;
  margin-top: 10px;
}

.category-filter {
  &__hide {
    display: none;
  }
  &__container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  &__select {
    width: 300px;
    height: 40px;
    background: url("./../../images/header/store-select-icon.svg") no-repeat
      101% 50%;
    background-size: 40px 40px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    outline-width: 0;
    padding-left: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 30px;
    border: 1px solid $contrast-color;
    background-color: $cards-color;
    color: $main-text-color;
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    &-disabled {
      filter: grayscale(1) brightness(1.4);
    }
  }

  &__show {
    position: absolute;
    z-index: 8;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 63px;
    width: 300px;
    box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 8px 8px;
    animation-duration: 0.2s;
    animation-name: slidein;
    max-height: 500px;
    overflow-y: auto;
    background-color: $cards-color;
    height: 400px;
  }

  &__show::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
    display: inline;
  }

  &__show::-webkit-scrollbar-thumb {
    background: $gray-50;
    border-radius: 4px;
  }

  &__show::-webkit-scrollbar-track {
    background: $cards-color;
    border-radius: 4px;
  }

  &__fullwidth {
    width: 100%;
  }

  &__all-dept {
    display: flex;
    width: 100%;
    cursor: default;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    min-height: 50px;
  }

  &__all-dept:hover {
    background-color: $background-basic-color;
  }

  &__container-check {
    width: 28px;
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    margin-left: 30px;
  }

  &__checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
  }

  &__checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &__check {
    position: absolute;
    top: -4px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: $gray-10;
    border: 1px solid $gray-90;
    border-bottom: 1px solid $gray-90 !important;
    border-radius: 18px;
  }

  &__checkbox input:checked ~ &__check {
    background-color: $background-basic-color;
    border: 1px solid $contrast-color;
    border-bottom: 1px solid $contrast-color !important;
  }

  &__check:after {
    content: "";
    position: absolute;
    display: none;
  }

  &__checkbox input:checked ~ &__check:after {
    display: block;
  }

  &__checkbox &__check:after {
    left: 4px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid $contrast-color;
    border-width: 0px 1px 1px 0;
    transform: rotate(45deg);
  }

  &__report-category-item-name-all {
    padding-top: 15px;
  }

  &__report-category-item-for {
    display: flex;
    width: 100%;
    cursor: default;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    min-height: 50px;
    border-top: 1px solid $gray-30;
    display: block;
  }

  &__main-cat-header {
    width: 100%;
    display: flex;
    padding: 8px 0px;
  }

  &__main-cat-header:hover {
    background-color: $background-basic-color;
  }

  &__report-category-dept-name {
    padding-bottom: 7px;
    padding-top: 7px;
    width: 200px;
  }

  &__report-category-dept-name2 {
    padding-bottom: 7px;
    padding-top: 7px;
    width: 200px;
    cursor: pointer;
  }

  &__arrow-to-right {
    background: url("./../../images/reports/gray-right-arrow.svg") no-repeat;
    padding: 7px 7px;
    margin-top: 10px;
    cursor: pointer;
  }

  &__arrow-to-down {
    background: url("./../../images/reports/gray-down-arrow.svg") no-repeat;
    padding: 7px 7px;
    margin-top: 15px;
    cursor: pointer;
  }

  &__select-all-cat {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: $contrast-color;
    width: 100%;
    padding: 15px 0px 15px 50px;
    background-color: $gray-10;
    cursor: pointer;
  }

  &__select-all-cat:hover {
    background-color: $background-basic-color;
  }

  &__cat-header {
    width: 100%;
    display: flex;
    padding: 8px 0px 8px 20px;
    background-color: $gray-10;
  }

  &__cat-header:hover {
    background-color: $background-basic-color;
  }

  &__cat-header &__report-category-item-name {
    padding-bottom: 7px;
    padding-top: 7px;
    width: 182px;
  }

  &__report-category-item-name2 {
    cursor: pointer;
  }

  &__cat-header &__report-category-item-name2 {
    padding-bottom: 7px;
    padding-top: 7px;
    width: 182px;
  }

  &__select-all-sub-cat {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: $contrast-color;
    width: 100%;
    padding: 15px 0px 15px 70px;
    cursor: pointer;
  }

  &__select-all-sub-cat:hover {
    background-color: $background-basic-color;
  }

  &__sub-cat-header {
    width: 100%;
    display: flex;
    padding: 8px 0px 8px 40px;
  }

  &__sub-cat-header:hover {
    background-color: $background-basic-color;
  }

  &__sub-cat-header &__report-category-item-name {
    padding-bottom: 7px;
    padding-top: 7px;
    width: 175px;
  }
}

.category-searcher {
  position: relative;
  margin-right: 10px;

  &__report-searcher {
    margin-top: 9px;
    background-color: $gray-10;
    min-width: 300px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__input {
    border: none;
    background-color: $gray-10;
    padding: 7px 0 7px 5px;
    margin-left: 10px;
    font-weight: 500;
    width: 80%;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: $main-text-color;
  }

  &__input::placeholder {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: $gray-90;
  }

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__search-icon {
    width: 40px;
  }

  &__search-result {
    width: 100%;
    position: absolute;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $background-basic-color;
    padding: 5px 0 0 0;
    box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 8px 8px;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-name: slidein;
    animation-name: slidein;
    max-height: 400px;
    overflow-y: auto;
  }

  &__search-result::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
    display: inline;
  }
  &__search-result::-webkit-scrollbar-thumb {
    background: $gray-50;
    border-radius: 4px;
  }

  &__search-result::-webkit-scrollbar-track {
    background: $cards-color;
    border-radius: 4px;
  }

  &__search-result-body {
    width: 100%;
    padding: 0px 10px 5px 10px;
  }

  &__search-item-name {
    padding-top: 14px;
    border-bottom: 1px solid $gray-30;
    padding-left: 15px;
    min-height: 50px;
    display: flex;
    width: 100%;
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  &__search-item-name:last-child {
    border-bottom: none;
  }
}

.category-chart-link {
  display: flex;
  justify-content: flex-start;
  color: #1996fc;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  grid-gap: 10px;

  &__tooltip {
    display: flex;
    align-items: center;
    background: url("./../../images/tooltip/blue-help.svg") no-repeat 100% 50%;
    background-size: 30px 30px;
    padding: 15px 15px;
    cursor: pointer;
  }

  &__icon {
    display: flex;
    align-items: center;
    background: url("./../../images/reports/exelicon.svg") no-repeat 100% 50%;
    height: 41px;
    width: 50px;
    z-index: 1;
    cursor: pointer;
    &.is-inactive {
      filter: grayscale(1);
      opacity: 0.5;
    }
  }

  &__graph {
    background: url("./../../images/reports/graphReportIcon.svg") no-repeat 100%
      50%;
    color: $contrast-color;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    border-radius: 30px;
    cursor: pointer;
    height: 40px;
    width: 45px;
  }

  &__button:hover {
    border: 1px solid $click-color;
    color: $click-color;
  }

  &__button:active {
    border: 1px solid $click-color;
    color: $click-color;
    background-color: $gray-10;
  }
}

.category-report-table-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 18px 28px;

  &__item {
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-weight: bold;
    color: $gray-90;
    line-height: 15px;
    vertical-align: middle;
    font-size: 10px;
    padding: 0px;
    position: relative;
  }
  &__item:nth-child(1) {
    width: 18%;
  }
  &__item:nth-child(2) {
    width: 9%;
  }
  &__item:nth-child(3) {
    width: 9%;
  }
  &__item:nth-child(4) {
    width: 12%;
  }
  &__item:nth-child(5) {
    width: 9%;
  }
  &__item:nth-child(6) {
    width: 9%;
  }

  &__item:nth-child(7) {
    width: 11%;
  }
  &__item:nth-child(8) {
    width: 11%;
  }
  &__item:nth-child(9) {
    width: 12%;
  }
  &__item:nth-child(1) &__box {
    border-radius: 8px 0px 0px 8px;
    background-color: $gray-10;
    min-height: 65px;
    display: flex;
    align-items: center;
    padding: 8px;
    border: 1px solid $gray-30;
    height: 100%;
    width: 100%;
  }

  &__item:nth-child(9) &__box {
    border-radius: 0px 8px 8px 0px;
    background-color: $gray-10;
    min-height: 65px;
    display: flex;
    align-items: center;
    padding: 8px;
    border: 1px solid $gray-30;
    border-left: none;
  }

  &__box {
    background-color: $gray-10;
    min-height: 65px;
    display: flex;
    align-items: center;
    padding: 8px;
    border: 1px solid $gray-30;
    border-left: none;
    height: 100%;
    width: 100%;
  }

  &__box:hover &__down-sort-button {
    display: inline;
  }

  &__box:hover &__up-sort-button {
    display: inline;
  }

  &__down-sort-button:hover {
    background: url("./../../images/reports/blue-down-sort.svg") no-repeat;
  }

  &__up-sort-button:hover {
    background: url("./../../images/reports/blue-up-sort.svg") no-repeat;
  }

  &__up-sort-button {
    background: url("./../../images/reports/gray-up-sort.svg") no-repeat;
    right: 3px;
    position: absolute;
    top: 20px;
    width: 10px;
    height: 10px;
    display: none;
    cursor: pointer;
    z-index: 10;
  }

  &__down-sort-button {
    background: url("./../../images/reports/gray-down-sort.svg") no-repeat;
    right: 3px;
    position: absolute;
    top: 35px;
    width: 10px;
    height: 10px;
    display: none;
    cursor: pointer;
    z-index: 10;
  }
  &__text {
    width: 100%;
  }
}

.store-category-item {
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  padding: 0px 28px;

  &__body {
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    vertical-align: middle;
    position: relative;
    color: $main-text-color;
    padding: 8px 4px;
    font-size: 12px;
    height: 56px;
  }
  &__body:nth-child(1) {
    width: 18%;
    padding: 0px;
  }
  &__body:nth-child(2) {
    width: 9%;
  }
  &__body:nth-child(3) {
    width: 9%;
  }
  &__body:nth-child(4) {
    width: 12%;
  }
  &__body:nth-child(5) {
    width: 9%;
  }
  &__body:nth-child(6) {
    width: 9%;
  }
  &__body:nth-child(7) {
    width: 11%;
  }
  &__body:nth-child(8) {
    width: 11%;
  }
  &__body:nth-child(9) {
    width: 12%;
  }

  &__button {
    display: flex;
  }

  &__cont {
    display: block;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: 27px;
    width: auto;
  }

  &__checkmarkCheckbox {
    display: block;
    top: 60px;
    left: 44px;
    z-index: 200;
  }

  &__cont input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__checkmark {
    position: absolute;
    top: 18px;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: transparent;
    border: 1.5px solid $gray-90;
    border-radius: 4px;
  }

  &__cont input:checked ~ &__checkmark {
    border: 1.5px solid $contrast-color;
    background-color: transparent;
    border-radius: 4px;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  &__checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  &__cont input:checked ~ &__checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  &__cont &__checkmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid $contrast-color;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &__edit-icon {
    background: url("./../../images/reports/blue-edit-icon.svg") no-repeat 50%
      50%;
    padding: 16px;
    margin-top: 10px;
    float: left;
    cursor: pointer;
  }

  &__box {
    background-color: $gray-10;
    min-height: 56px;
    display: flex;
    align-items: center;
    padding: 8px;
    border-right: 1px solid $gray-30;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: center;
  }

  &__text {
    width: 100%;
  }

  &__body:nth-child(9) &__box {
    background-color: $gray-10;
    min-height: 56px;
    display: flex;
    align-items: center;
    padding: 8px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    border-radius: 0px 8px 8px 0px;
    border-right: none;
  }

  &__body:nth-child(2) &__box {
    background-color: $gray-10;
    min-height: 56px;
    display: flex;
    align-items: center;
    padding: 8px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    border-radius: 8px 0px 0px 8px;
    border-right: 1px solid $gray-30;
  }
  &__link-space {
    display: flex;

    background-color: $gray-10;
    width: 100%;
    height: 100%;
    padding: 16px 8px;
    border-radius: 8px;
    height: 56px;
    border: 1px solid $gray-10;
    cursor: pointer;
  }

  &__name-space {
    background-color: $gray-10;
    width: 100%;
    height: 100%;
    padding: 16px 8px;
    border-radius: 8px;
    height: 56px;
    border: 1px solid $gray-10;
    cursor: pointer;
  }
}

.store-category-item__text,
.category-report-table-header__text {
  font-size: 14px;
}

.store-category-item__body:nth-child(4) {
  .store-category-item__text {
    display: flex;
    justify-content: flex-end;
  }
}

.store-category-item:hover .store-category-item__box {
  background-color: $background-basic-color;
}

.store-category-item:hover .store-category-item__name-space {
  background-color: $background-basic-color;
}

.store-category-item:hover .store-category-item__link-space {
  background-color: $background-basic-color;
  border: 1px solid $contrast-color;
}

.store-category-item:hover .store-category-item__link-space:hover {
  border: 1px solid $contrast-color;
  background-color: $contrast-color;
  color: $cards-color;
}

.category-chart {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;

  &__loader {
    position: absolute;
    height: 100%;
    width: 100%;
    background: $gray-10;
    opacity: 0.95;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
  }

  &__categories-view {
    color: $contrast-color;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    padding-left: 40px;
    padding-top: 10px;
  }
}

.category-chart-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 27px 28px 12px;

  &__left {
    width: 52%;
    display: flex;
    justify-content: flex-start;
  }

  &__right {
    width: 48%;
    display: flex;
    justify-content: flex-end;
    grid-gap: 10px;
  }

  &__title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: $main-text-color;
    margin-right: 15px;
    height: 40px;
    display: flex;
    align-items: center;
  }

  &__button {
    background: url("../../images/reports/header-gray-arrow.svg") no-repeat 93%
      50%;
    color: $main-text-color;
    border: 1px solid $gray-30;
    box-sizing: border-box;
    border-radius: 20px;
    width: 200px;
    height: 40px;
    margin-right: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    cursor: pointer;
  }

  &__button:hover {
    box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
  }

  &__button:disabled {
    background: url("../../images/reports/header-blue-arrow.svg") no-repeat 93%
      50%;
    box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
    border: 1px solid $contrast-color;
    background-color: transparent;
    color: $contrast-color;
    pointer-events: none;
  }

  &__select-off {
    background-color: transparent;
    display: inline-block;
    min-width: 254px;
    margin-left: 70px;
    border-radius: 20px;
    border: 1px solid $gray-30;
    padding-left: 15px;
    color: $gray-90;
    font-size: 14px;
    padding: 8px 7px 7px 25px;
    font-weight: 500;
    background: url("./../../images/reports/gray-arrow-date.svg") no-repeat
      99.9% 50%;
  }

  &__select-on {
    background-color: transparent;
    display: inline-block;
    min-width: 254px;
    margin-left: 70px;
    border-radius: 20px;
    border: 1px solid $contrast-color;
    padding-left: 15px;
    color: $main-text-color;
    font-size: 14px;
    padding: 8px 7px 7px 25px;
    font-weight: 500;
    background: url("./../../images/reports/blue-arrow-date.svg") no-repeat
      99.9% 50%;
    position: relative;
  }

  &__hide {
    display: none;
  }

  &__show {
    position: absolute;
    z-index: 8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    top: 42px;
    width: 275px;
    box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    animation-duration: 0.2s;
    animation-name: slidein;
    max-height: 500px;
    overflow-y: auto;
    background-color: $background-basic-color;
    height: 188px;
    left: -10px;
    padding: 16px;
  }

  &__range-div {
    height: 28px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    width: 50%;
    border-radius: 5px;
    padding: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__range-div:nth-child(2n) {
    justify-content: flex-end;
  }

  &__range-div:hover {
    font-weight: 600;
    background: rgba(25, 150, 252, 0.2);
  }

  .DayPickerKeyboardShortcuts_show__bottomRight {
    display: none;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: $click-color;
    border: 1px double $click-color;
    color: $cards-color;
  }

  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: $click-color;
    border: 1px double $click-color;
    color: $cards-color;
  }

  .DateRangePickerInput:focus-within {
    border: 1px solid $contrast-color !important;
  }

  .CalendarDay__selected_span {
    background: $contrast-color;
    border: 1px double $click-color;
    color: $cards-color;
  }

  .CalendarDay__default:hover {
    background: $middle-smartman-color;
    border: 1px double $click-color;
  }

  .DateRangePicker_picker {
    z-index: 12;
  }

  .DateInput_input {
    font-weight: 500;
    font-weight: 500;
    line-height: 21px;
    color: $gray-90;
    background-color: transparent;
    font-size: 14px;
    text-align: center;
    padding: 8px 7px 7px;
  }

  .DateInput {
    width: 110px;
    text-align: center;
  }

  .DateInput_input__focused {
    border-bottom: 2px solid $contrast-color;
  }

  .DateRangePickerInput {
    background-color: transparent;
    display: inline-block;
    min-width: 300px;
    margin-left: 15px;
    border-radius: 20px;
    border: 1px solid $gray-30;
    padding-left: 15px;
  }

  .DateRangePicker {
    background: url("./../../images/reports/gray-arrow-date.svg") no-repeat
      99.9% 50%;
  }

  .DateRangePicker:focus-within {
    background: url("./../../images/reports/blue-arrow-date.svg") no-repeat
      99.9% 50% !important;
  }

  .DateRangePickerInput_arrow_svg {
    vertical-align: middle;
    fill: transparent;
    height: 24px;
    width: 24px;
  }

  .DateRangePickerInput_arrow {
    background: url("./../../images/reports/divide-date-icon.svg") no-repeat 50%
      50% !important;
  }

  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: $middle-smartman-color;
    border: 1px double $click-color;
    color: $cards-color;
  }
}

.chart-category-tags {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0px 28px;
  position: relative;

  &__button {
    width: 190px;
    height: 30px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    background-color: $gray-10 !important ;
    color: $main-text-color;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    padding: 4px 2px 3px 20px;
    background: url("./../../images/reports/blue-simple-direction-icon.svg")
      no-repeat 92% 51%;
    background-size: 9px 20px;
    float: left;
    position: relative;
    cursor: pointer;
    margin-top: 6px;
  }
  &__hide {
    display: none;
  }

  &__show {
    position: absolute;
    z-index: 8;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 40px;
    width: 300px;
    box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 8px 8px;
    animation-duration: 0.2s;
    animation-name: slidein;
    max-height: 400px;
    overflow-y: auto;
    background-color: $background-basic-color;
  }

  &__show::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
    display: inline;
  }

  &__show::-webkit-scrollbar-thumb {
    background: $gray-50;
    border-radius: 4px;
  }

  &__show::-webkit-scrollbar-track {
    background: $cards-color;
    border-radius: 4px;
  }

  &__options-table-list {
    display: flex;
    width: 100%;
    padding: 8px 0px;
    cursor: default;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  &__options-table-list:hover {
    background-color: $cards-color;
  }

  &__container-check {
    width: 28px;
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    margin-left: 30px;
  }

  &__checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
  }

  &__checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &__check {
    position: absolute;
    top: -4px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: $gray-10;
    border: 1px solid $gray-90;
    border-bottom: 1px solid $gray-90 !important;
    border-radius: 18px;
  }

  &__checkbox input:checked ~ &__check {
    background-color: $background-basic-color;
    border: 1px solid $contrast-color;
    border-bottom: 1px solid $contrast-color !important;
  }

  &__check:after {
    content: "";
    position: absolute;
    display: none;
  }

  &__checkbox input:checked ~ &__check:after {
    display: block;
  }

  &__checkbox &__check:after {
    left: 4px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid $contrast-color;
    border-width: 0px 1px 1px 0;
    transform: rotate(45deg);
  }

  &__last-button {
    border: 1px solid $contrast-color;
    cursor: pointer;
    padding: 10px 25px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: $contrast-color;
    margin-bottom: 20px;
  }

  &__last-button:hover {
    color: $click-color;
    border: 1px solid $click-color;
  }

  &__last-button:active {
    color: $click-color;
    border: 1px solid $click-color;
    background-color: $gray-10;
  }

  &__category-div {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    border: 1px solid $gray-30;
    background-color: $cards-color;
    float: left;
    height: 30px;
    margin-left: 12px;
    padding: 4px 10px;
    font-size: 13px;
    cursor: auto;
    margin-top: 6px;
  }
  &__x-button {
    margin-left: 20px;
    background: url("./../../images/reports/x-gray-icon.svg") no-repeat 100% 50%;
    padding-left: 18px;
    background-size: 16px 16px;
  }
  .circle-color {
    width: 12px;
    height: 12px;
    float: left;
    margin-top: 3px;
    margin-right: 6px;
    border-radius: 50px;
  }

  .color1 {
    background-color: #7188a5;
  }

  .color2 {
    background-color: #f64764;
  }

  .color3 {
    background-color: #89e40a;
  }

  .color4 {
    background-color: #725bed;
  }

  .color5 {
    background-color: #62d7e1;
  }
}

.six-points {
  background: url("./../../images/reports/six-points.svg") no-repeat;
  padding: 0px 8px;
  margin-top: 19px;
  cursor: grabbing;
}

.chart-cont {
  padding-bottom: 240px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.recharts-surface {
  font-size: 11px !important;
}

.main-report-header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // position: sticky;
  z-index: 11;
  background-color: $background-basic-color;
  padding: 13px;
  top: 87px;

  &__card {
    background: $cards-color;
    border-radius: 8px;
    height: 73px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-left: 25px;
  }
  &__inactive-button {
    display: grid;
    grid-template-columns: max-content 30px;
    align-items: center;
    width: max-content;
    height: 45px;
    text-align: left;
    padding-left: 19px;
    border: 1px solid $gray-30;
    border-radius: 8px;
    margin-right: 45px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    cursor: pointer;
    &::after {
      content: "";
      background: url("../../images/reports/header-gray-arrow.svg") no-repeat
        85% 50%;
      width: 100%;
      height: 100%;
    }
  }

  &__inactive-button:hover {
    color: $contrast-color;
    border: 1px solid $gray-30;
    box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
    &::after {
      background: url("../../images/reports/header-blue-arrow.svg") no-repeat
        85% 50%;
    }
  }

  &__active-button {
    display: grid;
    grid-template-columns: max-content 30px;
    align-items: center;
    width: max-content;
    height: 45px;
    color: $contrast-color;
    text-align: left;
    padding-left: 19px;
    border: 1px solid $contrast-color;
    border-left-width: 5px;
    border-radius: 8px;
    margin-right: 45px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    cursor: pointer;
    box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
    &::after {
      content: "";
      background: url("../../images/reports/header-gray-arrow.svg") no-repeat
        85% 50%;
      width: 100%;
      height: 100%;
    }
  }

  &__comingsoon-button {
    width: 144px;
    height: 45px;
    left: 637px;
    top: 119px;
    background: url("../../images/reports/header-gray-arrow.svg") no-repeat 85%
      50%;
    text-align: left;
    padding-left: 19px;
    border: 1px solid $gray-30;
    border-radius: 8px;
    margin-right: 25px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    &_tooltip {
      display: flex;
      justify-content: space-around;
      opacity: 1;
      align-items: center;
      background: transparent;
    }
  }

  &__coming-soon,
  &__beta {
    border: 1px solid $alert-color;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: $alert-color;
    border-radius: 5px;
    width: 100px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__beta {
    width: 60px;
  }
}

.ecommerce-report {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;

  &__card {
    width: 100%;
    background: $cards-color;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.160784);
    border-radius: 10px;
    margin-bottom: 13px;
  }
}

.ecommerce-report-header {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  padding: 27px 28px 12px;

  &__first-line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 17px;
    align-items: center;
    margin-top: 6px;
    margin-bottom: 23px;
  }

  &__left {
    width: 50%;
    display: flex;
    justify-content: flex-start;
  }

  &__right {
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }

  &__fl-right {
    display: flex;
    justify-content: flex-end;
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    padding-left: 36px;
  }

  &__boxes_space {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__box {
    width: 49%;
    height: 144px;
    justify-content: space-between;
    display: flex;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 14px 16px;
  }

  &__box-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 36%;
    align-items: center;
    background: $gray-10;
    border: 1px solid $gray-30;
    border-radius: 5px;
    height: 100%;
  }

  &__box-main2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 36%;
    align-items: center;
    background: $gray-10;
    border: 1px solid $gray-30;
    border-radius: 5px;
    height: 100%;
  }

  &__main-text {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  &__main-number {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    color: $contrast-color;
  }

  &__second-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 61%;
    height: 100%;
  }

  &__third-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 61%;
    height: 100%;
  }

  &__third-box &__box-line:first-child {
    margin-bottom: 12px;
  }

  &__box-line {
    width: 100%;
    height: 30px;
    border: 1px solid $gray-30;
    display: flex;
    align-items: center;
    border-radius: 5px;
  }

  &__coming-soon {
    border: 1px solid $alert-color;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: $alert-color;
    border-radius: 5px;
    width: 100px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__box-line2 {
    width: 100%;
    height: 30px;
    border: 1px solid $gray-30;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 12px;
  }

  &__text1 {
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    text-transform: capitalize;
    color: $gray-90;
  }

  &__text2 {
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: $gray-90;
  }

  &__text {
    width: 46%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: $gray-10;
    border-right: 1px solid $gray-30;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
  }

  &__number {
    width: 54%;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    padding-right: 21px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__total-range {
    background: $gray-10;
    box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.2);
    border-radius: 8px;
    width: 340px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    padding: 0px 10px;
  }

  &__total-range &__number {
    margin-left: 9px;
  }

  &__button {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    border-radius: 30px;
    cursor: pointer;
    height: 40px;
    width: 200px;
    margin-left: 19px;
    background: url("../../images/reports/header-gray-arrow.svg") no-repeat 93%
      50%;
    color: $main-text-color;
    border: 1px solid $gray-30;
  }

  &__button:hover {
    box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
  }

  &__button:disabled {
    background: url("../../images/reports/header-blue-arrow.svg") no-repeat 93%
      50%;
    box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
    border: 1px solid $contrast-color;
    background-color: transparent;
    color: $contrast-color;
    pointer-events: none;
  }

  &__controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }

  &__select-off {
    background-color: transparent;
    display: inline-block;
    min-width: 254px;
    margin-left: 70px;
    border-radius: 20px;
    border: 1px solid $gray-30;
    padding-left: 15px;
    color: $gray-90;
    font-size: 14px;
    padding: 8px 7px 7px 25px;
    font-weight: 500;
    background: url("./../../images/reports/gray-arrow-date.svg") no-repeat
      99.9% 50%;
  }

  &__select-on {
    background-color: transparent;
    display: inline-block;
    min-width: 254px;
    margin-left: 70px;
    border-radius: 20px;
    border: 1px solid $contrast-color;
    padding-left: 15px;
    color: $main-text-color;
    font-size: 14px;
    padding: 8px 7px 7px 25px;
    font-weight: 500;
    background: url("./../../images/reports/blue-arrow-date.svg") no-repeat
      99.9% 50%;
    position: relative;
  }

  &__hide {
    display: none;
  }

  &__show {
    position: absolute;
    z-index: 8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    top: 42px;
    width: 275px;
    box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    animation-duration: 0.2s;
    animation-name: slidein;
    max-height: 500px;
    overflow-y: auto;
    background-color: $background-basic-color;
    height: 188px;
    left: -10px;
    padding: 16px;
  }

  &__show2 {
    position: absolute;
    z-index: 8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    top: 30px;
    width: 100%;
    box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 8px 8px;
    animation-duration: 0.2s;
    animation-name: slidein;
    max-height: 500px;
    overflow-y: auto;
    background-color: $cards-color;
    padding: 8px;
  }

  &__month-item {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    height: 25px;
    display: flex;
    align-items: center;
    padding-left: 4px;
  }

  &__month-item:hover {
    background: rgba(25, 150, 252, 0.2);
    border-radius: 5px;
  }
  &__range-div {
    height: 28px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    width: 50%;
    border-radius: 5px;
    padding: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__range-div:nth-child(2n) {
    justify-content: flex-end;
  }

  &__range-div:hover {
    font-weight: 600;
    background: rgba(25, 150, 252, 0.2);
  }

  &__month-select {
    position: relative;
    width: 70%;
    height: 35px;
    background: $cards-color;
    border: 1px solid $gray-30;
    box-sizing: border-box;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    display: flex;
    min-width: 121px;
    margin: 5px 0px;
  }

  &__month-label {
    height: 100%;
    width: 73%;
    border-right: 1px solid $gray-30;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    padding-left: 10px;
  }

  &__month-icon {
    height: 100%;
    width: 27%;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .DayPickerKeyboardShortcuts_show__bottomRight {
    display: none;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: $click-color;
    border: 1px double $click-color;
    color: $cards-color;
  }

  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: $click-color;
    border: 1px double $click-color;
    color: $cards-color;
  }

  .CalendarDay__selected_span {
    background: $contrast-color;
    border: 1px double $click-color;
    color: $cards-color;
  }

  .CalendarDay__default:hover {
    background: $middle-smartman-color;
    border: 1px double $click-color;
  }

  .DateRangePicker_picker {
    z-index: 12;
  }

  .DateInput_input {
    font-weight: 500;
    font-weight: 500;
    line-height: 21px;
    color: $gray-90;
    background-color: transparent;
    font-size: 14px;
    text-align: center;
    padding: 8px 7px 7px;
  }

  .DateInput {
    width: 110px;
    text-align: center;
  }

  .DateInput_input__focused {
    border-bottom: 2px solid $contrast-color;
  }

  .DateRangePickerInput {
    background-color: transparent;
    display: inline-block;
    min-width: 300px;
    margin-left: 15px;
    border-radius: 20px;
    border: 1px solid $gray-30;
    padding-left: 15px;
  }

  .DateRangePickerInput:focus-within {
    border: 1px solid $contrast-color !important;
  }

  .DateRangePicker {
    background: url("./../../images/reports/gray-arrow-date.svg") no-repeat
      99.9% 50%;
  }

  .DateRangePicker:focus-within {
    background: url("./../../images/reports/blue-arrow-date.svg") no-repeat
      99.9% 50% !important;
  }

  .DateRangePickerInput_arrow_svg {
    vertical-align: middle;
    fill: transparent;
    height: 24px;
    width: 24px;
  }

  .DateRangePickerInput_arrow {
    background: url("./../../images/reports/divide-date-icon.svg") no-repeat 50%
      50% !important;
  }

  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: $middle-smartman-color;
    border: 1px double $click-color;
    color: $cards-color;
  }
}

.real-time-report {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  position: sticky;

  .leaflet-control-attribution {
    display: none;
  }

  .leaflet-container {
    border-radius: 10px;
    max-height: 611px;
  }

  .leaflet-tooltip {
    background: $main-text-color;
    color: $cards-color;
    border: 1px solid $gray-30;
    box-sizing: border-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }

  .leaflet-tooltip-left:before {
    right: 0;
    margin-right: -11px;
    border-left-color: $main-text-color;
  }

  .leaflet-tooltip-right:before {
    border-right-color: $main-text-color;
    margin-left: -11px;
  }

  &__full-card {
    width: 100%;
    background: $cards-color;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.160784);
    border-radius: 10px;
    margin-bottom: 13px;
  }

  &__mid-card {
    width: 49%;
    background: $cards-color;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.160784);
    border-radius: 10px;
    margin-bottom: 13px;
  }
}

.real-time-product-list {
  width: 100%;
  display: flex;
  padding: 21px 13px 21px 22px;
  flex-direction: column;

  &__list {
    height: 426px;
    overflow-y: scroll;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-right: 13px;
    position: relative;
  }

  &__list-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    padding-bottom: 5px;
    background-color: $cards-color;
  }

  &__list-header-box {
    background-color: $gray-10;
    color: $contrast-color;
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 21px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }

  &__list-header-box:first-child {
    width: 70%;
    border-right: 1px solid $gray-30;
    border-radius: 5px 0px 0px 5px;
  }

  &__list-header-box:nth-child(2) {
    width: 30%;
    border-radius: 0px 5px 5px 0px;
  }

  &__list-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }

  &__list-item:first-child {
    margin-top: 0px;
  }
  &__list-item-box {
    background-color: $gray-10;
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 21px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }

  &__list-item-box:first-child {
    width: 70%;
    border-right: 1px solid $gray-30;
    border-radius: 5px 0px 0px 5px;
  }

  &__list-item-box:nth-child(2) {
    width: 30%;
    border-radius: 0px 5px 5px 0px;
  }

  &__list::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
    display: inline;
  }

  &__list::-webkit-scrollbar-thumb {
    background: $gray-90;
    border-radius: 10px;
  }

  &__list::-webkit-scrollbar-track {
    background: $gray-10;
    border-radius: 10px;
  }

  &__total {
    width: 100%;
    display: flex;
    padding-right: 16px;
    justify-content: space-between;
    margin-top: 12px;
  }

  &__label {
    width: 89%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: $background-basic-color;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    border-radius: 8px;
  }

  &__number {
    width: 9%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: $background-basic-color;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    border-radius: 8px;
  }

  &__list-items {
    max-height: 396px;
  }
}

.real-time-chart {
  width: 100%;
  display: flex;
  padding: 20px;
  flex-direction: column;

  &__title {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    padding-left: 36px;
    padding: 7px 43px;
    width: 100%;
    .c-tooltip {
      width: max-content;
      padding: 0 0.5rem;
    }
  }

  &__totals {
    margin-top: 11px;
    width: 650px;
    height: 122px;
    background: $gray-10;
    box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.2);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
  }

  &__box {
    height: 100%;
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid $gray-30;
  }

  &__box:last-child {
    border-right: none;
  }

  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;

    max-width: 300px;
    text-align: center;
  }

  &__number {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
  }

  &__circle {
    width: 12px;
    height: 12px;
    float: left;
    margin-top: 3px;
    margin-right: 8px;
    border-radius: 50px;
    background-color: #62d7e1;
  }

  &__label-div {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: $gray-90;
    margin-top: 40px;
    margin-left: 58px;
  }

  &__chart-cont {
    padding-bottom: 240px;
    margin-bottom: 30px;
  }
}

.chart-tutorial {
  min-height: 476px;
  display: flex;
  align-items: center;
  justify-content: center;
  &__init {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__init-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 32px;
    display: flex;
    align-items: center;
    width: 270px;
    text-align: center;
    margin-bottom: 18px;
  }
  &__button {
    border-radius: 20px;
    background-color: $contrast-color;
    color: $cards-color;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    cursor: pointer;
  }

  &__button:hover {
    box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
  }

  &__button:active {
    background-color: $click-color;
  }
}

.category-chart-example {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &__img-div {
    padding: 45px 35px;
  }

  &__image {
    width: 100%;
  }

  &__msg {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(248, 248, 248, 0.9);
    opacity: 0.95;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    gap: 10rem;
    align-items: center;
    z-index: 9;
  }

  &__video_div {
    width: 536px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 16px 13px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__img-text {
    width: 640px;
  }
  &__video_text {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    margin-top: 16px;
  }

  &__video_span {
    color: $contrast-color;
  }
}

.checkAll {
  display: block;
  top: 60px;
  left: 44px;
  z-index: 1;
}

//  Changes order screen

.order-change {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  .selectOrder & {
    width: 25rem;
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    padding-left: 36px;
    padding: 7px 43px;
    width: 100%;
    text-align: center;

    color: $contrast-color;

    display: flex;
    justify-content: center;
    grid-gap: 9px;
    align-items: center;
    .selectOrder & {
      font-size: 1.5625rem;
      font-style: normal;
      font-weight: 500;
    }
  }
  &__video {
    height: 196px;
    width: 351px;
    .selectOrder & {
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  &__videoIframe {
    position: relative;
    height: 196px;
    width: 350px;
  }
  &__descript {
    font-style: normal;
    font-weight: lighter;
    font-size: 13px;
    line-height: 19px;
    max-width: 380px;
    min-height: 5rem;
    text-align: center;
    margin: 15px 10px;
    .selectOrder & {
      min-height: 2rem;
    }
  }
  &__button {
    border-radius: 20px;
    background-color: #1996fc;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 32px;
    cursor: pointer;
    &[disabled] {
      background-color: $gray-50;
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.MuiFab-primary {
  background-color: #1996fc !important;
}

.typeOfOrder {
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 20px;
  margin: 15px auto;
  padding: 0.5rem;
  background: #ffffff;

  &__cards {
    display: grid;
    grid-template-rows: 4rem 1fr;

    &__main {
      grid-area: 2/1 / span 1 / span 1;
      display: flex;
      gap: 1rem;
      justify-content: space-evenly;
      padding: 0 0 1.5rem 0;
      .selectOrder & {
        margin-top: 2rem;
      }
    }

    &__title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      margin: 1rem auto;
      line-height: 32px;

      text-align: center;

      color: #001130;
    }
    &__o {
      width: 76px;
      height: 19px;
      left: 653px;
      top: 365px;

      font-family: Poppins;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 96px;
      line-height: 19px;

      color: #c4c4c4;
    }
  }
}

.category-chart-text {
  max-width: 366px;
  &_title {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    margin: 0.5rem;
  }
  &_desc {
    text-align: center;
    max-width: 80%;
    margin: auto;
  }
  &_resalt {
    margin: 0 0.3125rem;
    color: $contrast-color;
  }
}
