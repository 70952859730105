.mainheader {
  width: 100%;
  height: 87px;
  background-color: $cards-color;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.2);
  padding: 20px 50px 20px 15px;
  position: sticky;
  z-index: 999;
  top: 0;
  transition: margin 0.5s ease;
  &[data-toolbar="true"] {
    margin-bottom: 4rem;
  }
  &__plan {
    width: 100px;
    display: flex;
    gap: 0.5rem;
    color: $contrast-color;
    text-transform: capitalize;
  }
  &__transparent-Label {
    width: 2.1875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .MuiInputBase-root {
    padding: 0 0.5rem;
    border-left: 1px solid #e2e2e2;
    border-right: 1px solid #e2e2e2;
  }
  .MuiSelect-select.MuiSelect-select {
    padding-right: 1.1875rem;
  }

  .MuiInput-underline::before,
  .MuiInput-underline::after {
    display: none;
  }

  .MuiSelect-icon {
    color: $contrast-color;
    right: 5px;
  }

  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background-color: inherit;
  }

  &__left-side {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
  }

  &__right-side {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 10px;
  }

  .MuiSelect-select:focus {
    border-radius: 0;
    background-color: #fff;
  }

  &__smartman-logo {
    min-width: 208px;
    min-height: 40px;
    background: url("./../../images/header/smartman-logo.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;
  }

  &__user-avatar {
    padding: 24.5px;
    background-repeat: no-repeat;
    background-size: 46px 46px;
    background-position: center;
    margin-right: 5px;
  }
  .MuiFab-primary {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
    .MuiFab-label {
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: flex-end;
      border-radius: 50%;
      box-shadow: 0 0 0px 1px $high-smartman-color;
      overflow: hidden;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }

  &__contrast-button {
    border-radius: 20px;
    background-color: $contrast-color;
    color: $cards-color;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 32px;
    margin-left: 16px;
    cursor: pointer;
  }

  &__contrast-button {
    border-radius: 20px;
    background-color: $contrast-color;
    color: $cards-color;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 32px;
    margin-left: 16px;
    cursor: pointer;
  }

  &__contrast-button:hover {
    box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
  }

  &__contrast-button:active {
    background-color: $click-color;
  }

  &__transparent-button {
    border-radius: 27px;
    border: 1px solid $contrast-color;
    background-color: transparent;
    color: $contrast-color;
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 43px;
    margin-right: 5px;
    cursor: pointer;
    &__bg {
      background-color: #f5f6fa;
    }
  }

  &__transparent-button:hover {
    border: 1px solid $click-color;
    color: $click-color;
  }

  &__vertical-points-button {
    display: flex;
    align-items: center;
    margin-top: 12px;
    background: url("./../../images/header/vertical-points.svg") no-repeat;
    background-size: 6px 22px;
    cursor: pointer;
  }

  &__transparent-button:active {
    border: 1px solid $click-color;
    color: $click-color;
    background-color: $gray-10;
  }

  &__quickdemo-button {
    display: flex;
    align-items: center;
    background: url("./../../images/header/quickdemo-icon.svg") no-repeat 100%
      50%;
    background-size: 33px 33px;
    padding: 16.5px 16.5px;
    cursor: pointer;
    margin-right: 5px;
  }

  &__iconoReportes-button {
    display: flex;
    align-items: center;
    background: url("./../../images/header/iconoReportes.svg") no-repeat 100%
      50%;
    height: 40px;
    padding: 16.5px 16.5px;
    cursor: pointer;
  }

  &__iconoReportes-button::after {
    content: "";
    width: 85%;
    background: #e2e2e2;
    right: 20px;
    height: 1px;
    position: absolute;
    bottom: 0px;
  }

  &__orderProducts-button {
    display: flex;
    align-items: center;
    background: url("./../../images/header/icon_categories.svg") no-repeat 100%
      50%;
    width: 34px;
    height: 40px;
    padding: 16.5px 16.5px;
    cursor: pointer;
  }
  &__orderProducts-button::after {
    content: "";
    width: 85%;
    background: #e2e2e2;
    right: 20px;
    height: 1px;
    position: absolute;
    bottom: 0px;
  }
  &__orderCollection-button {
    display: flex;
    align-items: center;
    background: url("./../../images/header/icon_collection.svg") no-repeat 100%
      50%;
    width: 34px;
    height: 40px;
    padding: 16.5px 16.5px;
    cursor: pointer;
  }
  &__orderCollection-button::after {
    content: "";
    width: 85%;
    background: #e2e2e2;
    right: 20px;
    height: 1px;
    position: absolute;
    bottom: 0px;
  }
  &__bellIcon-button {
    display: flex;
    align-items: center;
    background: url("./../../images/header/bellIcon.svg") no-repeat 100% 50%;
    height: 40px;
    padding: 16.5px 16.5px;
    cursor: pointer;
  }

  &__vertical-points-button {
    display: flex;
    align-items: center;
    margin-top: 12px;
    background: url("./../../images/header/vertical-points.svg") no-repeat;
    cursor: pointer;
  }

  &__user-actions {
    display: flex;

    a {
      text-decoration: none;
      color: $main-text-color;
    }
  }
}
#SpeedDialexample-actions.MuiSpeedDial-actionsClosed {
  display: none;
}
.MuiFab-sizeSmall {
  width: 50px;
  height: 50px;
}
.MuiTooltip-tooltipArrow {
  background-color: #1996fc;
}

.store-selector {
  position: relative;
  min-width: 267px;
  height: 4.5625rem;

  &__p {
    font-weight: 300;
    font-size: 12px;
    color: #707070;
    margin-left: 0.5rem;
  }
  &__store-select {
    position: absolute;
    width: auto;
    min-width: 250px;
    max-width: 250px;
    appearance: none;
    outline-width: 0;
    padding-left: 26px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #ffffff;
    color: #707070;
    font-size: 18px;
    font-weight: 900;
    line-height: 19px;
    color: $contrast-color;

    &::before {
      content: "";
      width: 2px;
      height: 244%;
      background: #e2e2e2;
      left: 0;
      position: absolute;
      top: -33px;
    }
    &::after {
      content: "";
      width: 2px;
      height: 244%;
      background: #e2e2e2;
      right: -20px;
      position: absolute;
      top: -33px;
    }
  }

  &__options-hide {
    display: none;
  }

  &__options-show {
    position: absolute;
    z-index: 12;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: -34px;
    top: 2.125rem;
    width: auto;
    min-width: 330px;
    max-height: 216px;
    background-color: #ffffff;
    box-shadow: 0px 4px 4px #707070;
    border-radius: 0px 0px 10px 10px;
    animation-duration: 0.4s;
    animation-name: slidein;
    overflow-y: auto;
    padding: 10px 10px;

    &::-webkit-scrollbar {
      width: 3px;
      border-radius: 10px;
      display: inline;
    }

    &::-webkit-scrollbar-thumb {
      background: $gray-90;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background: $gray-10;
      border-radius: 10px;
    }
  }
  &__item {
    display: flex;
    width: 100%;
    padding: 10px 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #1996fc;
    font-family: Poppins;
    font-style: normal;
    border-bottom: 1px solid #e2e2e2;
  }
  &__item:hover {
    background-color: $cards-color;
  }

  &__container-check {
    width: 10%;
    display: flex;
    align-items: center;
    margin-left: 30px;
    top: -3px;
    position: relative;
  }

  &__checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
  }

  &__checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &__check {
    position: absolute;
    top: -4px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: $gray-10;
    border: 1px solid $gray-90;
    border-bottom: 1px solid $gray-90;
    border-radius: 18px;
  }

  &__checkbox input:checked ~ &__check {
    background-color: $background-basic-color;
    border: 1px solid $contrast-color;
    border-bottom: 1px solid $contrast-color;
  }

  &__check:after {
    content: "";
    position: absolute;
    display: none;
  }

  &__checkbox input:checked ~ &__check:after {
    display: block;
  }

  &__checkbox &__check:after {
    left: 4px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid $contrast-color;
    border-width: 0px 1px 1px 0;
    transform: rotate(45deg);
  }
}

.user-options-show {
  position: absolute;
  z-index: 12;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: 50px;
  top: 67px;
  width: 272px;
  background-color: $background-basic-color;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 8px 8px;
  animation-duration: 0.2s;
  animation-name: slidein;
  max-height: 500px;
  overflow-y: auto;
  padding: 0px 0px;

  &__item {
    display: flex;
    width: 100%;
    padding: 10px 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    border-radius: 5px;
    padding-left: 60px;
  }

  &__item:hover {
    background-color: $cards-color;
  }

  &__header {
    display: flex;
    width: 100%;
    padding: 13px 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border-radius: 5px;
    padding-left: 30px;
    border-bottom: 1px solid $gray-30;
    cursor: default;
  }

  &__footer {
    display: flex;
    width: 100%;
    padding: 13px 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    border-radius: 5px;
    padding-left: 60px;
    border-top: 1px solid $gray-30;
    background: url("./../../images/header/logout-icon.svg") no-repeat 13% 50%;
    background-size: 15px 15px;
    cursor: pointer;
  }

  &__footer:hover {
    background-color: $cards-color;
  }

  &__hide {
    display: none;
  }
  &__plan {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: $contrast-color;
    margin-left: 5px;
  }
}

.testppp {
  top: 90px;
}

.MuiList-padding {
  padding-top: 0px !important;
  padding-bottom: 8px !important;
}

.burgerMenu {
  width: 55px;
  &__menu {
    top: 90px;
  }
  &__burgerIcon {
    background: url("./../../images/header/burgerIcon.svg");
    position: relative;
    width: 30px;
    height: 25px;
    margin: 15px 5px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 3px;

    &::after {
      content: "";
      width: 2px;
      height: 220%;
      background: #e2e2e2;
      right: -20px;
      position: absolute;
      top: -15px;
    }
  }
}

.MuiSFab-root:hover {
  background-color: $contrast-color !important;
}

.MuiSpeedDialAction-fab:hover {
  background-color: $contrast-color !important;
}

//override material ui modal alert
// .MuiCollapse-wrapper{
//   position: fixed;
//   width: 100%;
//   z-index: 10;
// }
