.selectedplan {
  background-color: $background-basic-color;
  margin: 2rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.menuselectedplan {
  width: 98%;
  height: 3.75rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  &__areaclose {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  &__close {
    width: 25px;
    height: 25px;
  }
  &__status {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  &__step {
    width: 30%;
    display: flex;
    align-items: center;
  }
  &__step-active {
    color: $main-text-color;
  }
  &__step-realized {
    color: $gray-90;
  }
  &__step-disabled {
    color: $gray-50;
  }
}
.listdetailplans {
  width: 100%;
  min-height: 100vh;
  display: flex;
}
.listplans {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.main-pago {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 23%;
  min-height: 240px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.21);
  border-radius: 8px;
  padding: 25px 0;
  margin: 0 15px;
  background-color: $cards-color;
  &__title-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
  }
  &__title-name {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: $main-text-color;
  }
  &__title-price {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    color: $contrast-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
  }
  &__title-enterprise {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: $contrast-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    text-align: center;
  }
  &__total-prices {
    width: 100%;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $gray-10;
    border-radius: 18px;
    padding: 15px 0 15px 0;
    margin-top: 25px;
  }
  &__total {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    border: 1px solid $gray-30;
    border-radius: 8px;
    padding: 7px 0 2px 0;
    margin-bottom: 10px;
  }
  &__total-money-value {
    width: 100%;
    display: flex;
  }
  &__total-money {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.12px;
    color: $gray-90;
  }
  &__total-areames {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  &__total-mes {
    width: 50%;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: $gray-90;
  }
  &__total-value {
    width: 70%;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 32px;
    color: $gray-90;
  }
  &__select-period {
    width: 60%;
    border: 1px solid $gray-30;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 0;
    margin-bottom: 10px;
    position: relative;
    flex-direction: column;
  }
  &__select-month-list {
    position: absolute;
    bottom: -50px;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    left: 0;
    background-color: $background-basic-color;
    box-shadow: 0px 2px 6px -3px rgba(156, 156, 156, 1);
    border-radius: 8px;
  }
  &__month-list-label {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    margin: 0;
    cursor: pointer;
    border-radius: 8px;
  }
  &__month-list-label:hover {
    background-color: $cards-color;
  }
  &__discount {
    width: 68%;
    color: $gray-90;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
  }
  &__discount-span {
    color: $success-color;
  }
  &__select-plan {
    width: 70%;
    display: flex;
    align-items: flex-end;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    height: 100%;
    margin: 0;
  }
  &__select-month {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: $contrast-color;
    margin-left: 5px;
  }
  &__select-img {
    width: 15px;
    height: 8px;
    margin: 0 0 4px 10px;
  }
  &__collapse {
    width: 90%;
    margin-bottom: 30px;
    position: relative;
  }
  &__collapse-collapse {
    max-height: 180px !important;
    overflow-y: scroll !important;
    box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1) !important;
    border-radius: 0px 0px 8px 8px !important;
    position: absolute;
    background-color: $gray-10;
    top: 47px;
    width: 100%;
    z-index: 2;
  }
  &__collapse-empty-1 {
    height: 230px;
  }
  &__collapse-empty-2 {
    height: 225px;
  }
  &__collapse-empty-4 {
    height: 140px;
  }
  &__collapse-collapse::-webkit-scrollbar {
    width: 5px;
    border-radius: 4px;
  }
  &__collapse-collapse::-webkit-scrollbar-thumb {
    background: $gray-50;
    border-radius: 4px;
  }
  &__collapse-collapse::-webkit-scrollbar-track {
    background: $cards-color;
    border-radius: 4px;
    height: 100px;
  }
  &__collapse-label {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    margin: 0;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    padding: 15px 0;
    color: $main-text-color;
    background-color: $gray-10;
    z-index: 9;
    position: absolute;
    top: 0;
  }
  &__title-period {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: $main-text-color;
  }
  &__title-period-price {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: $contrast-color;
  }
  &__collapse-img {
    width: 15px;
    cursor: pointer;
  }
  &__button {
    border: 1px solid $gray-30;
    border-radius: 27px;
    background-color: $cards-color;
    color: $gray-90;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    width: 60%;
    padding: 10px 0;
  }
  &__button_blue {
    border-radius: 27px;

    background-color: $contrast-color;
    color: $cards-color;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    width: 60%;
    padding: 10px 0;
  }
  &__button_continuos-pay {
    border: 1px solid $contrast-color;
    border-radius: 27px;
    background-color: $contrast-color;
    color: $cards-color;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    width: 75%;
    padding: 7px 0;
    cursor: pointer;
  }
  &__collapse-list {
    color: $gray-90;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    padding-right: 20px;
  }
  &__collapse-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: $main-text-color;
    margin-left: 25px;
    margin-top: 15px;
  }
}
.paymentscreen {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 56.375rem;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.2);
  border-radius: 8px;
  margin-top: 3rem;
}

.titleofpay {
  &__title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__label {
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 20px;
  }
  &__suscription {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 7.5625rem;
    background: #1996fc;
    border-radius: 8px 8px 0px 0px;
    padding: 1.25rem 3.875rem;
  }

  &__suscription-1 {
    display: flex;
    flex-direction: column;
    &_text {
      color: #fff;
      font-style: normal;
      font-weight: 300;
      font-size: 48px;
      margin: 0;
      line-height: 1;
    }
    small {
      line-height: 1;
      color: #fff;
      font-weight: 400;
      font-size: 16px;
      text-transform: uppercase;
      margin: 0;
      padding: 0;
    }
  }
  &__suscription-2 {
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 1;
    text-align: center;
    p {
      margin: 0;
    }
  }
  &__money {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 1;
    text-align: center;
    color: #fff;
  }
}

.successfulpayment {
  width: 40%;
  height: 500px;
  margin-top: 115px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__areatitle {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
  }
  &__title {
    color: $success-color;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 32px;
    margin-bottom: 20px;
  }
  &__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: $gray-90;
  }
  &__areadetails {
    width: 70%;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }
  &__detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    color: $main-text-color;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
  }
  &__see {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: $contrast-color;
    margin-bottom: 40px;
  }
  &__home {
    background-color: $contrast-color;
    border-radius: 20px;
    color: $cards-color;
    width: 200px;
    padding: 10px 0;
    cursor: pointer;
  }
}

.failedpayment {
  width: 50%;
  height: 400px;
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 32px;
    color: $fail-color;
  }
  &__msg {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: $gray-90;
    text-align: center;
    margin-bottom: 70px;
  }
  &__try {
    background-color: $contrast-color;
    color: $cards-color;
    padding: 10px 30px;
    border-radius: 20px;
    cursor: pointer;
  }
}

.formPay {
  width: 100%;
  display: grid;
  place-items: center;
  padding: 2rem 1rem;
  gap: 1rem;
  &__controller {
    display: flex;
    align-items: center;
    justify-items: flex-end;
    gap: 1rem;
  }
  &__group {
    display: flex;
    gap: 1rem;
    .formPay__label {
      min-width: auto;
      width: max-content;
    }
    .formPay__select {
      width: 7rem;
    }
    .formPay__input {
      width: 16.5rem;
    }
    .formPay__controller:first-child {
      .formPay__label {
        min-width: 12.5rem;
      }
    }
  }
  &__label {
    min-width: 12.5rem;
    text-align: right;
  }
  &__input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }
  &__input,
  &__select {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 28px;
    gap: 10px;
    width: 526px;
    height: 56px;
    background: #f8f8f8;
    border: 1px solid #1996fc;
    border-radius: 10px;
  }
  &__select {
    appearance: none;
    &_wrapper {
      position: relative;
      &::after {
        content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE1IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03LjQ5NTE5IDhDNy4yMDI1OCA4IDYuOTA5OTggNy44OTE4MSA2LjY2NjE0IDcuNjQ4MzhMMC4xMzEyNCAwLjgzMjQzQy0wLjAzOTQ0NzkgMC42NDMwOTggLTAuMDM5NDQ3OSAwLjM0NTU3NyAwLjEwNjg1NiAwLjE1NjI0NUMwLjI3NzU0MyAtMC4wMzMwODc1IDAuNTQ1NzY3IC0wLjAzMzA4NzEgMC43MTY0NTQgMC4xMjkxOThMNy4yNTEzNSA2Ljk0NTE1QzcuMzczMjcgNy4wODAzOSA3LjU5MjczIDcuMDgwMzkgNy43MzkwMyA2Ljk0NTE1TDE0LjI3MzkgMC4xMjkxOThDMTQuNDQ0NiAtMC4wNjAxMzQ1IDE0LjcxMjggLTAuMDMzMDg3NSAxNC44ODM1IDAuMTU2MjQ1QzE1LjA1NDIgMC4zNDU1NzcgMTUuMDI5OCAwLjY0MzA5OCAxNC44NTkxIDAuODMyNDNMOC4zMjQyNCA3LjYyMTM0QzguMDgwNDEgNy44NjQ3NiA3Ljc4NzggOCA3LjQ5NTE5IDhaIiBmaWxsPSIjMTk5NkZDIi8+Cjwvc3ZnPgo=);
        position: absolute;
        right: 0.5rem;
        top: 0;
        bottom: 0;
        left: auto;
        display: flex;
        align-items: center;
        pointer-events: none;
        user-select: none;
      }
    }
  }
  &__address {
    display: flex;
    align-items: center;
    .formPay__select {
      width: 11.3125rem;
    }
    .formPay__input {
      width: 5.625rem;
    }
  }
  &__card {
    display: flex;
    align-items: center;
    & > .formPay__input {
      width: 8.625rem;
    }
    &_input {
      position: relative;
      width: 23rem;
      display: grid;
      gap: 0.5rem;
      grid-template-columns: 1fr 4rem;
      background: #f8f8f8;
      border: 1px solid #1996fc;
      border-radius: 10px;
      .formPay__input {
        border: none;
        width: 100%;
        &.number {
          padding-left: 3.5rem;
        }
        &.cvc {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }
      }
      &-img {
        width: 2rem;
        height: 2rem;
        position: absolute;
        top: 0;
        left: 0.5rem;
        bottom: 0;
        margin: auto;
      }
    }
  }
  &__terms {
    max-width: 500px;
    width: 100%;
    margin-top: 2rem;
    &:last-child {
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
  }
  &__buttons {
    margin-top: 2rem;
    max-width: 700px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__pay {
    width: 246px;
    height: 40px;
    color: #fff;
    background: var(--contrast-color);
    border-radius: 30px;
  }
  &__return {
    color: var(--contrast-color);
    width: 246px;
    height: 40px;
    border: 1px solid #1996fc;
    border-radius: 30px;
  }
}
