.storeSelect {
    position: absolute;
    background-color: rgba(0, 17, 48, 0.8);
    height: 100vh;
    width: 100vw;
    top: 0;
    margin: auto;
    right: 0;
    left: 0;
    // border-radius: 10px;
    z-index: 0;
    
    &__main {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        position: relative;
    }
}
.storeSelector{
    position: absolute;
    width: 679px;
    height: 384px;

    background: #FFFFFF;
    box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.211765);
    border-radius: 10px;
    overflow: scroll;

    &__header{
        display: flex;
        align-items: center;

        width: 679px;
        height: 42px;
        background: #F8F8F8;
        border-radius: 10px 10px 0px 0px;

        p{
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            color: #001130;
            margin: 0 0 0 20px;
        }

    }
    &__search{
        top: 42px;
        width: 100%;
        height: 90px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        &__title{
            top: 42px;
            width: auto;
            height: 60px;
            display: flex;
            justify-content: space-around;
            align-items: center;

            p{
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 27px;
                color: #1996FC;
            }
        }
    }
    &__body{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        grid-gap: 14px;
        margin: 0 20px;
        max-height: 189px;
        overflow-y: auto;
    }

    ::-webkit-scrollbar {
        width: 3px;
        border-radius: 4px;
        display: inline;
      }

      ::-webkit-scrollbar-thumb {
        background: $gray-90;
        border-radius: 4px;
      }
      
      ::-webkit-scrollbar-track {
        background: $gray-30;
        border-radius: 4px;
      }

    &__functs {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 15px;

        &__btn--canc{

            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 15px;
            
            color: #1996FC;
            width: 123px;
            height: 40px;
            border: 1px solid #1996FC;
            border-radius: 20px;
        }
        &__btn--cont{

            width: 151px;
            height: 40px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 19px;

            color: #FFFFFF;
            background: #1996FC;
            border-radius: 30px;
        }
    }
}

.storeStyle{
    display: flex;
    align-items: center;
    grid-gap: 10px;

    width: 306px;
    height: 36px;

    background: #F8F8F8;
    border-radius: 20px;

    Input {
        width: 22px;
        height: 22px;
        background: #F8F8F8;
        margin: 0 10px;     
    }
    &__p {
        font-family: Poppins;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;
        color: #001130;
    }
}
