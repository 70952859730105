.score {
  &__container {
    flex-direction: column;
    gap: 1rem;
  }
  &__ranges {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    width: 100%;
    height: 100%;
  }
  &__content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
  }
  &__input.MuiSlider-vertical {
    --width-rail: 1rem;
    padding: 0;
    color: $contrast-color;
    &,
    .MuiSlider-rail,
    .MuiSlider-track {
      width: var(--width-rail) !important;
      border-radius: 14px;
    }
    .MuiSlider-rail {
      background-color: $gray-50;
    }
    .MuiSlider-thumb {
      width: calc(var(--width-rail) * 1.5);
      height: calc(var(--width-rail) * 1.5);
      margin-left: -4px;
    }
  }
  &__actions {
    display: grid;
    grid-template-columns: 29px max-content;
    justify-content: center;
    gap: 0.25rem;
    margin-bottom: 0.5rem;
  }
  &__item {
    display: grid;
    height: 100%;
    grid-template-rows: max-content 25rem max-content;
    justify-content: center;
    justify-items: center;
    gap: 0.5rem;
    &-reload,
    &-block {
      width: 17px;
      height: 16px;
      cursor: pointer;
      background-position: center;
      background-repeat: no-repeat;
    }
    &-reload {
      background-image: url("../../images/smartmanscore/icon-reload.svg");
      &:hover {
        background-image: url("../../images/smartmanscore/icon-reload_active.svg");
      }
    }
    &-block {
      background-image: url("../../images/smartmanscore/icon-lock.svg");
      &[data-active="true"] {
        background-image: url("../../images/smartmanscore/icon-lock_active.svg");
      }
    }
    &-value,
    &-title {
      margin: 0;
      text-align: center;
    }
  }
  &__graphic {
    display: grid;
    place-content: center;
    &-percent {
      grid-area: 1 / 1 / 1 / 1;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &-number {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
    }
    &-text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
    }
    .recharts-wrapper {
      grid-area: 1 / 1 / 1 / 1;
    }
    .recharts-pie-sector:last-child {
      path {
        fill: $contrast-color;
      }
    }
  }
  &__footer {
    display: grid;
    grid-template-columns: repeat(auto-fit, 200px);
    justify-content: space-around;
    align-items: center;
    .stepsbuttons__back,
    .store__contrast-button {
      cursor: pointer;
      width: 100%;
    }
  }
}
