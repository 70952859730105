.quick-demo{
  background-color: $background-basic-color !important;
  position: absolute !important;
  top: 0px;
  bottom: 0;
  height: 100vh !important;
  width: 500px;
  right: 0px;
  box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.211765);
  border-radius: 10px 0px 0px 10px;
  z-index: 1;

  &__header{
    padding: 29px 39px;
    display: flex;
  }

  &__title{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 27px;
    color: $main-text-color;
    width: 96%;
    padding-top: 7px;
  }

  &__close{
    background: url('../../images/quickdemo/close.svg') 50% 50% no-repeat;
    height: 40px;
    width: 4%;
  }

  &__themes-sumary{
    padding:45px 57px;
  }
}

.theme{
    height: 48px;
    border-bottom: 1px solid $gray-30;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    background: url('../../images/quickdemo/gray-right-arrow.svg') 94% 50% no-repeat;

    &__button-title{
        cursor: pointer;
        padding-top: 13px;
        padding-bottom: 13px;
      }

      &__container{
        background-color: $background-basic-color !important;
        position: absolute !important;
        top: 80px !important;
        bottom: 0;
        height: 87vh;
        width: 460px;
        left: 35px !important;
        
      }

      &__back-button{
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: $contrast-color;
        background: url('../../images/quickdemo/blue-quickdemo-arrow.svg') 7% 50% no-repeat;
        width: 80px;
        padding-left: 25px;
        cursor: pointer;
      }

      &__body{
        padding: 10px 28px 10px 28px;
        margin-right: 25px;
        overflow-y: auto;
        height: 84vh;
      }

      &__body::-webkit-scrollbar {
        width: 8px;
        border-radius: 4px;
      }
      &__body::-webkit-scrollbar-thumb {
        background: $gray-50;
        border-radius: 4px;
      }
      
      &__body::-webkit-scrollbar-track {
        background: $cards-color;
        border-radius: 4px;
      }

      &__title{
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 24px;
        color: $main-text-color;
      }
  }
  
  .theme:nth-child(1){
    border-top: 1px solid $gray-30;
  }

.subtheme{
    padding:20px 0px;

    &__title{
        font-weight: 600;
        font-size: 14px;
        padding:17px 15px;
      }

      &__description{
        margin-left: 15px;
        margin-bottom: 15px;
        font-weight: 400;
        font-size: 14px;
      }

      &__note{
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: var(--gray-90);
        font-style: italic;
        margin:15px 15px
      }
  }
  
.subsubtheme{
    border-bottom: 1px solid $gray-30;

    &__title{
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        padding-left: 15px;
        line-height: 19px;
        color: $gray-90;
        height: 50px;
        padding-top: 13px;
        background: url('../../images/quickdemo/gray-right-arrow.svg') 94% 45% no-repeat;
        cursor: pointer;
      }

    &__title-open{
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        padding-left: 15px;
        line-height: 19px;
        color: $gray-90;
        height: 50px;
        padding-top: 13px;
        background: url('../../images/quickdemo/gray-down-arrow.svg') 94% 45% no-repeat;
        cursor: pointer;
      }

      &__description{
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        padding:0px 15px 25px 30px;
      }

      &__video{
        padding-left: 15px;
      }
    
  }

  .subsubtheme:nth-child(1){
    border-top: 1px solid $gray-30;
  }

.section-point{
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    padding-left: 15px;
    line-height: 19px;
    color: $gray-90;
    margin-left: 45px;
    margin-right: 15px;
  }

.section-collapse{
    margin-left: 45px;
    border-top: 1px solid $gray-30;

    &__title{
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        padding-left: 15px;
        line-height: 19px;
        color: $gray-90;
        height: 35px;
        padding-top: 7px;
        background: url('../../images/quickdemo/gray-right-arrow.svg') 94% 45% no-repeat;
        cursor: pointer;
      }

      &__title-open{
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        padding-left: 15px;
        line-height: 19px;
        color: $gray-90;
        height: 35px;
        padding-top: 7px;
        background: url('../../images/quickdemo/gray-down-arrow.svg') 94% 45% no-repeat;
        cursor: pointer;
      }

      &__text{
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px; 
        color: $gray-90;
        padding: 10px 0px 25px 15px;
      }

      &__steps{
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: $gray-90;
      }
}