.categorysort {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.searchcategory {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: $cards-color;
  border-radius: 8px;
  height: 60px;
  margin-bottom: 20px;
  &__label {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin: 0 20px;
  }
  &__search {
    margin-left: 40px;
    height: 70%;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__input {
    margin-left: 20px;
    height: 80%;
    width: 80%;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: $gray-90;
  }
  &__logo {
    width: 40px;
    height: 40px;
    background-color: $gray-30;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.selectcategory {
  width: 95%;
  display: flex;
  align-items: flex-start;
  &_btns {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 3rem;
    button {
      min-width: 15.375rem;
      justify-content: center;
    }
  }
}
.modal_loading {
  min-height: 24.5rem;
  display: flex;
  align-items: center;
}
.cardcategory {
  width: 25%;
  max-width: 19.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $cards-color;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1.25rem 0.5rem;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    width: 90%;
    margin-bottom: 15px;
  }
  &__body {
    max-height: 400px;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
  }
  &__subtitle {
    width: 90%;
    display: flex;
    justify-content: space-between;
    padding: 0 0.3rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 15px;
    color: $contrast-color;
    margin-bottom: 15px;
  }
  &__subtext1 {
    color: $contrast-color;
  }
  &__subtext2 {
    width: 30%;
    display: flex;
    justify-content: center;
  }
}
.categorytext {
  width: 100%;
  min-height: 40px;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 0.3125rem;
  cursor: pointer;
  box-shadow: 0px 1px 0px 0px $gray-30;
  padding: 0 0.5rem;
  &:last-child {
    box-shadow: none;
  }
  &-input {
    accent-color: $contrast-color;
    margin: 0;
  }
  &::after {
    content: "";
    width: 0px;
    height: 100%;
    position: absolute;
    left: 0;
    background-color: $contrast-color;
    border-radius: 0px 5px 5px 0px;
    transition: width 0.2s;
  }
  &__text {
    width: 90%;
    min-height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  &__category {
    width: 80%;
    height: 2.1875rem;
    font-style: normal;
    display: flex;
    align-items: center;
    font-weight: normal;
    gap: 0.3125rem;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 0;
    cursor: pointer;
  }
  &__img {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    path {
      stroke: $gray-30;
    }
  }
}
.categorytext:hover,
.categorytext.is-active {
  background-color: $gray-10;
  &::after {
    width: 4px;
  }
  .categorytext__text {
    color: $contrast-color;
  }
  .categorytext__img {
    path {
      stroke: $contrast-color;
    }
  }
}
.spacesubcatego {
  width: 100%;
  max-width: 275px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-left: 15px;
  &__triangle {
    position: absolute;
    top: 22px;
    left: -2px;
    width: 17px;
    height: 17px;
    background-color: $cards-color;
    transform: rotate(45deg);
  }
}
.cardsubcategory {
  width: 100%;
  max-width: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $cards-color;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px 0;
}
.sortableItem {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 8px;
  gap: 4px;
  width: 256px;
  height: 364px;
  background: #ffffff;
  border: 2px solid #e2e2e2;
  border-radius: 30px;
  &__image {
    &-img {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      padding: 0px;
      isolation: isolate;
      object-fit: cover;

      width: 208px;
      height: 160px;
    }
    &-discount {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 4px;
      gap: 8px;
      position: absolute;
      width: 29px;
      height: 23px;
      right: 10px;
      top: 10px;
      background: #6acf64;
      border-radius: 5px 5px 5px 0px;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      text-align: right;
      color: #ffffff;
    }
  }
  &__info {
    width: 100%;
    padding: 8px 8px 12px;
    &-title {
      margin: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 12px;
      text-transform: uppercase;
      color: #001130;
    }
    &-strong {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #001130;
    }
    &-items {
      margin: 0;
      list-style: none;
      padding: 0;
    }
    &-item {
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 20px;
      color: #707070;
    }
  }
  &__footer {
    grid-area: 2 / 1 / span 1 / span 2;
    &-button {
      border-radius: 20px;
      background-color: #1996fc;
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      height: 40px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 32px;
      cursor: pointer;
    }
  }
  &__icon {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 2;
  }
}

.contentTables__filter [data-sortable-component] {
  display: grid;
  grid-template-columns: repeat(4, 16rem);
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.25rem;
}

.contentTables__filter .toolbar__sortable {
  min-width: 62.5rem;
  margin: auto;
}

.shelfProduct {
  &__wrapper {
    display: grid;
    grid-template: max-content 1fr / 1fr;
    gap: 1.25rem;
    width: calc(100dvw - 2rem);
    padding: 2rem 1rem;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.2);
    border-radius: 10px;
  }
  &__header {
    width: 100%;
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
  }
  .savechanges__save {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 32px;
    gap: 10px;
    width: 227px;
    height: 40px;
    background: #1996fc;
    border-radius: 30px;
  }
}
