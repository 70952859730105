.new-store {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &__form {
    width: 40%;
    padding: 17px;
  }

  &__banner {
    width: 60%;
  }
}

.new-store-banner {
  background: url("./../../images/newstore/banner.svg") no-repeat;
  height: 100%;
  width: 100%;
  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__main-text {
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 32px;
    width: 100%;
    text-align: center;
    padding-bottom: 17px;
  }

  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 0px 80px;
    text-align: center;
    margin-bottom: 15px;
  }

  &__white-button {
    width: 219px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $cards-color;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $main-text-color;
    border-radius: 20px;
    cursor: pointer;
  }

  &__white-button:hover {
    background-color: $gray-10;
  }

  &__white-button:active {
    background-color: $gray-30;
    box-shadow: 0px 3px 3px rgba(112, 112, 112, 0.4);
  }
}

.store-form {
  position: relative;

  &__body {
    display: flex;
    padding: 45px 35px;
    flex-wrap: wrap;
    background: $cards-color;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.211765);
    border-radius: 8px;
    min-height: calc(100vh - 117px);
  }

  &__row {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    &.storetype {
      display: grid;
      grid-template-columns: max-content max-content;
      gap: 0.5rem;
      margin-bottom: 2rem;
      p {
        margin: 0;
      }
    }
    &__storetype {
      display: flex;
      gap: 0.5rem;
    }
  }

  &__label-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
  }

  &__input {
    border: 1px solid $gray-90;
    opacity: 1;
    color: var(--main-text-color);
    border-radius: 25px;
    width: 100%;
    min-height: 36px;
    margin-top: 0px;
    margin-bottom: 20px;
    padding-left: 20px;
    background-color: transparent;
    font-size: 13px;
  }

  &__input:focus {
    border: 1px solid $contrast-color;
    opacity: 1;
    color: $main-text-color;
    outline: none;
  }

  &__input:placeholder-shown {
    opacity: 0.4;
    color: $gray-90;
    margin-bottom: 20px;
    background-color: transparent;
  }

  &__input-invalid {
    mix-blend-mode: normal;
    border: 1px solid $fail-color;
    opacity: 1;
    color: $main-text-color;
    box-sizing: border-box;
    border-radius: 25px;
    width: 100%;
    min-height: 36px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 20px;
    font-size: 13px;
    background-color: transparent;
  }

  &__text-area {
    resize: none;
    text-transform: uppercase;
    padding-right: 20px;
    margin-bottom: 10px;
  }

  &__text-area-invalid {
    resize: none;
    text-transform: uppercase;
    padding-right: 20px;
  }

  &__error {
    color: $fail-color;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
    margin-left: 20px;
  }

  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  &__element {
    position: relative;
  }

  &__element a {
    text-decoration: underline;
    color: var(--contrast-color);
  }

  &__element section {
    display: none;
  }
  &__element div {
    font-family: "Poppins";
    background-color: $main-text-color;
    border: 1px solid $gray-30;
    min-height: 31px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.12px;
    color: white;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-size: 10px;
    font-weight: normal;
    align-items: center;
    text-align: left;
    vertical-align: middle;
    margin-bottom: 20px;
    width: 275px;
    line-height: 15px;
    top: 55px;
    position: relative;
    z-index: 5;
    right: 250px;
    padding: 10px;
  }

  &__element:hover section {
    display: block;
  }

  &__button-tooltip-space {
    height: 31px;
    padding-top: 0px;
    position: absolute;
    top: -30px;
    display: inline-block;
  }

  &__tooltip-icon {
    display: flex;
    align-items: center;
    background: url("./../../images/tooltip/blue-help.svg") no-repeat 100% 50%;
    background-size: 26px 26px;
    padding: 13px 13px;
    cursor: pointer;
  }

  &__tooltip-icon-invalid {
    display: flex;
    align-items: center;
    background: url("./../../images/tooltip/information.svg") no-repeat 100% 50%;
    background-size: 26px 26px;
    padding: 13px 13px;
    cursor: pointer;
  }

  &__contrast-button {
    border-radius: 20px;
    background-color: $contrast-color;
    color: $cards-color;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    margin-top: 20px;
  }

  &__contrast-button:hover {
    box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
  }

  &__contrast-button:active {
    background-color: $click-color;
  }

  &__analytics-button {
    border-radius: 20px;
    border: 1px solid $main-text-color;
    background: url("./../../images/newstore/ga.svg") no-repeat 50% 50%;
    height: 40px;
    width: 100%;
    cursor: pointer;
    margin-top: 20px;
  }

  &__analytics-button:hover {
    box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
  }

  &__analytics-button-check {
    border-radius: 20px;
    border: 1px solid $contrast-color;
    height: 40px;
    width: 100%;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__ga-check {
    width: 287px;
    display: flex;
    justify-content: flex-start;
  }

  &__analytics-button-check:hover {
    box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
  }

  &__index-loader {
    position: absolute;
    height: 100%;
    width: 100%;
    background: $gray-10;
    opacity: 0.95;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
}

.ga-modal {
  .modal-content {
    border: none;
    box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.211765);
    border-radius: 10px;
    background-color: transparent;
    width: 413px;
    margin: auto;
  }

  &__header {
    height: 60px;
    color: white;
    background-color: $main-text-color;
    border-radius: 10px 10px 0px 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
  }

  &__close-button {
    background: url("./../../images/modal/white-x.svg") no-repeat;
    background-size: 28px 28px;
    padding: 14px;
  }

  &__body {
    border-radius: 0px 0px 10px 10px;
    background: $cards-color;
    padding: 24px 34px 38px 34px;
  }

  &__label-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
  }

  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
  }

  &__account {
    height: 40px;
    width: 100%;
    border: 1px solid $gray-30;
    box-sizing: border-box;
    border-radius: 25px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 13px;
    color: $gray-90;
    margin-bottom: 24px;
  }

  &__contrast-button {
    border-radius: 20px;
    background-color: $contrast-color;
    color: $cards-color;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    cursor: pointer;
    margin-top: 20px;
    margin-left: 8px;
  }

  &__contrast-button:hover {
    box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
  }

  &__contrast-button:active {
    background-color: $click-color;
  }

  &__gray-button {
    border-radius: 20px;
    background-color: $gray-10;
    color: $gray-90;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    cursor: pointer;
    margin-top: 20px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__gray-button:hover {
    box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
  }

  &__gray-button:active {
    background-color: $gray-30;
  }
  &__tooltip {
    display: flex;
    align-items: center;
    background: url("./../../images/tooltip/blue-help.svg") no-repeat 100% 50%;
    background-size: 30px 30px;
    padding: 12px 15px;
    cursor: pointer;
    margin-left: 20px;
  }
}
