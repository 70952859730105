// Compatible with @progress/kendo-theme-default v.7.0.0

$tb-kendo-is-dark-theme: false;
$tb-kendo-border-radius: 16px;
$tb-kendo-color-primary: #1996fc;
$tb-kendo-color-secondary: #707070;
$tb-kendo-color-info: #1475c5;
$tb-kendo-color-success: #6acf64;
$tb-kendo-color-warning: #f6bd41;
$tb-kendo-color-error: #fa7b49;
$primary: #1996fc;
$secondary: #707070;
$info: #1475c5;
$success: #6acf64;
$warning: #f6bd41;
$error: #fa7b49;
$tb-kendo-body-bg: #ffffff;
$tb-kendo-body-text: #707070;
$tb-kendo-heading-text: #001130;
$tb-kendo-subtle-text: #001130;
$tb-kendo-disabled-text: #c4c4c4;
$tb-kendo-base-bg: #ffffff;
$tb-kendo-hover-bg: #ecececff;
$tb-kendo-selected-text: #ffffff;
$tb-kendo-button-bg: #ffffff;
$tb-kendo-button-text: #707070;
$tb-kendo-link-hover-text: #1475c5;
$tb-kendo-series-a: #d9d9d9ff;
$tb-kendo-series-b: #ffe162;
$tb-kendo-series-c: #20c997;
$tb-kendo-series-d: #725bed;
$tb-kendo-series-e: #62d7e1;
$tb-kendo-series-f: #7188a5;
$tb-kendo-component-bg: $tb-kendo-body-bg;
$tb-kendo-component-text: $tb-kendo-body-text;
$tb-kendo-base-text: $tb-kendo-body-text;
$tb-kendo-hover-text: $tb-kendo-base-text;
$tb-kendo-selected-bg: $tb-kendo-color-primary;
$tb-kendo-link-text: $tb-kendo-color-primary;

$kendo-is-dark-theme: $tb-kendo-is-dark-theme;
$kendo-border-radius: $tb-kendo-border-radius;
$kendo-color-primary: $tb-kendo-color-primary;
$kendo-color-secondary: $tb-kendo-color-secondary;
$kendo-color-info: $tb-kendo-color-info;
$kendo-color-success: $tb-kendo-color-success;
$kendo-color-warning: $tb-kendo-color-warning;
$kendo-color-error: $tb-kendo-color-error;
$kendo-body-bg: $tb-kendo-body-bg;
$kendo-body-text: $tb-kendo-body-text;
$kendo-heading-text: $tb-kendo-heading-text;
$kendo-subtle-text: $tb-kendo-subtle-text;
$kendo-disabled-text: $tb-kendo-disabled-text;
$kendo-base-bg: $tb-kendo-base-bg;
$kendo-hover-bg: $tb-kendo-hover-bg;
$kendo-selected-text: $tb-kendo-selected-text;
$kendo-button-bg: $tb-kendo-button-bg;
$kendo-button-text: $tb-kendo-button-text;
$kendo-link-hover-text: $tb-kendo-link-hover-text;
$kendo-series-a: $tb-kendo-series-a;
$kendo-series-b: $tb-kendo-series-b;
$kendo-series-c: $tb-kendo-series-c;
$kendo-series-d: $tb-kendo-series-d;
$kendo-series-e: $tb-kendo-series-e;
$kendo-series-f: $tb-kendo-series-f;
$kendo-component-bg: $tb-kendo-component-bg;
$kendo-component-text: $tb-kendo-component-text;
$kendo-base-text: $tb-kendo-base-text;
$kendo-hover-text: $tb-kendo-hover-text;
$kendo-selected-bg: $tb-kendo-selected-bg;
$kendo-link-text: $tb-kendo-link-text;
$kendo-font-family: Avenir;
$kendo-font-size: 14px;
$kendo-font-weight-normal: 400;
$kendo-line-height: 1.4285714286;

$enable-gradients: true;

$tb-typography: (
  kendo-default-typography: (
    font-family: "Avenir",
    font-size: 14px,
    font-weight: 400,
    line-height: 1.4285714286,
  ),
);

@mixin typography-classes($typography) {
  @each $selector, $property in $typography {
    &-#{$selector} {
      @each $propName, $propValue in $property {
        #{$propName}: #{$propValue};
      }
    }
    &-#{$selector}-important {
      @each $propName, $propValue in $property {
        #{$propName}: $propValue !important;
      }
    }
  }
}

$tb-effects: (
  tb-internal-none-effects: (
    box-shadow: (
      none,
    ),
    filter: (
      none,
    ),
    backdrop-filter: (
      none,
    ),
  ),
);

@mixin effects-classes($effects) {
  @each $selector, $property in $effects {
    &-#{$selector} {
      @each $propName, $propValue in $property {
        #{$propName}: $propValue;
      }
    }
    &-#{$selector}-important {
      @each $propName, $propValue in $property {
        #{$propName}: $propValue !important;
      }
    }
  }
}
