.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 15rem;
    background: #ffffff;
    &__content{
        width: 100%;
        height: 100%;
        max-width: 105rem;
        display: grid;
        grid-template: 1fr / 25rem 1fr;
        justify-content: center;
        padding: 1rem;
        align-items: center;
    }
    &__section {
        &:nth-child(2) {
            display: flex;
            justify-content: space-around;
            align-items: flex-start;
            gap: .5rem;
        }
    }
    &__article{
        &__title {
            font-size: 1.125rem;
        }
        &__link {
            display: block;
            margin: .25rem;
            font-size: .8125rem;
            &:hover{
                color:$contrast-color;
            }
            &--disabled {
                opacity: .5;
                pointer-events: none;
                user-select: none;
                &:hover{
                    color: initial;
                }
            }
        }
    }
    &__logo {
        width: 100%;
        height: 4.8rem;
        display: block;
        margin: auto;
        max-width: 13.4375rem;
        max-height: .6875rem;
    }
}
