.login {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template: 1fr / 1fr minmax(39rem, 0.5fr);
  background: no-repeat center/cover url(../../images/login/bg_login.svg);
  overflow: hidden;
  &__column {
    &:first-child {
      display: grid;
      grid-template-rows: 12rem;
      align-items: center;
      grid-auto-rows: max-content;
      align-content: center;
      justify-content: center;
      gap: 1rem;
    }
    &:last-child {
      width: 42.6rem;
      display: grid;
      grid-template-rows: 1fr max-content;
      padding-left: 0.5rem;
      background-color: #fff;
      clip-path: polygon(9% 0%, 100% 0, 100% 100%, 0% 100%);
    }
  }
  &__logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 430px;
  }
  &__video {
    aspect-ratio: 16/9;
    max-width: 600px;
    margin: auto;
    &--file {
      width: 100%;
      height: 100%;
    }
  }
  &__label {
    width: 465px;
    margin: auto;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    margin-top: 40px;
  }
  &__registration {
    font-size: 13px;
    font-weight: normal;
    width: 75%;
    margin: auto;
    margin-top: 1.25rem;
    &--text {
      margin-right: 18px;
      font-size: 18px;
    }
    &--button {
      color: white;
      background: $contrast-color;
      padding: 8px 24px;
      border-radius: 27px;
      text-decoration: none !important;
      border: none;
      cursor: pointer;
      &:hover {
        color: white;
      }
    }
  }
  &__text {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #001130;
    width: 90%;
    margin: auto;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    align-items: center;
    &::after,
    &::before {
      content: "";
      width: 100%;
      height: 1px;
      background-color: #c3c3c3;
    }
    p {
      background-color: #fff;
    }
  }
  &__content {
    width: 90%;
    margin: auto;
  }
  &__header {
    display: grid;
    grid-template-columns: 2.1875rem 20.125rem;
    gap: .25rem;
    justify-content: center;
  }
  &__footer {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    justify-content: center;
    gap: 1.5rem;
    font-size: 13px;
    padding-bottom: 24px;
    padding-top: 80px;
  }
  &__title {
    grid-area: 1 / 1 / span 1 / -1;
    text-align: center;
    font-size: 1.5625rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
    line-height: 1.2;
  }
  &__subtitle {
    margin: 0;
    font-size: .8125rem;
    text-align: center;
    font-weight: normal;
  }
  &__form {
    width: 75%;
    font-size: 13px;
    margin: auto;
    &-registrer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      gap: 0 1rem;
      .login__controller {
        width: 100%;
        &:nth-child(3){
          grid-area: 2 / 1 / span 1/span 2;
        }
        &:nth-child(4){
          grid-area: 3 / 1 / span 1/span 2;
        }
        &:nth-child(5){
          grid-area: 4 / 1 / span 1/span 2;
        }
        &:nth-child(6){
          grid-area: 5 / 1 / span 1/span 2;
          justify-self: flex-start;
        }
        &:nth-child(7){
          grid-area: 6 / 1 / span 1/span 2;
          justify-self: flex-start;
        }
        &:nth-child(8){
          display: flex;
          justify-content: center;
          grid-area: 7 / 1 / span 1/span 2;
        }
      }
    }
  }
  &__controller {
    &--resend {
      cursor: pointer;
    }
    &--checkbox{
      display: grid;
      grid-template-columns: max-content 1fr;
      .container__form__cart__error {
        grid-area: 2 / 1 / span 1 / span 2;
      }
    }
    &--after {
      display: flex;
      gap: .5rem;
      align-items: center;
      font-weight: normal;
      cursor: pointer;
      a {
        color: $contrast-color;
        text-transform: lowercase;
      }
      &::before {
        content: '';
        display: block;
        width: .75rem;
        height: .75rem;
        border-radius: 50%;
        transform: background .25s ease-in;
        border: 1px solid $contrast-color;
      }
    }
    &--label {
      font-weight: 600;
      margin-left: 10px;
      margin-bottom: 5px;
      font-size: 14px;
      display: inline-block;
    }
    &--input {
      mix-blend-mode: normal;
      border: 1px solid $contrast-color;
      opacity: 1;
      color: $main-text-color;
      box-sizing: border-box;
      border-radius: 25px;
      width: 100%;
      height: 36px;
      padding-left: 15px;
      background-color: #fff;

      &[type="text"],
      &[type="password"] {
        box-shadow: 0 0 0px 1000px #fff inset;
      }
      &[type="password"] {
        padding-right: 2.4rem;
      }
      &[type="submit"] {
        width: 100%;
        height: 36px;
        border-radius: 2px;
        background-color: $contrast-color;
        font-family: "Poppins", sans-serif;
        font-size: 13px !important;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        padding: 0;
        color: #ffffff;
        border-style: none;
        margin-top: 30px;
        margin-bottom: 30px;
        border-radius: 20px;
        cursor: pointer;
        &:disabled{
          background-color: $gray-50;
        }
        &.login__controller--registrer{
          width: 8rem;
          margin: auto;
          margin-top: 0;
        }
      }
      &[type='checkbox'] {
        display: none;
        &:checked ~ span::before {
          background: $contrast-color;
        }
      }
    }
    &--reset {
      float: right;
      & > * {
        color: $contrast-color;
        cursor: pointer;
      }
    }
    &--eye {
      text-align: right;
      padding-right: 30px;
      height: 0px;
      float: right;
      cursor: pointer;
      .icon {
        position: relative;
        top: -38px;
        right: -20px;
        width: 24px;
        height: 24px;
        opacity: 0.54;
        border: none;
      }
    }
  }
  &__sesion {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
  }
}
.link-section{
  display: flex;
  gap: .5rem;
  align-items: center;
  margin: .5rem 0;
  & > * {
    margin: 0;
    padding: 0;
  }
  button {
    cursor: pointer;
    color: $contrast-color;
  }
}

.formOtp {
  display: grid;
  grid-template-columns: repeat(6, 2.1875rem);
  gap: 1rem;
  justify-content: center;
  margin: 1rem 0;
  .container__form__cart__error{
    grid-area: 2 / 1 / span 1 / -1;
    
  }
  .login__controller {
    width: 2.1875rem;
    height: 2.5rem;
    & > input {
      border-radius: 5px;
    }
    &:last-child {
      grid-area: 3 / 1 / span 1/ -1;
      width: 100%;
      display: flex;
      justify-content: space-around;
      & > input {
        border-radius: 25px;
        padding: 0 .5rem;
      }

    }
  }
}