.app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  &__main-content {
    width: 100%;
    min-height: 65vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
}
