$fail-color: var(--fail-color);
$main-text-color: var(--main-text-color);
$contrast-color: var(--contrast-color);
$contrast-color_dark: var(--contrast-color_dark);
$success-color: var(--success-color);
$gray-90: var(--gray-90);
$gray-50: var(--gray-50);
$gray-30: var(--gray-30);
$gray-10: var(--gray-10);
$cards-color: var(--cards-color);
$background-basic-color: var(--background-basic-color);
$alert-color: var(--alert-color);
$high-smartman-color: var(--high-smartman-color);
$click-color: var(--click-color);
$middle-smartman-color: var(--middle-smartman-color);
$low-smartman-color: var(--low-smartman-color);
$fontfamily: var(--fontfamily);

:root {
  --fail-color: #fa7b49;
  --main-text-color: #001130;
  --contrast-color: #1996fc;
  --contrast-color_dark: #001130;
  --success-color: #6acf64;
  --gray-90: #707070;
  --gray-50: #c4c4c4;
  --gray-30: #e2e2e2;
  --gray-10: #f8f8f8;
  --cards-color: #ffffff;
  --background-basic-color: #f5f6fa;
  --alert-color: #f6bd41;
  --high-smartman-color: #1475c5;

  --click-color: #1475c5;
  --middle-smartman-color: #81afdc;
  --low-smartman-color: #dce9f3;
  --fontfamily: "Poppins", sans-serif;
}
