.fail-modal {
  background-color: $fail-color;
  color: white;
  padding: 0px 135px;
  height: 55px;
  display: flex;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: 10;

  &__left-side {
    justify-content: center;
    width: 95%;
    display: flex;
    align-items: center;
  }

  &__right-side {
    justify-content: flex-start;
    width: 5%;
    display: flex;
    align-items: center;
  }

  &__icon-info {
    background: url("./../../images/modal/white-info.svg") no-repeat;
    background-size: 24px 24px;
    padding: 12px;
  }

  &__main-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    padding-left: 15px;
  }

  &__close-button {
    background: url("./../../images/modal/white-x.svg") no-repeat;
    background-size: 20px 20px;
    padding: 10px;
  }
}

.success-modal {
  background-color: $success-color;
  color: white;
  padding: 0px 135px;
  height: 55px;
  display: flex;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: 10;

  &__left-side {
    justify-content: center;
    width: 95%;
    display: flex;
    align-items: center;
  }

  &__right-side {
    justify-content: flex-start;
    width: 5%;
    display: flex;
    align-items: center;
  }

  &__main-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    padding-left: 15px;
  }

  &__icon-check {
    background: url("./../../images/modal/white-check.svg") no-repeat;
    background-size: 24px 24px;
    padding: 12px;
  }

  &__close-button {
    background: url("./../../images/modal/white-x.svg") no-repeat;
    background-size: 20px 20px;
    padding: 10px;
  }

  &__normal-link {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    text-decoration-line: underline;
    color: white;
    margin-left: 20px;
  }

  &__special-link {
    width: 204px;
    height: 40px;
    background-color: white;
    border-radius: 20px;
    margin-left: 20px;
    padding: 10px 20px;
  }

  &__special-link:hover {
    background-color: $gray-10;
  }

  &__special-link:active {
    background: $gray-30;
    box-shadow: 0px 3px 3px rgba(112, 112, 112, 0.4);
  }
}

.info-modal {
  background-color: $contrast-color;
  color: white;
  padding: 0px 135px;
  height: 55px;
  display: flex;
  align-items: center;

  &__left-side {
    justify-content: center;
    width: 95%;
    display: flex;
    align-items: center;
  }

  &__right-side {
    justify-content: flex-start;
    width: 5%;
    display: flex;
    align-items: center;
  }

  &__main-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    padding-left: 15px;
  }

  &__icon-check {
    background: url("./../../images/modal/white-check.svg") no-repeat;
    background-size: 24px 24px;
    padding: 12px;
  }

  &__icon-info {
    background: url("./../../images/modal/white-info.svg") no-repeat;
    background-size: 24px 24px;
    padding: 12px;
  }

  &__close-button {
    background: url("./../../images/modal/white-x.svg") no-repeat;
    background-size: 20px 20px;
    padding: 10px;
  }

  &__normal-link {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    text-decoration-line: underline;
    color: white;
    margin-left: 20px;
  }
}

.modal-background {
  background-color: rgba(2, 17, 28, 0.9) !important;
  backdrop-filter: blur(4px) !important;
}

.c-materialModal {
  display: flex;
  justify-content: center;
  align-items: center;
  &[type='aside']{
    z-index: 9 !important;
    justify-content: flex-end;
    .MuiBackdrop-root{
      background-color: rgba(0, 17, 48, 0.7);
      backdrop-filter: blur(2px);
    }
    .c-materialModal_content{
      position: relative;
      border-radius: 10px 0 0 10px;
      width: 500px;
    }
  }
  &_content {
    background-color: #fff;
    padding: 1rem;
    border-radius: 10px;
    width: 95%;
    min-height: 70vh;
  }
}

.modal {
  width: 35.9375rem;
  background: #fff;
  min-height: 500px;
  border-radius: 12px;
  padding: 0.5rem 0.5rem 2rem;
  &__wrapper{
    width: 100vw;
    min-height: 100vh;
    display: grid;
    place-content: center;
  }
  &__body{
    width: 100%;
    max-width: 25.5rem;
    margin: auto;
  }
  &__text {
    grid-area: 2 / 1 / span 1 / -1;
    text-align: center;
  }
  &__header {
    display: flex;
    justify-content: flex-end;
  }
  &__close {
    font-size: 1.3rem;
    cursor: pointer;
  }
}