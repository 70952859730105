.tableCollections {
  width: 95%;
  background: #fff;
  min-height: 5rem;
  border-radius: 0.5rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  &__table {
    margin: 2rem auto;
  }
  .headerstableordering {
    width: 81.5%;
    &__contCheck {
      display: flex;
      font-size: 0.7rem;
      align-items: center;
      .itemrowbodyordering {
        &__checkbox {
          width: 29%;
        }
        &__spancheck {
          top: -8.4px;
        }
      }
    }
  }
}
.createCollection__content {
  width: 100%;
  height: max-content;
  display: grid;
  grid-template: min-content min-content / minmax(334px, 0.25fr) 1fr;
  gap: 0.5rem;
  padding: 0 1rem;

  .contentTables__filter {
    grid-area: 1 / 1 / 4 / 3;
  }
  &.create__groups {
    .contentTables__filter {
      margin: 0 1rem 2rem 5rem;
      grid-area: 4 / 1 / 4 / 3;
    }
  }
  .MuiAccordion-root {
    background-color: transparent;
    box-shadow: none;
  }
  .MuiAccordionSummary-root {
    background-color: #fff;
    border-radius: 10px;
    border-bottom: 1px solid #fff;
    transition: border-radius 0.25s linear;
    &.Mui-expanded {
      min-height: auto;
      border-radius: 10px 10px 0 0;
    }
    .MuiIconButton-label {
      .MuiSvgIcon-root {
        fill: var(--contrast-color_dark);
      }
    }
  }
  .MuiAccordionDetails-root {
    background-color: var(--gray-10);
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 0 0 10px 10px;
  }
  .MuiAccordionSummary-content {
    &.Mui-expanded {
      margin: 0;
    }
  }
  .MuiAccordion-root {
    &.Mui-expanded {
      margin: 0;
    }
  }
  .accordion {
    &__title {
      color: var(--contrast-color_dark);
      font-weight: 600;
    }
    &__content {
      width: 100%;
      display: grid;
      gap: 0.5rem;
      justify-items: center;
      &-header {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr max-content;
        justify-items: flex-end;
      }
      &-search {
        width: 100px;
      }
      &-button {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        text-decoration: underline;
        color: var(--contrast-color);
        cursor: pointer;
        [data-icon="sort"] {
          display: block;
          width: 1.5625rem;
          height: 1.5625rem;
          border-radius: 50%;
          background: no-repeat center / 14px
            url(../../images/collections/icon_order.svg);
          background-color: var(--contrast-color);
        }
      }
    }
    &__button {
      width: 100%;
      max-width: 14.7825rem;
      height: 1.68rem;
      background-color: var(--contrast-color_dark);
      color: var(--gray-10);
      border-radius: 1.875rem;
      cursor: pointer;
    }
    &__selectall {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--contrast-color);
      font-weight: 500;
      cursor: pointer;
      &-input {
        appearance: none;
        width: 1.25rem;
        height: 1.25rem;
        border: 1px solid var(--gray-90);
        border-radius: 50%;
        padding: 10px;
        box-sizing: border-box;
        &:checked {
          background: var(--contrast-color);
          background: radial-gradient(
            circle,
            var(--contrast-color) 0%,
            var(--contrast-color) 50%,
            var(--gray-10) 50%,
            var(--gray-10) 100%
          );
        }
      }
    }
    &__products {
      width: 100%;
      border-radius: 0.5rem;
      .contentTables__filter {
        margin: 0;
      }
      &-header {
        border-radius: 0.5rem 0.5rem 0 0;
        display: flex;
        gap: 0.25rem;
        height: 2.5rem;
        padding: 0.5rem;
        align-items: center;
        background-color: var(--gray-30);
      }
      &-content {
        padding: 0.25rem 0.5rem;
        border-radius: 0 0 0.5rem 0.5rem;
        max-height: 21.6rem;
        overflow: hidden;
        overflow-y: scroll;
      }
    }
    &__groups {
      width: 100%;
      padding: 0.25rem;
      border-radius: 0 0 0.5rem 0.5rem;
      max-height: 13rem;
      overflow: hidden;
      overflow-y: scroll;
    }
    &__seeAll {
      text-decoration: underline;
      cursor: pointer;
      justify-self: flex-start;
      padding: 0.5rem 0.25rem;
    }
    &__sort {
      display: flex;
      flex-direction: column;
      gap: 0.15rem;
      &-asc,
      &-desc {
        font-size: 0;
        width: 0.5169rem;
        height: 0.3669rem;
        background: var(--gray-90);
        cursor: pointer;
      }
      &-asc {
        clip-path: polygon(50% 0, 0 100%, 100% 100%);
      }
      &-desc {
        clip-path: polygon(50% 100%, 0 0, 100% 0);
        filter: contrast(5.5);
      }
    }
  }
  .groupDetails {
    &__container {
      position: relative;
      grid-area: 1 / 1 / 4 / 3;
      width: 100%;
      max-width: 75rem;
      padding-left: 4rem;
      padding-bottom: 2rem;
      height: min-content;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: max-content max-content 1fr max-content;
      min-height: 18.75rem;
      border-radius: 0.625rem;
    }
    &__contype {
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
      gap: 1rem;
      &_label {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        &:nth-child(1) {
          .groupDetails__contype_icon {
            background: no-repeat center / contain
              url(../../images/collections/icon_exclude.svg);
          }
        }
        &:nth-child(2) {
          .groupDetails__contype_icon {
            background: no-repeat center / contain
              url(../../images/collections/icon_include.svg);
          }
        }
      }
      &_icon {
        font-size: 0;
        width: 14px;
        height: 14px;
        border-radius: 50%;
      }
    }

    &__item {
      padding: 0.5rem 1rem;
      &[data-group="false"]::after {
        content: "";
        position: absolute;
        inset: 0;
        background: rgba(255, 255, 255, 0.14);
        backdrop-filter: blur(4px);
      }
      & > * {
        margin: 0;
      }
      &_group {
        color: #001130;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        margin: 0;
        line-height: 24px; /* 150% */
      }
      &_name {
        display: flex;
        align-items: flex-start;
        gap: 0.5rem;
      }
      &:nth-child(1) {
        display: inline-flex;
        align-items: center;
        gap: 0.5rem;
        .groupDetails__title {
          font-size: 18px;
          font-weight: 600;
          margin: 0;
        }
      }
      &:nth-child(2) {
        position: relative;
        min-height: 3rem;
        background-color: #fff;
        padding: 0;
      }
      &:nth-child(3) {
        padding: 0;
      }
      &_text {
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
      }
      &-button {
        display: flex;
        width: 650px;
        height: 40px;
        padding: 8px 32px;
        justify-content: center;
        margin: 1rem auto auto;
        align-items: center;
        gap: 8px;
        color: var(--gray-10);
        background-color: var(--contrast-color);
        border-radius: 30px;
        cursor: pointer;
      }
      .tableheadandbody__head {
        &:nth-child(2) {
          width: 24.5%;
        }
        &:nth-child(3) {
          width: 10%;
        }
        &:nth-child(9) {
          width: 12%;
        }
        &:nth-child(10) {
          width: 8%;
        }
      }
      .itemrowbodyordering__rowbody2 {
        width: 24.5%;
      }
      .itemrowbodyordering__rowbody {
        &:nth-child(3) {
          width: 10%;
        }
        &:nth-child(9) {
          width: 12%;
        }
        &:nth-child(10) {
          width: 8%;
        }
      }
    }
    &__button {
      width: 1.375rem;
      height: 1.375rem;
      padding: 0;
      margin: 3px 4px 3px 3px;
      background: no-repeat center / 14px
        url("../../images/collections/icon_pen.svg");
      font-size: 0;
      color: transparent;
      cursor: pointer;
    }
    &__table-accordion-title {
      color: var(--atom-color-primary-click, #1475c5);
      font-feature-settings: "clig" off, "liga" off;

      /* atom/typography/body-02 */
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px; /* 135.714% */
    }
  }
}

.create-group__modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  align-items: center;
  gap: 1rem;
  &--error {
    margin: 0 8px;
    padding: 0 5px;
    font-size: 0.7rem;
    color: var(--fail-color);
  }
  &--button {
    all: unset;
    border-radius: 20px;
    background-color: #1996fc;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 32px;
    cursor: pointer;
  }
  &--input {
    all: unset;
    width: 100%;
    border: 1px solid var(--gray-30, #e2e2e2);

    margin: 0;
    padding: 5px;
    border-radius: 8px;
    outline: none;
    resize: none;
    box-sizing: border-box;
  }
}
