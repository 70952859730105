.sidebar {
  width: 6%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: sticky;
    top: 80px;
}
.itemsidebar {
  height: 19%;
  top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &__hoveractive {
    width: 5px;
    height: 45px;
    background-color: $contrast-color;
    position: absolute;
    left: 0;
    border-radius: 0px 5px 5px 0px;
  }
  &__hover {
    width: 0;
    height: 45px;
    background-color: $contrast-color;
    position: absolute;
    left: 0;
    border-radius: 0px 5px 5px 0px;
    transition: width 0.2s;
  }
  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 55px;
  }

  &__links {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: $gray-90;
  }

  &__linkactive {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: $main-text-color;
  }
  &__link:hover {
    text-decoration: none;
  }
  &__img-0 {
    width: 26px;
    height: 35px;
    background-image: url("../../images/sidebar/userAvatar.svg");
    background-repeat: no-repeat;
  }
  &__img-1 {
    width: 26px;
    height: 35px;
    background-image: url("../../images/sidebar/newsIcon.svg");
    background-repeat: no-repeat;
  }
  &__img-2 {
    width: 27px;
    height: 32px;
    background-image: url("../../images/sidebar/gearIcon.svg");
    background-repeat: no-repeat;
  }
  &__img-3 {
    width: 32px;
    height: 33px;
    background-image: url("../../images/sidebar/shopIcon.svg");
    background-repeat: no-repeat;
  }
  &__img-4 {
    width: 25px;
    height: 25px;
    background-image: url("../../images/sidebar/logoutIcon.svg");
    background-repeat: no-repeat;
  }

  &__img-0-active {
    width: 26px;
    height: 35px;
    background-image: url("../../images/sidebar/userAvatar.svg");
    background-repeat: no-repeat;
  }
  &__img-1-active {
    width: 26px;
    height: 35px;
    background-image: url("../../images/sidebar/newsIcon.svg");
    background-repeat: no-repeat;
  }
  &__img-2-active {
    width: 27px;
    height: 32px;
    background-image: url("../../images/sidebar/gearIcon.svg");
    background-repeat: no-repeat;
  }
  &__img-3-active {
    width: 32px;
    height: 33px;
    background-image: url("../../images/sidebar/shopIcon.svg");
    background-repeat: no-repeat;
  }
  &__img-4-active {
    width: 25px;
    height: 25px;
    background-image: url("../../images/sidebar/logoutIcon.svg");
    background-repeat: no-repeat;
  }
}
.MuiSpeedDialAction {
  width: 75px;
  height: 75px;
}

.MuiSFab-root:hover {
  background-color: $contrast-color !important;
  background-color: #1996FC !important;
}

.MuiSpeedDialAction-fab:hover {
  background-color: $contrast-color !important;
  background-color: #1996FC !important;
}

.itemsidebar:hover .itemsidebar__links {
  color: $main-text-color;
}

.itemsidebar{
  &__img-0:hover {
    width: 26px;
    height: 35px;
    background-image: url("../../images/sidebar/userAvatarLight.svg");
  }
  &__img-1:hover  {
    width: 26px;
    height: 35px;
    background-image: url("../../images/sidebar/newsIconLigh.svg");
  }
  &__img-2:hover  {
    width: 27px;
    height: 32px;
    background-image: url("../../images/sidebar/gearIconLight.svg");
  }
  &__img-3:hover  {
    width: 32px;
    height: 33px;
    background-image: url("../../images/sidebar/shopIconLight.svg");
  }
  &__img-4:hover  {
    width: 25px;
    height: 25px;
    background-image: url("../../images/sidebar/logoutIconLight.svg");
  }
}
