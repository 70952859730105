*,
*::before,
*:after {
  box-sizing: border-box;
  font-family: $fontfamily;
}

html,
body {
  height: 100vh;
  width: 100%;
}
body {
  margin: 0;

  font-size: 14px;
  background-color: $background-basic-color;
  color: $main-text-color;
}

button {
  background: none;
  border: none;
  outline: none;
  font-family: inherit;
}

input {
  border: none;
  outline: none;
  background: none;
}

a {
  text-decoration: none;
  color: $main-text-color;
}

a:hover {
  text-decoration: none;
}

.clear-select {
  border-radius: 30px;
  border: 1px solid $contrast-color;
  background-color: $cards-color;
  color: $main-text-color;
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
}

.contrast-button {
  border-radius: 20px;
  background-color: $contrast-color;
  color: $cards-color;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.contrast-button:hover {
  box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
}

.contrast-button:active {
  background-color: $click-color;
}

.transparent-button {
  border-radius: 27px;
  border: 1px solid $contrast-color;
  background-color: transparent;
  color: $contrast-color;
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
}

.transparent-button:hover {
  border: 1px solid $click-color;
  color: $click-color;
}

.transparent-button:active {
  border: 1px solid $click-color;
  color: $click-color;
  background-color: $gray-10;
}

*::-webkit-scrollbar {
  width: 5px;
  border-radius: 4px;
}
*::-webkit-scrollbar-thumb {
  background: $gray-50;
  border-radius: 4px;
}
*::-webkit-scrollbar-track {
  background: $cards-color;
  border-radius: 4px;
  height: 100px;
}

[hidden] {
  display: none !important;
}

.modal-backdrop {
  background-color: $main-text-color !important;
}

input:-internal-autofill-selected {
  background-color: white !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 610px !important;
  }
}

.MuiTooltip-tooltipPlacementBottom {
  margin: 5px 0 !important;
}

th.k-filterable.k-table-th.k-header:has(
    a[aria-label="New Position Column menu"],
    a[aria-label="Nuevo Vtex Score Column menu"],
    a[aria-label="New Vtex Score Column menu"],
    a[aria-label="Nueva Posicion Vtex Score Column menu"],
    a[aria-label="Nueva Posición Vtex Score Column menu"],
    a[aria-label="Nuevo Vtex Score Column menu"],
    a[aria-label="Nuevo Score Column menu"],
    a[aria-label="Nueva Posición Column menu"],
    a[aria-label="Nova posição Column menu"],
    a[aria-label="Nova pontuação Vtex Column menu"],
    a[aria-label="New Vtex Score Column menu"],
    a[aria-label="New Score Column menu"],

  ) {
  background: rgba(81, 148, 255, 0.3);
  background-color: rgba(81, 148, 255, 0.3);
}
