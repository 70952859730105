@import "tokens";

@import "@progress/kendo-theme-default/dist/all";

@import "overrides";

.k-table-thead {
  background-color: #d8ecff;
}
.k-header {
  border-color: #001130 !important;
}

.k-grid {
  border-color: transparent;
}

.k-grid .k-grid-aria-root {
  border-color: #e2e2e2;
  border: 1px solid #e2e2e2;
}

.k-table-thead .k-table-row {
  height: 2.5625rem;
}
.k-table-tbody .k-table-row {
  height: 3.5rem;
}

.k-table-thead .k-cell-inner > .k-link {
  justify-content: flex-start;
}

.k-table-tbody .k-table-row {
  background-color: #fff;
}
.k-table-tbody .k-table-row.k-table-alt-row {
  background-color: #f8f8f8;
}
.k-grid td {
  text-align: left;
  color: #707070;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0.5rem 0.75rem;
}
.k-table-thead .k-column-title {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
}
.k-table-thead .k-sort-order {
  font-size: 10px;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px; /* 150% */
}
.k-table-thead .k-sort-icon {
  display: inline-flex;
  align-items: center;
}

.k-header .k-cell-inner .k-svg-i-sort-desc-small svg,
.k-header .k-cell-inner .k-svg-i-sort-asc-small svg,
.k-table-thead .k-svg-icon.k-svg-i-more-vertical svg {
  display: none;
}
.k-grid-header .k-grid-header-menu:focus {
  box-shadow: none;
}
.k-header .k-cell-inner .k-svg-i-sort-desc-small::after,
.k-header .k-cell-inner .k-svg-i-sort-asc-small::after,
.k-table-thead .k-svg-icon.k-svg-i-more-vertical::after {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIKICAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aCBkPSJNMTQuMDAwMiAxMS45OTk1QzE0LjAwMDIgMTAuODg1MiAxMy4wODYgOS45OTk1MSAxMi4wMDAyIDkuOTk5NTFDMTAuODg2IDkuOTk5NTEgMTAuMDAwMiAxMC45MTM4IDEwLjAwMDIgMTEuOTk5NUMxMC4wMDAyIDEzLjExMzggMTAuOTE0NSAxMy45OTk1IDEyLjAwMDIgMTMuOTk5NUMxMy4xMTQ1IDEzLjk5OTUgMTQuMDAwMiAxMy4xMTM4IDE0LjAwMDIgMTEuOTk5NVpNMTEuMDAwMiAxMS45OTk1QzExLjAwMDIgMTEuNDU2NyAxMS40NTc0IDEwLjk5OTUgMTIuMDAwMiAxMC45OTk1QzEyLjU0MzEgMTAuOTk5NSAxMy4wMDAyIDExLjQ1NjcgMTMuMDAwMiAxMS45OTk1QzEzLjAwMDIgMTIuNTQyNCAxMi41NDMxIDEyLjk5OTUgMTIuMDAwMiAxMi45OTk1QzExLjQ1NzQgMTIuOTk5NSAxMS4wMDAyIDEyLjU0MjQgMTEuMDAwMiAxMS45OTk1WiIgZmlsbD0iIzAwMTEzMCIvPgogICAgPHBhdGggZD0iTTE0LjAwMDIgMTcuOTk5NUMxNC4wMDAyIDE2Ljg4NTIgMTMuMDg2IDE1Ljk5OTUgMTIuMDAwMiAxNS45OTk1QzEwLjg4NiAxNS45OTk1IDEwLjAwMDIgMTYuOTEzOCAxMC4wMDAyIDE3Ljk5OTVDMTAuMDAwMiAxOS4xMTM4IDEwLjkxNDUgMTkuOTk5NSAxMi4wMDAyIDE5Ljk5OTVDMTMuMTE0NSAyMC4wMjgxIDE0LjAwMDIgMTkuMTEzOCAxNC4wMDAyIDE3Ljk5OTVaTTExLjAwMDIgMTcuOTk5NUMxMS4wMDAyIDE3LjQ1NjcgMTEuNDU3NCAxNi45OTk1IDEyLjAwMDIgMTYuOTk5NUMxMi41NDMxIDE2Ljk5OTUgMTMuMDAwMiAxNy40NTY3IDEzLjAwMDIgMTcuOTk5NUMxMy4wMDAyIDE4LjU0MjQgMTIuNTQzMSAxOC45OTk1IDEyLjAwMDIgMTguOTk5NUMxMS40NTc0IDE4Ljk5OTUgMTEuMDAwMiAxOC41NzA5IDExLjAwMDIgMTcuOTk5NVoiIGZpbGw9IiMwMDExMzAiLz4KICAgIDxwYXRoIGQ9Ik0xNC4wMDAyIDUuOTk5NTFDMTQuMDAwMiA0Ljg4NTIzIDEzLjA4NiAzLjk5OTUxIDEyLjAwMDIgMy45OTk1MUMxMC44ODYgMy45OTk1MSAxMC4wMDAyIDQuOTEzOCAxMC4wMDAyIDUuOTk5NTFDMTAuMDAwMiA3LjExMzggMTAuOTE0NSA3Ljk5OTUxIDEyLjAwMDIgNy45OTk1MUMxMy4xMTQ1IDcuOTk5NTEgMTQuMDAwMiA3LjA4NTIzIDE0LjAwMDIgNS45OTk1MVpNMTEuMDAwMiA1Ljk5OTUxQzExLjAwMDIgNS40NTY2NSAxMS40NTc0IDQuOTk5NTEgMTIuMDAwMiA0Ljk5OTUxQzEyLjU0MzEgNC45OTk1MSAxMy4wMDAyIDUuNDU2NjUgMTMuMDAwMiA1Ljk5OTUxQzEzLjAwMDIgNi41NDIzNyAxMi41NDMxIDYuOTk5NTEgMTIuMDAwMiA2Ljk5OTUxQzExLjQ1NzQgNi45OTk1MSAxMS4wMDAyIDYuNTQyMzcgMTEuMDAwMiA1Ljk5OTUxWiIgZmlsbD0iIzAwMTEzMCIvPgo8L3N2Zz4K);
}

.k-header .k-cell-inner .k-svg-i-sort-asc-small::after {
  width: 15px;
  height: 15px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNSAxNiIgZmlsbD0ibm9uZSIKICAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTcuNTAwMDIgNS41QzcuMjE1ODYgNS41IDYuOTQzMzMgNS42MTI4OCA2Ljc0MjQgNS44MTM4MkwzLjMxMzgxIDkuMjQyNEMyLjg5NTQgOS42NjA4MiAyLjg5NTQgMTAuMzM5MiAzLjMxMzgyIDEwLjc1NzZDMy43MzIyMyAxMS4xNzYxIDQuNDEwNjMgMTEuMTc2MSA0LjgyOTA1IDEwLjc1NzZMNy41MDAwMSA4LjA4NjY3TDEwLjE3MDkgMTAuNzU3NkMxMC41ODk0IDExLjE3NjEgMTEuMjY3OCAxMS4xNzYxIDExLjY4NjIgMTAuNzU3NkMxMi4xMDQ2IDEwLjMzOTIgMTIuMTA0NiA5LjY2MDgzIDExLjY4NjIgOS4yNDI0MUw4LjI1NzY0IDUuODEzODJMNy45MDQwOCA2LjE2NzM3TDguMjU3NjQgNS44MTM4MkM4LjA1NjcxIDUuNjEyODggNy43ODQxOCA1LjUgNy41MDAwMiA1LjVaIiBmaWxsPSIjMTk5NkZDIi8+Cjwvc3ZnPgo=);
}
.k-header .k-cell-inner .k-svg-i-sort-desc-small::after {
  width: 15px;
  height: 15px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNSAxNiIgZmlsbD0ibm9uZSIKICAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTcuNSAxMC41QzcuMzM0MjQgMTAuNSA3LjE3NTI3IDEwLjQzNDIgNy4wNTgwNiAxMC4zMTY5TDMuMzA4MDkgNi41NjY5NEMzLjA2NDAyIDYuMzIyODYgMy4wNjQwMiA1LjkyNzEzIDMuMzA4MSA1LjY4MzA2QzMuNTUyMTggNS40Mzg5OCAzLjk0NzkxIDUuNDM4OTggNC4xOTE5OCA1LjY4MzA2TDcuNSA4Ljk5MTExTDEwLjgwODEgNS42ODMwNkMxMS4wNTIxIDUuNDM4OTggMTEuNDQ3OSA1LjQzODk4IDExLjY5MTkgNS42ODMwNkMxMS45MzYgNS45MjcxNCAxMS45MzYgNi4zMjI4NiAxMS42OTE5IDYuNTY2OTRMNy45NDE5NCAxMC4zMTY5QzcuODI0NzMgMTAuNDM0MiA3LjY2NTc2IDEwLjUgNy41IDEwLjVaIiBmaWxsPSIjMTk5NkZDIiBzdHJva2U9IiMxOTk2RkMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
}

.k-grid-pager {
  background-color: transparent;
  border-color: transparent;
  justify-content: center;
}

.k-pager-numbers .k-button {
  display: flex;
  align-items: center;
  width: 25px;
  height: 25px;
  padding: 3px 9px 2px 8px;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
}

.k-pager-numbers-wrap {
  align-items: center;
}

.k-pager-numbers .k-button .k-button-text {
  color: #707070;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 166.667% */
}
.k-pager-numbers .k-button.k-selected .k-button-text {
  font-size: 12px;
  color: #fff;
  z-index: 1;
}

.k-pager-numbers .k-button.k-selected::before {
  background: #1475c5;
  opacity: 1;
}
.k-pager {
  margin-top: 1.4375rem;
}
.k-pager-info {
  flex: unset;
  text-align: left;
  justify-content: flex-start;
}

.k-pager-nav .k-svg-i-caret-alt-right svg,
.k-pager-nav .k-svg-i-caret-alt-to-right svg,
.k-pager-nav .k-svg-i-caret-alt-left svg,
.k-pager-nav .k-svg-i-caret-alt-to-left svg {
  display: none;
}

.k-pager-nav .k-svg-i-caret-alt-to-right::after,
.k-pager-nav .k-svg-i-caret-alt-right::after,
.k-pager-nav .k-svg-i-caret-alt-left::after,
.k-pager-nav .k-svg-i-caret-alt-to-left::after {
  content: "";
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMC4xNTUxIDEzLjg3MUM5LjgwOTMyIDEzLjQ4MjIgOS44MTk0OSAxMi44OTMyIDEwLjE3ODUgMTIuNTE2NkwxNS43NzUxIDYuNjQ0MzlDMTYuMTU2MiA2LjI0NDYgMTYuNzg5MiA2LjIyOTM5IDE3LjE4ODkgNi42MTA0M0MxNy41ODg3IDYuOTkxNDYgMTcuNjAzOSA3LjYyNDQ0IDE3LjIyMjkgOC4wMjQyM0wxMi4yNjE0IDEzLjIzTDE3LjI0NjMgMTguODM1NUMxNy42MTMzIDE5LjI0ODIgMTcuNTc2MyAxOS44ODAyIDE3LjE2MzYgMjAuMjQ3M0MxNi43NTA4IDIwLjYxNDMgMTYuMTE4OCAyMC41NzcyIDE1Ljc1MTggMjAuMTY0NUwxMC4xNTUxIDEzLjg3MVoiIGZpbGw9IiMxOTk2RkMiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04LjQwMjM1IDZMOC40MDIzNCAyMC41TDYuNDAyMzQgMjAuNUw2LjQwMjM1IDZMOC40MDIzNSA2WiIgZmlsbD0iIzE5OTZGQyIvPgo8L3N2Zz4K);
}

.k-pager-nav .k-svg-i-caret-alt-left::after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04LjUgMTIuNUM4LjUgMTIuMjM0OCA4LjYwNTM2IDExLjk4MDQgOC43OTI5IDExLjc5MjlMMTQuNzkyOSA1Ljc5Mjk1QzE1LjE4MzQgNS40MDI0MyAxNS44MTY2IDUuNDAyNDMgMTYuMjA3MSA1Ljc5Mjk2QzE2LjU5NzYgNi4xODM0OCAxNi41OTc2IDYuODE2NjUgMTYuMjA3MSA3LjIwNzE3TDEwLjkxNDIgMTIuNUwxNi4yMDcxIDE3Ljc5MjlDMTYuNTk3NiAxOC4xODM0IDE2LjU5NzYgMTguODE2NiAxNi4yMDcxIDE5LjIwNzFDMTUuODE2NiAxOS41OTc2IDE1LjE4MzQgMTkuNTk3NiAxNC43OTI5IDE5LjIwNzFMOC43OTI4OSAxMy4yMDcxQzguNjA1MzYgMTMuMDE5NiA4LjUgMTIuNzY1MiA4LjUgMTIuNVoiIGZpbGw9IiMxOTk2RkMiLz4KPC9zdmc+Cg==);
}

.k-pager-nav .k-svg-i-caret-alt-right::after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNi41IDEyLjVDMTYuNSAxMi43NjUyIDE2LjM5NDYgMTMuMDE5NiAxNi4yMDcxIDEzLjIwNzFMMTAuMjA3MSAxOS4yMDdDOS44MTY1OCAxOS41OTc2IDkuMTgzNDEgMTkuNTk3NiA4Ljc5Mjg5IDE5LjIwN0M4LjQwMjM3IDE4LjgxNjUgOC40MDIzNyAxOC4xODM0IDguNzkyOSAxNy43OTI4TDE0LjA4NTggMTIuNUw4Ljc5Mjg5IDcuMjA3MTFDOC40MDIzNyA2LjgxNjU4IDguNDAyMzcgNi4xODM0MiA4Ljc5Mjg5IDUuNzkyODlDOS4xODM0MiA1LjQwMjM3IDkuODE2NTggNS40MDIzNyAxMC4yMDcxIDUuNzkyODlMMTYuMjA3MSAxMS43OTI5QzE2LjM5NDYgMTEuOTgwNCAxNi41IDEyLjIzNDggMTYuNSAxMi41WiIgZmlsbD0iIzE5OTZGQyIvPgo8L3N2Zz4K);
}

.k-pager-nav .k-svg-i-caret-alt-to-right::after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy43NDczIDEyLjYyOUMxNC4wOTMgMTMuMDE3OCAxNC4wODI5IDEzLjYwNjggMTMuNzIzOSAxMy45ODM0TDguMTI3MiAxOS44NTU2QzcuNzQ2MTcgMjAuMjU1NCA3LjExMzE5IDIwLjI3MDYgNi43MTM0IDE5Ljg4OTZDNi4zMTM2MSAxOS41MDg1IDYuMjk4NCAxOC44NzU2IDYuNjc5NDMgMTguNDc1OEwxMS42NDA5IDEzLjI3TDYuNjU2MDUgNy42NjQ1NEM2LjI4OTA1IDcuMjUxODQgNi4zMjYwOSA2LjYxOTc2IDYuNzM4NzkgNi4yNTI3NUM3LjE1MTQ5IDUuODg1NzQgNy43ODM1NyA1LjkyMjc4IDguMTUwNTggNi4zMzU0OEwxMy43NDczIDEyLjYyOVoiIGZpbGw9IiMxOTk2RkMiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNS41IDIwLjVWNkgxNy41VjIwLjVIMTUuNVoiIGZpbGw9IiMxOTk2RkMiLz4KPC9zdmc+Cg==);
}

.k-pager-nav.k-button-md.k-icon-button {
  min-width: unset;
  height: unset;
  padding: 0;
  margin: 0;
}

.k-pager-md .k-pager-info {
  display: flex;
  color: #707070;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 166.667% */
}

.k-grid .k-grid-container {
  border-top: 1px solid #001130;
}

.k-icon {
  &::after,
  &::before {
    font-family: inherit !important;
  }
}

.k-table-row {
  height: 4.4375rem;
}

.k-cell-inner > .k-link {
  justify-content: center;
}
.k-grid th > .k-checkbox {
  margin: auto;
}
.k-column-title {
  font-size: 0.7188rem;
  text-align: center;
  white-space: initial;
}
.k-grid-header .k-header {
  vertical-align: middle;
}

.k-grid-toolbar {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  background-color: #fff;
  &::before {
    display: none;
  }
}

.k-grid [data-status] {
  text-align: left;
  height: 4.4375rem;
  display: grid;
  gap: 0.5rem;
  grid-template: max-content / max-content 60px;
  place-content: center;
}

.k-grid-header .k-grid-filter:hover,
.k-grid-header .k-header-column-menu:hover,
.k-grid-header .k-grid-header-menu:hover,
.k-grid-header .k-hierarchy-cell .k-icon:hover {
  background-color: transparent;
}
