.home {
  display: grid;
  grid-template: max-content max-content / 1fr;
  gap: 3rem;
  justify-items: center;
  width: 100%;
  min-height: 35rem;
  margin: 2rem;
  padding: 1rem;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.211765);
  border-radius: 10px;
  position: relative;
  &__section {
    position: relative;
    display: flex;
    padding: 1rem 0 2rem;
    &-highlight {
      position: absolute;
      height: 3px;
      opacity: 0;
      top: 0;
      background: var(--contrast-color);
    }
  }
  &__back {
    position: absolute;
    left: 1rem;
    font-size: 0;
    top: 1rem;
    background: url("../../images/home/icon_back.svg") no-repeat;
    background-position: center;
    filter: grayscale(1);
    width: 1.75rem;
    height: 1.75rem;
    cursor: pointer;
    transition: filter 0.25s linear;
    &:hover {
      filter: grayscale(0);
    }
  }
  &__tooltip {
    display: flex;
    align-items: center;
    background: url("./../../images/tooltip/blue-help.svg") no-repeat 100% 50%;
    background-size: 30px 30px;
    padding: 12px 15px;
    cursor: pointer;
    background-color: transparent;
    border: none !important;

    position: absolute;
    right: 0;
    top: 1rem;
  }
  &__title {
    font-weight: 500;
    font-size: 25px;
    line-height: 32px;
    text-align: center;
  }
  &__item {
    position: relative;
    display: grid;
    grid-template-rows: 54px 170px max-content;
    align-items: center;
    justify-items: center;
    width: 18.75rem;
    &::after {
      content: "";
      width: 1px;
      height: 70%;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: var(--gray-50);
    }
    &[data-last="true"]::after {
      display: none;
    }
    &_title {
      display: grid;
      grid-template-rows: max-content 2rem;
      align-items: center;
      justify-content: center;
      justify-items: center;
      margin: 0;
      position: relative;
      width: 100%;
      padding: 1rem 0.5rem;
      // color: var(--contrast-color);
      color: black;
      text-transform: capitalize;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
    }
    &_img {
      // width: 144px;
      max-width: 100%;
      object-fit: contain;
      margin: auto;
      // height: 144px;
    }
    &_button {
      margin: 1rem 1rem 0.5rem;
      width: 174px;
      height: 36.09px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--contrast-color);
      border: 2px solid var(--contrast-color);
      border-radius: 30px;
      cursor: pointer;
      &[disabled] {
        filter: grayscale(1);
        user-select: none;
        pointer-events: none;
      }
    }
    &_description {
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
      text-align: center;
      max-width: 10.75rem;
      margin: 0;
      opacity: 0;
      transform: translateY(-10px);
    }
    &:hover {
      .home__item {
        &_button {
          transition: color 0.25s linear, border-color 0.25s linear,
          background-color 0.25s linear;
          color: var(--gray-10);
          background-color: var(--contrast-color);
        }
        &_description {
          cursor: help;
          transition: transform 0.25s linear, opacity 0.25s linear;
          transform: translateY(0);
          opacity: 1;
        }
      }
      & ~ .home__section-highlight {
        --cant-items: 3;
        --index-position: 0;
        opacity: 1;
        width: calc(100% / var(--cant-items));
        left: calc((100% / var(--cant-items)) * var(--index-position));
        transition: left 0.25s ease-in-out, opacity 0.5s linear;
      }
    }
    &[data-disabled="true"] {
      pointer-events: none;
      user-select: none;
      .home__item_img {
        filter: grayscale(1);
        opacity: 0.5;
      }
      .home__item_title {
        color: var(--gray-50);
      }
    }
  }
}
