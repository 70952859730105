.partner-list{
    padding-top: 19px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 26px;
    
    &__header{
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0px 3px 0px 0px;
    }

    &__title{
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 15px;
    }
    &__container {
        width: 100%;
        max-height: 190px;
        overflow-y: auto;
        padding-right: 20px;
        margin-top: 24px;
        &::-webkit-scrollbar {
            display: block !important; 
        }
        &::-webkit-scrollbar-track {
            background: $gray-30;
            border-radius: 4px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background: $gray-90;
        }
        &::-webkit-scrollbar {
            width: 3px;
        }
    }
    &__number{
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        color: $gray-90;
    }
}

.admin-info{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__user{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__user-name{
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
    }

    &__role{
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color:$contrast-color;
    }

    &__user-avatar{
        background: url('./../../images/header/user-avatar.svg');
        background-repeat: no-repeat; 
        background-size: 28px 28px;
        padding: 15.5px;
        background-position: center;
        margin-right: 10px;
    }
}

.partner-item{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 17px;
    position: relative;

    &__user{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        button {
            cursor: pointer;
            margin-left: 10px;
        }
    }

    &__user-name{
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
    }

    &__button{
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        position: relative;
        background-size: 10px 5px;
        padding-right: 17px;
        cursor: pointer;
        background: url('./../../images/configuration/black-down-arrow.svg') no-repeat 99% 50%;
    }

    &__button:hover{
        background: url('./../../images/configuration/blue-down-arrow.svg') no-repeat 99% 50%;
        background-size: 10px 5px;
        color:$contrast-color;
    }

    &__user-avatar{
        background: url('./../../images/header/user-avatar.svg');
        background-repeat: no-repeat; 
        background-size: 28px 28px;
        padding: 15.5px;
        background-position: center;
        margin-right: 10px;
    }

    &__slide{
        position: absolute;
        z-index: 9;
        top: 26px;
        right: 0px;
        background-color: #F5F6FA;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
        border-radius: 10px 0px 10px 10px;
        animation-duration: 0.2s;
        animation-name: slidein;
        padding: 0px 0px;
    }

    &__remove{
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
        padding:10px 50px 10px 20px;
        cursor:pointer
    }

    &__remove:hover{
        color:$contrast-color
    }

    &__hide{
        display: none;
    }
}

.partner-invitation{

    &__row{
        padding: 10px 0;
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 27px;
    }

    &__text{
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        margin-left: 10px;
        padding-right: 10px;
    }

    &__icon{
        background: url('./../../images/configuration/add-user.svg');
        background-repeat: no-repeat; 
        background-size: 28px 28px;
        padding: 15.5px;
    }

    &__subtitle{
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        width: 100%;
        text-align: left;
        padding-bottom: 8px;
    }

    &__div{
        width: 100%;
        height: 40px;
        padding-left: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid $gray-50;
        border-radius: 8px 25px 25px 8px;
    }

    &__div-focus{
        width: 100%;
        height: 40px;
        padding-left: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid $contrast-color;
        border-radius: 8px 25px 25px 8px;
    }

    &__button{
        height: 40px;
        width: 110px;
        color: $gray-50;
        background-color: $gray-30;
        border-radius: 30px;
        position: relative;
        right: -1px;
        border: 1px solid $gray-30;
    }

    &__button-focus{
        height: 40px;
        width: 110px;
        background-color: $contrast-color;
        border-radius: 30px;
        position: relative;
        right: -1px;
        color:$cards-color;
        box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
    }

    &__button-focus:hover{
        cursor: pointer;
    }

    &__button-focus:active{
        cursor: pointer;
        background-color: $click-color;
    }

    &__input{
        font-style: normal;
        font-size: 13px;
        line-height: 19px;
        display: flex;
        align-items: center;
        width: 65%;
    }

    &__input:placeholder-shown{
        color: #707070;
        opacity: 0.4;
    }

    &__list{
        box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.2);
        border-radius: 0px 0px 8px 8px;
        padding: 13px 6px;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;
        background-color: $gray-10;
        margin-top: 10px;
    }

    &__label{
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
        border-radius: 5px;
        border: 1px solid $gray-30;
        background-color: $cards-color;
        height: 30px;
        margin-right: 8px;
        padding:7px 10px;
        font-size: 10px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__x-button{
        margin-left: 20px;
        background: url('./../../images/configuration/x-gray.svg') no-repeat 100% 50%;
        margin-left: 10px;
        padding: 8px;
        background-size: 16px 16px;
    }
}