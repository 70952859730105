.user_subscription{
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  &__title{
    width: 100%;
    display: flex;
    text-align: left;
    margin-bottom: 0.5rem;

  }
  &__user_content{
    width: 100%;
    display: flex;
    justify-content: flex-start;

  }
  &__field{
    width: 90%;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    border-radius: 1rem;
    line-height: 2px;
    border-color: black;
    padding: 0.5rem;

  }
  &__field_label{
  color: rgb(199, 199, 199);
  }
  &__field_input{
    text-align: left;
    border-radius: 1rem;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(221, 221, 221);
    width: 90%;
    margin-top: 0.8rem;
    padding: 0.2rem  0.5rem;
    
  }
  &__field_cancel_sus_btn{
  margin-left: 1rem;
 
  }
}


.cards_subscription{
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  
  &__content{
      display: flex;
     
    }
  &__creditcard_list{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
     
    }
  &__add_more_btn{
    width: 203px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 16px;
    color: #1996FC;
    cursor: pointer;
     
    }
}


.creditcard{
  margin: 2rem;
  padding: 2rem 1rem 0rem 1rem;
  height: 9.68rem;
  width: 13.75rem;
  box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.2);
  border-radius: 1.8rem;
  &__head{
    padding-top: 1rem;
    display: flex;
    width: 100%;
    align-items: center;
    
  }
  &__head_label{
    margin-left: 0.5rem;
  }
  &__text{
    text-align: center;
    padding-top: 1rem;
    color: #707070;
  }
  &__text_actual{
    color: white;
    text-align: center;
    background: #6ACF64;
    border-radius: 30px;
  }
  &__text_cambiar{
    color: white;
    background-color: #1996FC;
    border-radius: 30px;
    cursor: pointer;
    text-align: center;

  }
}

.details_subscription{
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  
  &__content{
    display: flex;
    background-color: #F5F6FA;
    border-radius: 1rem;
    padding: 1rem 2rem;
    color: #8b8b8b;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
  }
  &__font{
    text-align:left;
    margin-right: 1rem;
  }
  &__description{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 0px 3px 0px 0px;
      align-items: center;
     
    }
  &__description{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 0px 3px 0px 0px;
      justify-content: center;
     
    }
  &__plan_status{
    display: flex;

    }
    
  
    
  
}

.btn_inactive_subs{
  background-color: white;
  border-radius: 1rem;
  border-style: solid;
  border-width: 2px;
  color: #1996FC;
  border-color: #1996FC;
  padding: 0.1rem 0.5rem;
  &:hover{
    cursor: pointer;
  }

}
.container_card_credit_form{
  padding: 0rem 2rem;
  
}

.notification_cancel_subscription{
  display: flex;
    flex-direction: column;
    justify-content: center;
}