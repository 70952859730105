.container {
  width: 800px;
  height: 100%;

  &__form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0.5rem;

    width: 100%;
    margin-top: 1rem;
    background-color: white;

    &__buttons {
      display: flex;
      justify-content: center;
      min-height: 4.125rem;
    }

    &__cartPass {
      width: 500px;
    }

    &__cart {
      width: 100%;
      align-items: center;

      display: grid;
      grid-template: max-content / 0.3fr 1fr;
      gap: 0 2.8rem;
      &__error {
        grid-area: 2 / 2 / -1 / -1;
        display: block;
        font-size: 0.8rem;
        color: $fail-color;
        min-height: 1rem;
        padding-left: 1rem;
        margin: 5px 0;
      }

      &__font {
        text-align: right;
        font-weight: 600;
        margin-left: 10px;
        margin-bottom: 8px;
        font-size: 14px;
      }
      &__inp {
        mix-blend-mode: normal;
        box-sizing: border-box;
        height: 3.8125rem;
        margin-top: 0px;
        padding-left: 30px;
        border: 1px solid $contrast-color;
        background: #f8f8f8;
        border-radius: 10px;
        cursor: pointer;
        &.is-invalid {
          border: 1px solid $fail-color;
          color: $fail-color;
          &::placeholder {
            color: $fail-color;
          }
        }
        &:disabled {
          border-color: transparent;
          user-select: none;
          cursor: default;
        }
      }
    }
    &__pass {
      display: flex;
      gap: 1rem;
      align-items: center;
    }
  }
}
.option-menu {
  display: flex;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.160784);
  border-radius: 10px;
  background: $cards-color;
  &__title {
    font-size: 18px;
    font-weight: 600;
    display: inline-flex;
    padding: 10px 0;
    img {
      margin-right: 8px;
    }
  }
  &__subTitle {
    font-size: 16px;
    color: $gray-90;
    line-height: 24px;
    padding: 8px;
    margin-left: 17px;
    cursor: pointer;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 9px;
      height: 9px;
      transform: rotate(-45deg);
      right: 0;
      top: 16px;
      border-bottom: 1.5px solid $gray-90;
      border-right: 1.5px solid $gray-90;
      cursor: pointer;
    }

    &.active {
      color: $contrast-color;
      &::after {
        border-bottom: 1.5px solid $contrast-color;
        border-right: 1.5px solid $contrast-color;
      }
    }
  }
  &__list {
    width: 25%;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.160784);
    border-radius: 10px;
    padding: 25px 40px 25px 27px;
  }
  &__content {
    width: 75%;
    padding: 26px 26px 30px 33px;
  }
}

.configuration-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  position: relative;
  .container-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $gray-30;
    padding-bottom: 0.625rem;

    &__edit {
      width: 30px;
      height: 30px;
      background-image: url("../../images/configuration/edit-blue.svg");
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center;
      font-size: 0;
    }

    &__label {
      width: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: 1.5625rem;
      line-height: 24px;
      display: inline-flex;
    }
    &__note {
      font-size: 0.7rem;
    }
    &__tooltip {
      display: flex;
      align-items: center;
      background: url("./../../images/tooltip/blue-help.svg") no-repeat 100% 50%;
      background-size: 30px 30px;
      padding: 12px 15px;
      cursor: pointer;
      margin-left: 15px;
    }
  }
}

.coming-soon {
  border: 1px solid $alert-color;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: $alert-color;
  border-radius: 5px;
  width: 100px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
