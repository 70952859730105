.index-admin {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 13px;

  &__card {
    background: $cards-color;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.160784);
    border-radius: 10px;
    padding: 1rem;
  }

  &__card:nth-child(1) {
    width: 50%;
    position: relative;
  }

  &__card:nth-child(2) {
    width: 49%;
  }

  &__index-loader {
    position: absolute;
    height: 100%;
    width: 100%;
    background: $gray-10;
    opacity: 0.95;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
  }
}

.store-index {
  &__footer-label {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;
    color: $contrast-color;
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }

  &__contrast-button {
    border-radius: 20px;
    background-color: $contrast-color;
    color: $cards-color;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 216px;
    margin: auto;
    cursor: pointer;
    margin-bottom: 16px;
  }

  &__contrast-button:hover {
    box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
  }

  &__contrast-button:active {
    background-color: $click-color;
  }
}

.index-table-header-margin {
  width: 100%;
  padding-right: 20px;
  margin-top: 20px;
}

.index-table-header {
  width: 100%;
  display: grid;
  grid-template-columns: 137px 137px 139px 137px 192px;
  justify-content: center;

  &__cell {
    background-color: $gray-10;
    min-height: 56px;
    display: flex;
    align-items: center;
    padding: 8px;
    border: 1px solid $gray-30;
    height: 100%;
    width: 100%;
    justify-content: center;
  }

  &__cell:nth-child(1) {
    border-radius: 8px 0px 0px 8px;
  }

  &__cell:nth-child(2) {
    // border-radius: 8px 0px 0px 8px;
    border-right: none;
    border-left: none;
    
  }
  &__cell:nth-child(3) {
    // border-radius: 8px 0px 0px 8px;
    border-right: none;
    border-left: none;
  }
  &__cell:nth-child(4) {
    border-right: none;
    border-left: none;
  }
  &__cell:nth-child(5) {
    border-radius: 0px 8px 8px 0px;
  }

  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $gray-90;
    text-align: center;
    width: 7.5rem;
  }
}

.index-table-body {
  width: 100%;
  padding-right: 17px;
  margin-top: 10px;
  max-height: 40vh;
  overflow-y: auto;
}

.index-table-body::-webkit-scrollbar {
  width: 4px;
  border-radius: 4px;
  display: inline;
}
.index-table-body::-webkit-scrollbar-thumb {
  background: $gray-90;
  border-radius: 4px;
}

.index-table-body::-webkit-scrollbar-track {
  background: $gray-30;
  border-radius: 4px;
}

.index-table-row {
  width: 100%;
  display: grid;
  grid-template-columns: 137px 137px 139px 136px 192px;
  justify-content: center;
  margin-bottom: 10px;
  border-radius: 8px;

  &__cell {
    min-height: 50px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    background-color: $gray-10;
  }

  &__cell:nth-child(1) {
    border-radius: 8px 0px 0px 8px;
    border-right: 1px solid $gray-30;
  }

  &__cell:nth-child(2) {
    border-right: 1px solid #e2e2e2;
  }
  
  &__cell:nth-child(3) {
    border-radius: 0px 8px 8px 0px;
    padding: 0px;
  }
  &__cell:nth-child(4) {
    border-left: 1px solid $gray-30;
  }
  &__cell:nth-child(5) {
    border-left: 1px solid $gray-30;
  }

  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: $main-text-color;
    text-align: center;
    max-width: 140px;
    word-wrap: break-word;
    small{
      color: gray;
      font-size: 9px;
    }
  }

  &__button {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: $main-text-color;
    cursor: pointer;
  }

  &__button:active {
    color: $cards-color;
    background-color: $click-color;
  }
}

.index-table-row:last-child {
  margin-bottom: 0px;
}

.index-table-row:hover .index-table-row__cell {
  background-color: $background-basic-color;
}

.index-historic-table-header-margin {
  width: 100%;
  padding-right: 20px;
}

.index-historic-table-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-collapse: collapse;

  &__cell {
    background-color: $gray-10;
    min-height: 56px;
    display: flex;
    align-items: center;
    padding: 8px;
    border: 1px solid $gray-30;
    height: 100%;
    width: 100%;
    justify-content: center;
  }

  &__cell:nth-child(1) {
    width: 27%;
    border-radius: 8px 0px 0px 8px;
  }

  &__cell:nth-child(2) {
    width: 31%;
    border-right: none;
    border-left: none;
  }

  &__cell:nth-child(3) {
    width: 42%;
    border-radius: 0px 8px 8px 0px;
  }

  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $gray-90;
    text-align: center;
    max-width: 140px;
  }
}

.delete-index-modal {
  display: flex;
  flex-wrap: wrap;
  width: 690px;

  &__warning {
    color: $cards-color;
    background-color: $alert-color;
    padding: 8px 30px 8px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    width: 100%;
  }

  &__row {
    padding: 6px 111px 18px 111px;
  }
  &__info {
    margin-right: 24px;
  }

  .modal-content {
    border: none;
    box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.211765);
    border-radius: 10px;
    background-color: transparent;
    width: 610px;
    margin: auto;
  }

  &__header {
    height: 60px;
    color: white;
    background-color: $main-text-color;
    border-radius: 10px 10px 0px 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
    width: 100%;
  }

  &__close-button {
    background: url("./../../images/modal/white-x.svg") no-repeat;
    background-size: 28px 28px;
    padding: 14px;
  }

  &__body {
    border-radius: 0px 0px 10px 10px;
    background: $cards-color;
    padding: 24px 70px 38px 70px;
    width: 100%;
  }

  &__label {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    width: 100%;
    text-align: center;
    margin-bottom: 8px;
  }

  &__input {
    border: 1px solid $gray-90;
    opacity: 1;
    color: var(--main-text-color);
    border-radius: 25px;
    width: 100%;
    min-height: 36px;
    margin-top: 0px;
    margin-bottom: 20px;
    padding-left: 20px;
    background-color: transparent;
    font-size: 13px;
  }

  &__input:focus {
    border: 1px solid $contrast-color;
    opacity: 1;
    color: $main-text-color;
    outline: none;
  }

  &__input:placeholder-shown {
    opacity: 0.4;
    color: $gray-90;
    margin-bottom: 20px;
    background-color: transparent;
  }

  &__input-invalid {
    mix-blend-mode: normal;
    border: 1px solid $fail-color;
    opacity: 1;
    color: $main-text-color;
    box-sizing: border-box;
    border-radius: 25px;
    width: 100%;
    min-height: 36px;
    margin-top: 0px;
    margin-bottom: 20px;
    padding-left: 20px;
    font-size: 13px;
    background-color: transparent;
  }

  &__contrast-button {
    border-radius: 20px;
    background-color: $contrast-color;
    color: $cards-color;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    cursor: pointer;
    margin-right: 32px;
  }

  &__contrast-button:hover {
    box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
  }

  &__contrast-button:active {
    background-color: $click-color;
  }

  &__gray-button {
    border-radius: 20px;
    background-color: $gray-10;
    color: $gray-90;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    cursor: pointer;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__gray-button:hover {
    box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
  }

  &__gray-button:active {
    background-color: $gray-30;
  }

  &__container-check {
    width: 6%;
    display: flex;
    align-items: center;
    margin-left: 0px;
  }
}

.index-historic-table-body {
  width: 100%;
  padding-right: 17px;
  margin-top: 10px;
  max-height: calc(40vh + 114px);
  overflow-y: auto;
}

.index-historic-table-body::-webkit-scrollbar {
  width: 4px;
  border-radius: 4px;
  display: inline;
}
.index-historic-table-body::-webkit-scrollbar-thumb {
  background: $gray-90;
  border-radius: 4px;
}

.index-historic-table-body::-webkit-scrollbar-track {
  background: $gray-30;
  border-radius: 4px;
}

.index-historic-table-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  border-collapse: collapse;
  border-radius: 8px;

  &__cell {
    min-height: 50px;
    display: flex;
    align-items: center;
    padding: 8px;
    height: 100%;
    width: 100%;
    justify-content: center;
    background-color: $gray-10;
  }

  &__cell:nth-child(1) {
    width: 27%;
    border-radius: 8px 0px 0px 8px;
    border-right: 1px solid $gray-30;
  }

  &__cell:nth-child(2) {
    width: 31%;
  }

  &__cell:nth-child(3) {
    border-left: 1px solid $gray-30;
    width: 42%;
    border-radius: 0px 8px 8px 0px;
    padding: 0px 21px;
    justify-content: space-between;
    position: relative;
  }

  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: $main-text-color;
    display: flex;
    align-items: center;
  }

  &__user {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: $main-text-color;
    display: flex;
    align-items: center;
    max-width: 90%;
    overflow-x: hidden;
  }

  &__button {
    display: flex;
    align-items: center;
    background: url("../../images/indexadmin/gray-points.svg") no-repeat;
    padding: 9px 4px;
    cursor: pointer;
  }

  &__slide {
    position: absolute;
    z-index: 9;
    top: 15px;
    right: 37px;
    background-color: #f5f6fa;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
    border-radius: 10px 0px 10px 10px;
    animation-duration: 0.2s;
    animation-name: slidein;
    padding: 0px 0px;
  }

  &__undo {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    padding: 10px 50px 10px 20px;
    cursor: pointer;
  }

  &__undo:hover {
    color: $contrast-color;
  }

  &__hide {
    display: none;
  }
}

.index-modal {
  width: 584px;
  top: 80px;
  .modal-content {
    border: none;
    box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.211765);
    border-radius: 10px;
    background-color: transparent;
    width: 584px;
    margin: auto;
  }

  &__header {
    height: 60px;
    color: white;
    background-color: $main-text-color;
    border-radius: 10px 10px 0px 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 21px;
  }

  &__coming-soon {
    border: 1px solid $alert-color;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: $alert-color;
    border-radius: 5px;
    width: 100px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__close-button {
    background: url("./../../images/modal/white-x.svg") no-repeat;
    background-size: 28px 28px;
    padding: 14px;
  }

  &__body {
    border-radius: 0px 0px 10px 10px;
    background: $cards-color;
    padding: 24px 55px 28px 55px;
  }

  &__label-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
  }

  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
  }

  &__main-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    // padding-left: 28px;
    display: flex;
    align-items: center;
    color: $contrast-color;
  }

  &__contrast-button {
    border-radius: 20px;
    background-color: $contrast-color;
    color: $cards-color;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    cursor: pointer;
    margin-top: 20px;
    margin-left: 8px;
  }

  &__contrast-button:hover {
    box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
  }

  &__contrast-button:active {
    background-color: $click-color;
  }

  &__gray-button {
    border-radius: 20px;
    background-color: $gray-10;
    color: $gray-90;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    cursor: pointer;
    margin-top: 20px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__gray-button:hover {
    box-shadow: 0px 3px 10px rgba(112, 112, 112, 0.5);
  }

  &__gray-button:active {
    background-color: $gray-30;
  }

  &__container-check {
    width: 28px;
    display: flex;
    align-items: flex-start;
    padding-bottom: 8px;
  }

  &__checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
  }

  &__checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &__check {
    position: absolute;
    top: -4px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: $gray-10;
    border: 1px solid $gray-90;
    border-bottom: 1px solid $gray-90 !important;
    border-radius: 18px;
  }

  &__checkbox input:checked ~ &__check {
    background-color: $background-basic-color;
    border: 1px solid $contrast-color;
    border-bottom: 1px solid $contrast-color !important;
  }

  &__check:after {
    content: "";
    position: absolute;
    display: none;
  }

  &__checkbox input:checked ~ &__check:after {
    display: block;
  }

  &__checkbox &__check:after {
    left: 4px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid $contrast-color;
    border-width: 0px 1px 1px 0;
    transform: rotate(45deg);
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    margin-top: 15px;
  }

  &__row:first-child {
    margin-top: 0px;
  }

  &__time-space {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    padding-left: 28px;
  }

  &__disabled {
    pointer-events: none;
  }

  .SingleDatePickerInput__withBorder {
    border: 1px solid $gray-30;
    border-radius: 8px;
    height: 36px;
    padding: 4.5px 11px;
    width: 145px;
    background: url("../../images/indexadmin/calendar.svg") no-repeat 94% 20%;
  }

  .DateInput_input {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: $gray-90;
    padding: 0px;
    background-color: transparent;
  }

  .DateInput_input__focused {
    border-bottom: none;
  }

  .DateInput_fang {
    top: 49px !important;
  }

  .DateInput {
    background: transparent;
  }

  .DayPickerKeyboardShortcuts_show__bottomRight {
    display: none;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: $contrast-color;
    border: 1px double $contrast-color;
    color: #fff;
  }
}
