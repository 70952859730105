.time-selector {
  border: 1px solid $gray-30;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 121px;
  height: 36px;
  padding: 0px 11px;
  position: relative;
  margin-left: 6px;

  &__button {
    background: url("./../../images/general/big-blue-arrow.svg") no-repeat 50%
      50%;
    background-size: 14px 32px;
    padding: 6px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__info {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: $gray-90;
  }

  &__hide {
    display: none;
  }

  &__slide {
    position: absolute;
    z-index: 20;
    top: 32px;
    left: 0px;
    background-color: $cards-color;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    animation-duration: 0.2s;
    animation-name: slidein;
    padding: 1px 0px;
    width: 121px;
    height: 152px;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    border-collapse: collapse;
  }

  &__slide-col {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: $main-text-color;
    overflow-y: scroll;
    height: 150px;
  }

  &__slide-col:nth-child(2) {
    border-left: 1px solid $gray-50;
    border-right: 1px solid $gray-50;
  }

  &__hour-selected {
    background-color: $contrast-color;
    color: $cards-color;
    width: 33px;
    height: 30px;
  }

  &__hour {
    width: 33px;
    height: 30px;
    cursor: pointer;
  }

  &__hour:hover {
    background-color: $background-basic-color;
    color: $contrast-color;
  }

  &__minute-selected {
    background-color: $contrast-color;
    color: $cards-color;
    width: 33px;
    height: 30px;
  }

  &__minute {
    width: 33px;
    height: 30px;
    cursor: pointer;
  }

  &__minute:hover {
    background-color: $background-basic-color;
    color: $contrast-color;
  }

  &__type-selected {
    background-color: $contrast-color;
    color: $cards-color;
    width: 33px;
    height: 30px;
  }

  &__type {
    width: 33px;
    height: 30px;
    cursor: pointer;
  }

  &__type:hover {
    background-color: $background-basic-color;
    color: $contrast-color;
  }
}

.c-toggle {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 2.25rem;
  position: relative;
  border-radius: 4px;
  min-width: 22.5rem;
  border: 2px solid $contrast-color;
  cursor: pointer;
  &_active {
    color: $gray-10;
  }
  &_inactive,
  &_active {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 0.25rem;
    overflow: hidden;
    z-index: 2;
    &::after {
      content: "";
      background-color: $contrast-color;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      transition: transform 0.25s ease-in-out;
      z-index: -1;
    }
  }
  &_input {
    display: none;
    visibility: hidden;
    &:not(:checked) {
      & ~ .c-toggle {
        &_active {
          color: $contrast-color;
          &::after {
            transform: translateX(100%);
          }
        }
        &_inactive {
          color: $gray-10;
          &::after {
            transform: translateX(0%);
          }
        }
      }
    }
    &:checked {
      & ~ .c-toggle {
        &_active {
          color: $gray-10;
          transform: translateX(0);
        }
        &_inactive {
          color: $contrast-color;
          &::after {
            transform: translateX(-100%);
          }
        }
      }
    }
  }
}
.MuiSpeedDial-directionDown .MuiSpeedDial-actions {
  margin-top: 17rem !important;
  z-index: 2147483647;
}

.MuiSpeedDial-root {
  width: 50px !important;
}

.MuiSpeedDial-fab {
  position: absolute !important;
  top: 1rem !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: $contrast-color !important;
}

.MuiTooltip-tooltip {
  background-color: $contrast-color !important;
}
.MuiTooltip-arrow {
  color: $contrast-color !important;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: #fff !important;
}

.c-tooltip {
  border: 1px solid $contrast-color !important;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: $contrast-color !important;
  border-radius: 5px;
  width: max-content;
  padding: 0.25rem;
  height: 25px;
  display: inline-flex;
  margin: 0 0.25rem;
  justify-content: center;
  align-items: center;
  &_content * {
    color: $gray-10;
  }
}

.flag__location {
  width: 26px;
  pointer-events: none;
}

.MuiMenu-paper {
  max-height: 20rem !important;
}

$toggle-knob-d: 0.5rem;
$toggle-box-p: 0.15rem;
$toggle-box-b: 0.15rem;
$toggle-box-o: $toggle-box-p + $toggle-box-b;
$toggle-box-h: $toggle-knob-d + $toggle-box-o * 2;
$toggle-box-r: $toggle-box-h/2;

.inputToggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
  & + .inputToggle__label {
    position: relative;
    display: block;
    width: 40px;
    height: 18px;
    background-color: $gray-50;
    font-size: 0px;
    text-indent: -9999px;
    cursor: pointer;
    border-radius: $toggle-box-r;
    outline: none;
    user-select: none;
    transition: box-shadow 0.3s linear, background 0.25s linear;
    &::before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      width: 1rem;
      height: 1rem;
      background-color: #fff;
      margin: auto;
      border-radius: $toggle-box-r;
      transition: background 0.3s, transform 0.3s;
      left: 2px;
      bottom: 0;
    }
  }
  &:disabled {
    & + .inputToggle__label {
      cursor: auto;
      filter: grayscale(1);
    }
  }
  &:checked + .inputToggle__label {
    background-color: $contrast-color;
    &::before {
      background-color: #fff;
      transform: translateX(1.25rem);
    }
  }
}

.modalWithTopBar {
  position: absolute;
  top: 15%;
  left: 50%;
  width: max-content;
  max-width: 35.3125rem;
  height: max-content;
  max-width: 90vh;
  background-color: $cards-color;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  outline: none;
  display: grid;
  grid-template: max-content 1fr / 1fr;
  transform: translateX(-50%);
  justify-content: center;
  align-items: center;
  &__header {
    background-color: $main-text-color;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    width: 100%;
  }
  &__header-label {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: $cards-color;
  }
  &__img {
    cursor: pointer;
    margin-left: 1rem;
  }
}
